<template>
  <div class="page-contact" style="margin-top: 1rem; margin: 0 auto">
    <div class="breadcrumb">
      <router-link to="/">ACCUEIL</router-link>
      <div
        style="display: flex"
        v-for="(route, index) in routes"
        v-bind:key="route"
      >
        <span>&nbsp;>&nbsp;</span>
        <router-link v-bind:to="route">
          <span v-bind:class="index == routes.length - 1 ? 'active' : ''">{{
            route.toUpperCase()
          }}</span>
        </router-link>
      </div>
    </div>
    <div>
      <h1 class="title">Pour nous contacter</h1>
      <p>
        Pour tous conseils, questions où si vous voulez juste discuter vous
        pouvez nous retrouver sur notre instagram <a href="https://www.instagram.com/outsidogparis/">
          #OUTSIDOG
        </a> ou encore envoyer un
        mail à <a href="mailto: contact@outsidog.com">contact@outsidog.com</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactView",
  data() {
    return {
      path: "",
      routes: [],
    };
  },
  mounted() {
    this.path = this.$router.currentRoute;
    this.routes = this.path.path.split("/").filter(Boolean);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.page-contact {
  padding: 1rem;
  max-width: 1280px;
}
.breadcrumb {
  display: flex;
  font-size: 0.8rem;
  a {
    margin: 0;
    padding: 0;
    color: grey;
    .active {
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
