<template>
  <div
    class="page-cart"
    style="
      margin-top: 60px;
      height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0 auto;
      padding-left: 1rem;
      padding-bottom: 1rem;
    "
  >
    <h1 class="title has-text-centered">Commande confirmée !</h1>
    <h1 class="has-text-centered">
      Merci {{ email }} pour votre commande {{ orderref }}.
    </h1>
    <h1>
      Un email récapitulatif vous a été envoyé à votre adresse email
      {{ email }}.
    </h1>
    <div>
      <router-link to="/my-orders" class="button-dark"
        >Vous pouvez retrouvez vos commandes dans votre espace</router-link
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SuccessView",
  data() {
    return {
      orderref: "",
      email: "",
    };
  },
  mounted() {
    let total = this.$store.state.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.final_price.toFixed(2) * curVal.quantity);
      }, 0);
    _paq.push(['trackEcommerceOrder', this.orderref, total]);
    this.$store.commit("clearCart")
    this.getOrderInfos();
  },
  methods: {
    async getOrderInfos() {
      await axios
        .get(`/api/v1/success`, {
          params: { session_id: this.$route.query.session_id },
        })
        .then((response) => {
          this.orderref = response.data.metadata["order_id"];
          this.email = response.data.customer_email;
        })
        .catch((error) => {});
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped></style>
