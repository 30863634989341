<template>
  <div
    class="page-my-account"
    style="
      margin-top: 5rem;
      display: flex;
      justify-content: center;
      margin: 0 auto;
    "
  >
    <div class="account">
      <div class="summarycomponent">
        <AccountNav />
      </div>
      <div class="detailed-section">
        <h1 class="title">Mes retours et remboursement</h1>
        <hr />
        <div v-if="returns.length>0" class="column is-12">
          <ReturnSummary
            v-for="returnasking in returns"
            v-bind:key="returnasking.id"
            v-bind:returnAsking="returnasking"
          />
        </div>
        <div v-else style="display: flex; justify-content: center;align-items: center; flex-direction: column;">
          <h1>Vous n'avez pas de retour pour l'instant.</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ReturnSummary from "@/components/ReturnSummary.vue";
import AccountNav from "@/components/AccountNav.vue";
export default {
  name: "ReturnAndRefundView",
  components: {
    ReturnSummary,
    AccountNav,
  },
  data() {
    return {
      returns: [],
    };
  },
  mounted() {
    this.getMyReturns();
  },
  methods: {
    async getMyReturns() {
      await axios
        .get("/api/v1/returns/")
        .then((response) => {
          this.returns = response.data;
        })
        .catch((error) => {
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-my-account {
  width: 100%;
  padding: clamp(1rem, 2vw, 4rem);
  padding-top: clamp(2rem, 2vw, 4rem);
}
.account {
  width: 100%;
  display: flex;
  gap: 4rem;
  justify-content: center;
  max-width: 1800px;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
  .summarycomponent {
    flex: 2 0 0;
  }
  .detailed-section {
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: 4 0 0;
  }
}
</style>
