<template>
  <div class="page-activation" style="padding-top: 60px">
    <div class="columns">
      <div class="column is-12">
        <h1 class="has-text-centered">Activate page.</h1>
        <button @click="activate()" class="button is-success">
          Click to activate your account.
        </button>
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";

export default {
  name: "ActivateView",
  data() {
    return {
      errors: [],
    };
  },
  methods: {
    async activate() {
      this.errors = [];
      const formData = {
        uid: this.$route.params.uid,
        token: this.$route.params.token,
      };
      await axios
        .post("/api/v1/users/activation/", formData)
        .then((response) => {
          toast({
            message: "Votre compte est maintenant actif, vous pouvez vous y connecter.",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 4000,
            position: "bottom-right",
          });
          this.$router.push("/log-in");
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            this.errors.push("Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard.");
          } else if (error.message) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
          }
        });
    },
  },
};
</script>

<style></style>
