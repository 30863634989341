<template>
  <div style="width: 100%; height: 100%">
    <div class="loader-img" ref="blurredimg">
      <img
        class="block"
        v-bind:src="img"
        style="height: 100%; width: 100%"
        loading="lazy"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
export default {
  name: "ImageLoader",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pending: false,
      img: "",
    };
  },
  beforeMount() {
    const imageUrl = this.url;
    const image = this.getImage(imageUrl);
    if (image) {
      this.img = image;
    } else {
      this.fetchImage(imageUrl);
      this.img = this.url;
    }
  },
  mounted() {
    const blurredImageDivs = document.querySelectorAll(".loader-img");

    function loaded() {
      this.parentNode.classList.add("loaded");
    }

    blurredImageDivs.forEach((blurredImageDiv) => {
      const img = blurredImageDiv.querySelector("img");
      if (img.complete) {
        loaded.call(img);
      } else {
        img.addEventListener("load", loaded);
      }
    });
  },
  computed: {
    ...mapGetters(["getImage"]),
    image() {
      const imageUrl = this.url;
      const image = this.getImage(imageUrl);
      if (image) {
        return image;
      } else {
        this.fetchImage(imageUrl);
        return "";
      }
    },
  },
  methods: {
    ...mapActions(["fetchImage"]),
  },
};
</script>

<style lang="scss">
.block {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.loading {
  display: block;
  position: relative;
  user-select: none;
  cursor: wait;
  transition: all 0.2s;
  border-radius: 4px !important;
  border: none;
  background: transparent;
  overflow: hidden;
  color: transparent !important;
  height: 400px;
  width: 200px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff !important;
    cursor: wait;
    transition: all 0.2s;
  }

  &:after {
    animation: loading 1.5s infinite;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
    background: linear-gradient(
      90deg,
      rgba(67, 32, 165, 0),
      rgba(192, 2, 2, 0.5),
      rgba(255, 255, 255, 0)
    );
  }

  svg {
    opacity: 0;
  }
}
.pipo {
  height: 500px;
}
.loader-img {
  aspect-ratio: 1 / 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(255, 255, 255);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-width: 100%;
  height: 100%;
}

.loader-img::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 2px;
  z-index: 1;
  background-color: grey;
  animation: pulse 2.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.loader-img.loaded::before {
  animation: none;
  content: none;
}

.loader-img img {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.loader-img.loaded img {
  opacity: 1;
}
</style>
