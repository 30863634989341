<template>
  <div class="page-category" style="margin: 0 auto">
    <div class="breadcrumb">
      <router-link to="/">HOME</router-link>
      <div
        style="display: flex"
        v-for="(category, index) in categories"
        v-bind:key="category"
      >
        <span>&nbsp;>&nbsp;</span>
        <router-link v-bind:to="category">
          <span v-bind:class="index == categories.length - 1 ? 'active' : ''">{{
            category.toUpperCase()
          }}</span>
        </router-link>
      </div>
    </div>
    <h2 class="is-size-2">{{ category.name }}</h2>
    <div class="separator"></div>
    <div style="position: sticky; top: 70px; background-color: white; z-index: 1;" v-bind:class="getAnoucementBanner ? 'myclasse' : ''">
      <div class="grid-header">
        <div class="category-info">
          <p>{{ nb_produits }} Produits</p>
        </div>
        <div id="filter-trier">
          Filtrer / Trier &nbsp; <i class="fa-solid fa-sliders"></i>
        </div>
      </div>
      <div class="test">
        <div class="filter-tab">
          <div class="filter">
            <label for="filtres" style="margin-bottom: 1rem">Trier par:</label>
            <div>
              <select name="filtres" id="filtres" v-model="filter">
                <option value="best_sale" @click="refreshProduits">
                  Meilleurs ventes
                </option>
                <option value="-average_rating" @click="refreshProduits">
                  Meilleures note
                </option>
                <option value="-final_price" @click="refreshProduits">
                  Prix décroissant
                </option>
                <option value="final_price" @click="refreshProduits">
                  Prix croissant
                </option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <div>
              <div style="display: flex; gap: 2rem">
                <div>
                  <button @click="refreshProduits" class="button-dark">
                    Appliquer
                  </button>
                </div>
                <div>
                  <button @click="resetFilter" class="button-light">
                    Réinitialiser
                  </button>
                </div>
              </div>
            </div>
          <a style="text-decoration:underline;color: black;cursor: pointer;" id="annuler-filter-trier">
            Annuler
          </a>
        </div>
        <div class="separator"></div>
      </div>
    </div>
    <div style="display: flex">
      <div class="grid-product">
        <ProduitBox
          v-for="produit in produits"
          v-bind:key="produit.id"
          v-bind:produit="produit"
        />
      </div>
    </div>
    <div class="grid-footer">
      <div class="footer-info" v-if="nb_produits">
        <button v-show="showLoadMoreButton" @click="loadMore()" style="cursor: pointer;">
          <span v-if="showMoreIsLoading">
            <i class="fa-solid fa-circle-notch fa-spin"></i>
          </span>
          <span v-else  >
            <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;&nbsp;
            <strong style="color:black !important;">Charger plus</strong>
          </span>
        </button>
        <div style="color: grey">
          {{ produits.length }} produits consultés sur
          {{ nb_produits }} produits
        </div>
      </div>
      <div v-else style="height: 50vh">
        <p>Pas de produits disponibles dans cette catégorie</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import router from "../router";
import { mapGetters } from 'vuex';

import ProduitBox from "@/components/ProduitBox";

export default {
  name: "CategoryView",
  components: {
    ProduitBox,
  },
  data() {
    return {
      category: {
        id: null,
        total_produits: 0,
      },
      categories: [],
      produitConsulted: 0,
      categoryId: null,
      nb_produits: 0,
      produits: [],
      pages: 0,
      showLoadMoreButton: true,
      filter: "best_sale",
      sizefilter: [],
      filterSectionTL: null,
      showMoreIsLoading:false,
    };
  },
  computed:{
    ...mapGetters(['getAnoucementBanner']),

  },
  beforeCreate() {
    this.produits = this.$store.state.produits;
  },
  created() {
    this.$nextTick(function () {
      setTimeout(function () {
        const next= (vm) => {
          vm.gray = true;
        };
        next(this);
      }, 10000);
    });
    this.produits = this.$store.state.produits;
  },
  beforeMount() {
    this.produits = this.$store.state.produits;
  },
  mounted() {
    this.getCategory();
    this.categoryId = this.$store.state.categoryId;
    this.nb_produits = this.$store.state.nb_produits;
    this.produits = this.$store.state.produits;
    if (this.produits.length >= this.nb_produits) {
      this.showLoadMoreButton = false;
    }
    var tl = gsap.timeline({ paused: true });
    tl.to(".test", { height: "auto", duration: 0.4 }).reverse();
    this.filterSectionTL = tl;

    let filter_section = document.getElementById("filter-trier");
    filter_section.addEventListener("click", function () {
      tl.reversed(!tl.reversed());
    });
    let annuler_filter_trier = document.getElementById("annuler-filter-trier");
    annuler_filter_trier.addEventListener("click", function () {
      tl.reversed(!tl.reversed());
    });
  },
  watch: {
    $route(to, from) {
      if (to.name === "category") {
        this.getCategory();
      }
    },
  },
  methods: {
    async getCategory() {
      this.$store.commit("setIsLoading", true);
      const categorySlug = this.$route.params.category_slug;
      const categories = categorySlug.split("/");
      axios
        .get(`/api/v1/collection/${categorySlug}/`)
        .then((response) => {
          this.category = response.data;
          this.categories = this.category.get_absolute_url
            .split("/")
            .filter(Boolean);
          document.title = this.category.name + " | Outsidog";
          // on veut differencier un retour en arrière d'un changement de catégories
          if (this.categoryId != this.category.id) {
            this.produits = [];
            this.$store.commit("resetPage");
            this.getProduits();
            this.$store.commit("setCategory", this.category.id);
            this.$store.commit("setCategoryUrl", this.category.get_absolute_url);
          }
        })
        .catch((error) => {
          toast({
            message: "Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard.",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 4000,
            position: "bottom-right",
          });
        });
        this.$store.commit("setIsLoading", false);
    },
    async getProduits() {
      this.$store.commit("setIsLoading", true);
      let url = `/api/v1/produits/?page=${this.$store.state.page}&categories=${this.category.id}`;
      if (this.filter) {
        url += `&ordering=${this.filter}`;
      }
      if (this.sizefilter.length > 0) {
        url += `&size__in=`;
        this.sizefilter.forEach((element) => {
          url += `${element},`;
        });
      }
      this.showMoreIsLoading = true
      await axios
        .get(url)
        .then((response) => {
          this.showMoreIsLoading = false
          if (this.produits.length <= 0) {
            this.nb_produits = response.data.count;
          }
          this.$store.commit("setNbProduits", this.nb_produits);
          function groupByTailles(array) {
            const groups = {};
            for (const item of array) {
              const key = JSON.stringify(item.tailles);
              if (!groups[key]) {
                groups[key] = [];
              }
              groups[key].push(item);
            }
            return groups;
          }
          const groupedByTailles = groupByTailles(response.data.results);

          for (const key in groupedByTailles) {
            if (groupedByTailles.hasOwnProperty(key)) {
              const groupedObjects = groupedByTailles[key];
              this.produits = this.produits.concat(groupedObjects[0]);
              this.nb_produits = this.nb_produits - groupedObjects.length + 1;
            }
          }
          this.showLoadMoreButton = false;
          if (response.data.next) {
            this.showLoadMoreButton = true;
          }
          this.addToProduit();
        })
        .catch((error) => {
          this.$store.commit("setIsLoading", false);
          this.showMoreIsLoading = false
          if (error.response && error.response.status === 500) {
            this.errors.push("Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard.");
          } else if (error.message) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
          }
        });
        this.$store.commit("setIsLoading", false);
    },
    async loadMore(){
      this.$store.commit("incrementPage");
      this.getProduits();
    },
    resetFilter() {
      this.sizefilter = [];
      this.filter = null;
      this.refreshProduits();
    },
    refreshProduits() {
      window.scrollTo(0,0);
      this.$store.commit("resetPage");
      this.produits = [];
      this.addToProduit();
      this.getProduits();
      this.filterSectionTL.reversed(!this.filterSectionTL.reversed());
    },

    addToProduit() {
      this.$store.commit("addToProduit", this.produits);
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-product {
  margin: 1rem;
  flex: 1 1 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (min-width: 480px) and (max-width: 1250px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width:480px) {
    grid-template-columns: repeat(1, 1fr);
  }
  gap: 1rem;
}
.page-category {
  width: 100vw;
  margin-top: 70px;
  padding: clamp(4px, 3vw, 200px);
  .test {
    height: 0px;
    overflow: hidden;
  }
  max-width: 1950px;
}
.right-filter {
  top: 20px;
  width: 0px;
  margin-left: 0rem;
  overflow: hidden;
}
.grid-header {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid black;
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.breadcrumb {
  display: flex;
  font-size: 0.8rem;
  a {
    margin: 0;
    padding: 0;
    color: grey;
    .active {
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
.separator {
  width: 100%;
  border-bottom: 1px solid black;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.grid-footer {
  display: flex;
  justify-content: center;
  .footer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  button {
    border-radius: none;
    margin: 1rem;
    padding: 1rem;
    width: 30vw;
    background: white;
    border: 1px solid rgb(240, 240, 240);
    transition: 0.2s ease all;
    cursor: pointer;
    &:hover {
      background: rgb(240, 240, 240);
    }
  }
}
#filter-trier {
  cursor: pointer;
  padding: 0.2rem;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid grey;
  }
}
.filter {
  padding-right: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-right: 1px solid grey;
}
.wrapper {
  width: 400px;
  height: 400px;
  overflow: hidden;
  position: relative;
}
.filtres {
  background: white;
}
.filter-tab {
  display: flex;
  flex-direction: row;
  @media (max-width: 950px) {
    flex-direction: column;
  }
}
input {
  accent-color: grey;
}

select {
  font-size: 1rem;
  padding: 1em 1.5em;
  padding-right: 1em;
  background: white;
  color: black;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  border: 1px solid transparent;
  transition: 0.5s ease all;
  &:hover {
    border: 1px solid rgba(143, 141, 141, 0.5);
  }
}
.myclasse{
  top: 95px !important;// + largeur baniière
}
</style>
