<template>
  <div class="page-favorites" style="margin: 0 auto">
    <h2 class="is-size-2">Mes Favoris</h2>
    <div class="separator"></div>
    <div v-if="produits.length>0" style="display: flex">
      <div class="grid-product">
        <ProduitBox
          v-for="produit in produits"
          v-bind:key="produit.id"
          v-bind:produit="produit"
          v-on:removeFromFavorites="removeFromFavorites"
        />
      </div>
    </div>
    <div v-else style="height: 100%;width: 100%;display: flex;justify-content: center;align-items: center;">
      Vous n'avez pas de favoris sauvegardés.
    </div>
  </div>
</template>

<script>
import axios from "axios";

import ProduitBox from "@/components/ProduitBox";

export default {
  name: "FavoritesView",
  components: {
    ProduitBox,
  },
  data() {
    return {
      produits: [],
    };
  },


  mounted() {
    this.getFavoris();
  },
  methods: {
    async getFavoris() {
      axios
        .get("/api/v1/favoris/")
        .then((response) => {
          this.produits = response.data.map((favori) => favori.produit);
          this.$store.commit("addToFavorites", this.produits);        })
        .catch((error) => {
          console.error(error);
        });
    },
    removeFromFavorites(item) {
      this.produits = this.produits.filter(
        (i) => i.unique_id !== item.unique_id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-product {
  flex: 1 1 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (min-width: 900px) and (max-width: 1250px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
    padding-left: 3rem;
    padding-right: 3rem;
  }
  gap: 1rem;
}
.page-favorites {
  width: 100vw;
  margin-top: 70px;
  padding: clamp(4px, 3vw, 200px);
  .test {
    height: 0px;
    overflow: hidden;
  }
  max-width: 1950px;
}
.right-filter {
  top: 20px;
  width: 0px;
  margin-left: 0rem;
  overflow: hidden;
}
.grid-header {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid black;
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.separator {
  width: 100%;
  border-bottom: 1px solid black;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.grid-footer {
  display: flex;
  justify-content: center;
  .footer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  button {
    border-radius: none;
    margin: 1rem;
    padding: 1rem;
    width: 30vw;
    background: white;
    border: 1px solid rgb(240, 240, 240);
    transition: 0.2s ease all;
    &:hover {
      background: rgb(240, 240, 240);
    }
  }
}

.wrapper {
  width: 400px;
  height: 400px;
  overflow: hidden;
  position: relative;
}

input {
  accent-color: grey;
}

select {
  font-size: 1rem;
  padding: 1em 1.5em;
  padding-right: 1em;
  background: white;
  color: black;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  border: 1px solid transparent;
  transition: 0.5s ease all;
  &:hover {
    border: 1px solid rgba(143, 141, 141, 0.5);
  }
}
</style>
