<template>
  <div class="card">
    <div
      v-if="produit.get_discount_percentage"
      style="
        position: absolute;
        z-index: 1;
        background-color: white;
        width: 50px;
        text-align: center;
        font-weight: bold;
      "
    >
      -{{ produit.get_discount_percentage * 100 }}%
    </div>
    <div>
      <figure class="image image--interactive">
        <div v-if="url && produit.images.length >0">
          <div v-if="mobile">
            <CarouselMulitple v-if="isMounted"
              v-bind:images="produit.images"
              :childElement="produit.images.length"
              :childPerSlide="1"
              :url=url
              :pagination="true"
            >
              <template v-slot:image="{ image }">
                <ImageLoader v-if="image" v-bind:url="image.get_image" />
              </template>
              <template #prev-icon>
                <i
                  class="fa-solid fa-chevron-left"
                  style="position: relative; font-size: 30px"
                ></i>
              </template>
              <template #next-icon>
                <i
                  class="fa-solid fa-chevron-right"
                  style="position: relative; font-size: 30px"
                ></i>
              </template>
            </CarouselMulitple>
          </div>
          <div v-else>
            <CarouselMulitple
              v-if="isMounted"
              ref="myRef"
              v-bind:images="produit.images"
              :childElement="produit.images.length"
              :childPerSlide="1"
              :url="produit.get_absolute_url +'?variant=' + encodeURIComponent(produit.unique_id)"
              :pagination="false"
            >
              <template v-slot:image="{ image }">
                <ImageLoader v-bind:url="image.get_image" />
              </template>
              <template #prev-icon>
                <i
                  class="fa-solid fa-chevron-left"
                  style="position: relative; font-size: 30px"
                ></i>
              </template>
              <template #next-icon>
                <i
                  class="fa-solid fa-chevron-right"
                  style="position: relative; font-size: 30px"
                ></i>
              </template>
            </CarouselMulitple>
          </div>
        </div>
        <div v-else style="height: 200px"></div>
        <div v-if="produit.tailles.length > 0">
          <figcaption v-if="!mobile">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
              "
            >
              <div v-for="taille in produit.tailles" v-bind:key="taille.id">
                <router-link
                  v-bind:to="
                    taille.get_absolute_url +
                    '?variant=' +
                    encodeURIComponent(taille.unique_id)
                  "
                  @click="handleLinkClick"
                >
                  <button class="size" v-if="taille.quantity > 0">
                    <strong>{{ taille.size }}</strong>
                  </button>
                  <button class="size disable" v-else>
                    {{ taille.size }}
                  </button>
                </router-link>
              </div>
            </div>
          </figcaption>
        </div>
      </figure>
      <div class="product-header">
        <i
          v-if="isFavori(produit.unique_id) && !firstload"
          @click="unFav(produit)"
          class="fa-solid fa-heart heart-animation"
          style="color: red; cursor: pointer; margin: 10px; font-size: 20px"
        ></i>
        <i
          v-else
          @click="putFav(produit)"
          class="fa-regular fa-heart"
          style="cursor: pointer; margin: 10px; font-size: 20px"
        ></i>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: center;
        "
      >
        <router-link v-bind:to="url"
        @click="handleLinkClick"
          ><h3 class="product-title">{{ produit.label }} </h3></router-link
        >
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <p v-if="!multiple" class="is-size-6 has-text-grey">
          <span v-if="!produit.get_discount_percentage">
            <strong> {{ parseFloat(produit.price).toFixed(2) }}€</strong>
          </span>
          <span v-else>
            <s>{{ parseFloat(produit.price).toFixed(2) }}€</s>
            <strong> {{ produit.get_final_price.toFixed(2) }}€</strong>
          </span>
        </p>
        <p v-else>
          <strong>{{ priceRange }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ImageLoader from "@/components/ImageLoader";
import { toast } from "bulma-toast";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "ProduitBox",
  props: {
    produit: Object,
  },
  components: {
    ImageLoader,
  },
  data() {
    return {
      favoris: [],
      url:
        this.produit.get_absolute_url +
        "?variant=" +
        encodeURIComponent(this.produit.unique_id),
      fav: false,
      final_price: this.produit.get_final_price,
      price: this.produit.price,
      multiple: false,
      priceRange: null,
      firstload: true,
      isMounted: false,
    };
  },
  mounted() {
    const newImage = { get_image: this.produit.get_image };
    // Vérifiez si l'objet newImage n'existe pas déjà dans la liste
    const imageAlreadyExists = this.produit.images.some(image => image.get_image === newImage.get_image);
    
    if (!imageAlreadyExists) {
      // Ajoutez le nouvel objet au début de la liste
      this.produit.images.unshift(newImage);
    }
    const sizeOrder = ["130 cm","150 cm","200 cm","XXS", "XS", "S", "M", "L", "XL", "XXL"];
    this.firstload = false;
    this.isMounted = true;
    if (this.produit.tailles.length > 1) {
      this.produit.tailles.sort((a, b) => {
        const indexA = sizeOrder.indexOf(a.size);
        const indexB = sizeOrder.indexOf(b.size);
        
        if (indexA === -1) {
          return 1; // Move items with unknown sizes to the end
        }
        if (indexB === -1) {
          return -1; // Move items with unknown sizes to the end
        }
        
        return indexA - indexB;
      });
      this.multiple = true;
      const min = this.produit.tailles.reduce(function (prev, curr) {
        return prev.get_final_price < curr.get_final_price ? prev : curr;
      });
      const max = this.produit.tailles.reduce(function (prev, curr) {
        return prev.get_final_price > curr.get_final_price ? prev : curr;
      });
      if (min.get_final_price == max.get_final_price) {
        this.priceRange = min.get_final_price.toFixed(2) + "€";
      } else {
        this.priceRange =
          min.get_final_price.toFixed(2) + "€ - " + max.get_final_price.toFixed(2) + "€";
      }
    }
    gsap.to(".card", {
      scrollTrigger: {
        trigger: ".card",
        toggleActions: "start none reverse none",
      },
      y: -30,
      duration: 0.5,
    });
  },
  methods: {
    getPriceRange() {
      const min = this.produit.tailles.reduce(function (prev, curr) {
        return prev.Cost < curr.Cost ? prev : curr;
      });
      const max = this.produit.tailles.reduce(function (prev, curr) {
        return prev.Cost > curr.Cost ? prev : curr;
      });
      this.priceRange = min.toFixed(2) + "€ - " + max.toFixed(2) + "€";
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "cartItems", "isFavori"]),
    isFavoris() {
      const res = this.produit.tailles.some((obj) => {
        return this.$store.state.favorites.some((favObj) => {
          return favObj.unique_id === obj.unique_id;
        });
      });
      return res;
    },
  },
  methods: {
    handleLinkClick() {
      this.$store.commit("setIsLoading", true);
    },
    changeproduitactive(variant) {
      this.tailles = variant.tailles;
      this.thumbnail = variant.get_thumbnail;
      this.url =
        this.produit.get_absolute_url +
        "?variant=" +
        encodeURIComponent(variant.unique_id);
      this.unique_id = variant.unique_id;
      this.label = variant.label;
      this.final_price = variant.tailles[0].get_final_price;
      this.price = variant.tailles[0].price;
    },
    putFav(variant) {
      _paq.push(["trackEvent", "Favoris", "Fav Click", variant.unique_id]);
      if (this.isAuthenticated) {
        this.$store.commit("addToFavoris", variant.unique_id);
        this.$store.commit("addToFavorite", variant);
        toast({
          message: "Produit ajouté a vos favoris !",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 4000,
          position: "top-right",
        });
        axios
          .post("/api/v1/favoris/ajouter/", {
            produit: variant.unique_id,
          })
          .then((response) => {
          })
          .catch((error) => {
          });
      } else {
        this.$router.push("/my-favorites");
      }
    },
    unFav(variant) {
      if (this.isAuthenticated) {
        this.$store.state.favoris = this.$store.state.favoris.filter(
          (i) => i !== variant.unique_id
        );
        this.$store.state.favorites = this.$store.state.favorites.filter(
          (i) => i.unique_id !== variant.unique_id
        );
        this.$emit("removeFromFavorites", variant);
        localStorage.setItem(
          "favoris",
          JSON.stringify(this.$store.state.favoris)
        );
        this.fav = false;
        axios
          .post("/api/v1/favoris/supprimer/", {
            produit: variant.unique_id,
          })
          .then((response) => {
          })
          .catch((error) => {
          });
      }
    },
  },
  watch: {
    "this.$store.state.favoris": function (newVal) {
      this.favoris = newVal;
    },
  },
  created() {
    this.favoris = this.$store.state.favoris;
  },
};
</script>

<style lang="scss" scoped>
.card {
  button{
    color:black !important;
  }
  display: flex;
  flex-direction: column;
  box-shadow: none;
  background-color: transparent;
  .image {
    width: 100%;
    stroke: 1px solid black;
    object-fit: cover;

    &--interactive {
      position: relative;
      overflow: hidden;
      max-height: 80vh;
    }
  }

  figcaption {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 1;
    .figcaption-content {
      display: flex;
      background-color: grey;
      border-radius: 5px;
      align-items: center;
      width: 80%;
      justify-content: center;
      opacity: 1;
    }
  }

  @media (hover: hover) {
    & figcaption {
      transform: translateY(100%);
      transition: transform 500ms;
    }
    & .figcaption-content {
      opacity: 1;
      transition: opacity 500ms linear 2s;
    }

    & .carousel__multiple__next {
      opacity: 0;
      transition: transform 500ms;
    }
    & .carousel__multiple__prev {
      opacity: 0;
      transition: transform 500ms;
    }
    &:hover figcaption {
      transform: translateY(0%);
    }
    &:hover .carousel__multiple__next {
      opacity: 1;
    }
    &:hover .carousel__multiple__prev {
      opacity: 1;
    }
  }

  .thumbnail {
    display: flex;
    position: relative;
    height: 40px;
    width: 40px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 10%;
    border-color: none;
    border: 1px solid transparent;
    cursor: pointer;
    &--active {
      border: 1px solid black;
      cursor: not-allowed;
      pointer-events: none;
    }
    &:hover {
      border: 1px solid rgba(128, 128, 128, 0.2);
    }

    img {
      padding: 5px;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .variant-section {
    display: flex;
  }
  .size {
    margin: 7px;
    display: flex;
    height: 30px;
    width: 40px;
    justify-content: center;
    align-items: center;
    font-style: bold;
    font-size: 0.75rem;
    border: 1px solid transparent;
    background: transparent;
    cursor: pointer;
    &:hover {
      border: 1px solid rgb(41, 41, 41);
    }
    @media(max-width: 768px){
      height: 26px;
      width: 30px;
    }
  }
  .disable {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
  .product-card {
    display: flex;
    background: #f6f4f2;
  }
  .product-header {
    display: flex;
    justify-content: space-between;
  }
  .product-title {
    color: black;
    font-size: 1.5rem;
    text-decoration: none;
  }
  .half-star {
    &:before {
      -webkit-transform: translateY(+50%);
      -moz-transform: translateY(+50%);
      -ms-transform: translateY(+50%);
      transform: translateY(+50%);
      -webkit-font-smoothing: antialiased;
      text-rendering: auto;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      font: var(--fa-font-solid);
      content: "\f005";
      color: rgba(225, 225, 225, 0.8);
      z-index: -1;
      font-size: 0.8rem;
      position: absolute;
    }
  }
  .heart-animation {
    position: relative;
    animation: heart-pulse 0.5s;
    animation-fill-mode: backwards;
    animation-direction: reverse;

    &:after {
      -webkit-font-smoothing: antialiased;
      text-rendering: auto;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      content: "\f004";
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      animation: heart-pulse-before 0.5s;
      transition: opacity 0.25s ease-in;
    }
  }

  @keyframes heart-pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes heart-pulse-before {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    25% {
      opacity: 0.25;
      transform: scale(1.8);
    }
    50% {
      opacity: 0.6;
      transform: scale(1.8);
    }
    100% {
      opacity: 0.7;
      transform: scale(0);
    }
  }
}
.image img {
  width: 100%;
  height: auto;
}
.card {
  overflow-x: hidden;
}
</style>
