<template>
  <div
    class="page-not-found"
    style="
      padding-top: 60px;
      height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    "
  >
    <h1 class="title has-text-centered">Nous sommes désolé,</h1>
    <h1 class="has-text-centered">
      la page que vous cherchez n'existe pas où à été déplacée.
    </h1>
    <p style="margin: 1rem">
      <button
        @click="this.$router.back(-1)"
        style="
          color: black;
          text-decoration: underline;
          border: none;
          background: none;
          cursor: pointer;
          font-size: 1rem;
        "
      >
        Revenir à la page précédente
      </button>
      ou
      <router-link
        to="/"
        class="link"
        style="color: black; text-decoration: underline"
        >Accueil</router-link
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "NotFoundView",
  data() {
    return {};
  },
};
</script>

<style></style>
