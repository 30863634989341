<template>
    <div class="announcement-banner" v-show="showAnnouncement">
      <p>{{ announcementText.toUpperCase() }}</p>
      <button @click="hideAnnouncement"><i class="fa-solid fa-xmark" style="color: white;"></i></button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showAnnouncement: true,
        announcementText: "Livraison gratuite à partir de 60€ d'achat",
      };
    },
    methods: {
      hideAnnouncement() {
        this.showAnnouncement = false;
        this.$store.commit("dismissAnnouncement");
      },
    },
  };
  </script>
  
  <style scoped>
  .announcement-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fc6500;
    color: #000;
    text-align: center;
    width: 100%;
    padding: 0.3rem;
    height: 28px; /*ATTENtion lié a la div dans app pour gérer le décalage quand  banière false */
    font-size: clamp(0.6rem,2.5vw,0.8rem);
    color: white;
    font-weight: bold;
  }
  
  button {
    background-color: transparent;
    border: none;
    margin-left: 1rem ;
    cursor: pointer;
    text-align: center;
  }
  </style>