<template>
  <div>
    <div class="loader-img" ref="blurredimg">
      <figure
        ref="demo"
        class="image"
        @click="zoom()"
        @mouseleave="zoomDisable()"
      >
        <div
          v-show="zoomI"
          class="trigger"
          style="height: 100%; width: 100%; position: absolute"
        ></div>

        <img
          ref="imgRef"
          class="demo-trigger"
          :src="url"
          :data-zoom="urlZoom"
        />
      </figure>
    </div>
  </div>
</template>

<script>
import Drift from "drift-zoom";
import ImageLoader from "@/components/ImageLoader";

export default {
  name: "ImageZoomed",
  props: {
    url: String,
    urlZoom: String,
  },
  components: {
    ImageLoader,
  },
  data() {
    return {
      drift: null,
      zoomI: true,
    };
  },
  mounted() {
    var demoTrigger = this.$refs.imgRef;
    var paneContainer = this.$refs.demo;

    this.drift = new Drift(demoTrigger, {
      paneContainer: paneContainer,
      inlinePane: 375,
    });
    this.drift.disable();
    const img = this.$refs.imgRef;

    if (img.complete) {
      this.loaded();
    } else {
      img.addEventListener("load", this.loaded);
    }
  },
  methods: {
    loaded() {
      this.$refs.blurredimg.classList.add("loaded");
    },
    zoom() {
      this.drift.enable();
      this.zoomI = !this.zoomI;
      if (this.zoomI) {
        this.drift.disable();
      }
    },
    zoomDisable() {
      if (!this.zoomI) {
        this.zoomI = !this.zoomI;
      }
      this.drift.disable();
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  &:hover {
    cursor: zoom-in;
  }
  max-height: 100%;
}
.demo-trigger {
  height: 100%;
  object-fit: cover;
}

.loader-img {
  aspect-ratio: 1 / 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(255, 255, 255);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-width: 100%;
}

.loader-img::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 2px;
  z-index: 1;
  background-color: rgb(98, 98, 98);
  animation: pulse 2.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.loader-img.loaded::before {
  animation: none;
  content: none;
}

.loader-img img {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.loader-img.loaded img {
  opacity: 1;
}
</style>
