<template>
  <div
    class="page page-faq"
    style="margin-left: 1rem;
      margin-right: 1rem;
      margin: 0 auto;
    "
  >
    <h1 class="title" style="display: flex; justify-content: center">
      AIDE - FAQ
    </h1>
    <div class="breadcrumb">
      <router-link to="/">ACCUEIL</router-link>
      <div
        style="display: flex"
        v-for="(route, index) in routes"
        v-bind:key="route"
      >
        <span>&nbsp;>&nbsp;</span>
        <router-link v-bind:to="route">
          <span v-bind:class="index == routes.length - 1 ? 'active' : ''">{{
            route.toUpperCase()
          }}</span>
        </router-link>
      </div>
    </div>

    <div class="faq">
      <div class="faqnav">
        <FaqNav />
      </div>
      <div class="faqbody">
        <h1 id="mainquestions" class="title">Prinicpales questions</h1>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >Comment mesurer mon animal ?</h1>
          </template>
          <template #content>
            <p>
              Pour mesurer votre animal correctement et choisir la taille
              appropriée, veuillez suivre ces étapes simples :
            </p>
            <ul>
              <li>
                Référez-vous au guide des tailles spécifique à chaque produit
                que nous proposons. Vous trouverez ce guide sur la page du
                produit, sous l'onglet "Guide des tailles" ou "Mesure de
                l'animal".
              </li>
              <li>
                Prenez les mesures de votre animal en utilisant un ruban à
                mesurer souple. Assurez-vous que votre animal est debout ou en
                position normale pour obtenir des mesures précises.
              </li>
              <li>
                Suivez les instructions du guide des tailles pour savoir quelles
                parties de l'animal doivent être mesurées. Cela peut inclure la
                longueur du dos, la circonférence du cou, la circonférence de la
                poitrine, etc.
              </li>
              <li>
                Comparez les mesures de votre animal avec les mesures indiquées
                dans le guide des tailles. Choisissez la taille qui correspond
                le mieux aux mesures de votre animal. En cas de doute entre deux
                tailles, nous vous recommandons de choisir la taille supérieure
                pour assurer un meilleur confort à votre animal.
              </li>
              <li>
                Veuillez noter que les tailles peuvent varier d'un produit à
                l'autre en fonction des fournisseurs. Il est donc important de
                se référer au guide des tailles spécifique à chaque produit pour
                obtenir les mesures les plus précises.
              </li>
            </ul>
            <p>
              Si vous avez des questions supplémentaires concernant la mesure de
              votre animal ou si vous avez besoin d'aide pour choisir la taille
              appropriée, n'hésitez pas à nous contacter. Notre équipe du
              service clientèle est là pour vous aider à trouver le produit qui
              convient le mieux à votre animal.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Je me suis trompé(e) dans la taille de mon accessoire, est-il
              possible d'échanger ?
            </h1>
          </template>
          <template #content>
            <p>
              Si vous vous êtes trompé(e) dans la taille de votre accessoire,
              veuillez nous contacter dès que possible. Nous ferons de notre
              mieux pour vous aider à trouver une solution. Dans certains cas,
              nous pourrons procéder à un échange, sous réserve de
              disponibilité. Pour procéder au retour veuillez vous référer à la procédure décrite <router-link
              to="/conditions-generales-de-ventes/#return_process"
              style="
                color: rgba(255, 255, 255, 0.7);
                text-decoration: underline;
              "
              >ici</router-link> dans les condition générales de ventes. Veuillez consulter notre politique de retour et
              d'échange pour plus d'informations.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Sous combien de jours est envoyée ma commande ?
            </h1>
          </template>
          <template #content>
            <p>
              Nous nous efforçons d'expédier les commandes dans un délai de 2
              jours ouvrés à partir de la date de confirmation de votre
              commande. Une fois votre commande emballée avec soin, elle sera
              expédiée. Le délai de livraison estimé est de 2 à 5
              jours ouvrés, en fonction de votre emplacement. Veuillez noter que
              ces délais sont indicatifs et peuvent varier en fonction des
              conditions locales et des retards éventuels rencontrés par le
              transporteur.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >Puis-je modifier ma commande ?</h1>
          </template>
          <template #content>
            <p>
              Si vous souhaitez modifier votre commande, veuillez nous contacter
              dès que possible. Selon l'état de traitement de votre commande,
              nous ferons de notre mieux pour vous aider à effectuer les
              modifications nécessaires. Veuillez noter que certaines
              modifications peuvent ne pas être possibles si la commande est
              déjà en cours de préparation ou d'expédition.
            </p>
          </template>
        </CollapsingItem>
        <h1 id="compte" class="title">Mon compte</h1>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >Comment créer un compte ?</h1>
          </template>
          <template #content>
            <p>
              Pour créer un compte, cliquez ici <router-link
              to="/sign-up"
              style="
                text-decoration: underline;
              "
              >Créer son compte</router-link>.
              Vous pouvez aussi cliquez sur l'icône <i class="fa-regular fa-user"></i> dans la
              barre de navigation, située à droite. Vous serez alors redirigé(e)
              vers une page où vous aurez le choix de vous connecter si vous
              avez déjà un compte, ou de créer un nouveau compte. Sélectionnez
              l'option "Créer un compte" pour être dirigé(e) vers la page de
              création de compte, où vous pourrez remplir les informations
              nécessaires.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >Comment accéder à mon compte ?</h1>
          </template>
          <template #content>
            <p>
              Pour accéder à votre compte, vous pouvez cliquer sur l'icône
              <i class="fa-regular fa-user"></i> située à droite dans la barre
              de navigation ou en cliquant sur ce lien <router-link
              to="/my-account"
              style="
                text-decoration: underline;
              "
              >Mon compte</router-link>. Pour toutes les
              pages nécessitant de se connecter (finalisation de la commande,
              historiques des commandes et retours, informations personnelles,
              changement email et mot de passe, liste des favoris) vous serez
              automatiquement redirigé vers la page de connexion.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >Comment ajouter des favoris ?</h1>
          </template>
          <template #content>
            <p>
              Pour enregistrer des favoris, vous devez d'abord vous connecter à
              votre compte. Une fois connecté, vous pouvez cliquer sur l'icône
              <i class="fa-regular fa-heart fa-lg"></i> située sous les produits
              pour les ajouter à vos favoris. Vous pourrez ensuite accéder à vos
              favoris en cliquant sur l'icône
              <i class="fa-regular fa-heart fa-lg"></i> située à droite dans la
              barre de navigation. Cela vous permettra de retrouver facilement
              vos produits préférés.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Comment ajouter, modifier ou supprimer une adresse ?
            </h1>
          </template>
          <template #content>
            <p>Vos adresses de livraisons et d'expéditions sont accessibles depuis votre compte dans  <router-link
              to="/my-account"
              style="
                text-decoration: underline;
              "
              >Mes informations et adresses</router-link>. Sur cette page vous pourrez modifier votre adresse en cliquant sur "Modifier" en dessous de l'adresse.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Comment modifier mon email ou mot de passe ?
            </h1>
          </template>
          <template #content>
            <p>Vous pouvez modifier votre email en accédant à la page  <router-link
              to="/change-pwd"
              style="
                text-decoration: underline;
              "
              >Changer mon mot de passe ou email</router-link>.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >Se désinscrire des offres</h1>
          </template>
          <template #content>
            <p>
              Ouvrez l'un des e-mails de notre newsletter que vous avez reçu.
              Faites défiler jusqu'en bas de l'e-mail, où vous trouverez un lien
              de désinscription. Cliquez sur le lien de désinscription. Cela
              vous dirigera vers une page où vous pourrez confirmer votre
              désinscription. Sur la page de confirmation, veuillez vérifier que
              votre adresse e-mail est correctement affichée. Confirmez votre
              désinscription en cliquant sur le bouton approprié. Une fois que
              vous aurez suivi ces étapes, vous ne recevrez plus notre
              newsletter. Veuillez noter qu'il est possible que vous continuiez
              à recevoir des e-mails transactionnels ou des notifications liées
              à vos commandes. Si vous rencontrez des difficultés ou avez des
              questions supplémentaires, n'hésitez pas à nous contacter via
              notre formulaire de contact. Nous serons ravis de vous aider.
            </p>
          </template>
        </CollapsingItem>
        <h1 id="commandes" class="title">Commandes</h1>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Est ce que les paiements sont sécurisés ?
            </h1>
          </template>
          <template #content>
            <p>
              Absolument ! Nous comprenons l'importance de la sécurité des
              paiements en ligne. C'est pourquoi nous avons choisi d'utiliser
              Stripe avec la solution Stripe Checkout pour traiter vos
              transactions. Stripe est l'un des principaux fournisseurs de
              services de paiement en ligne, offrant une plateforme sécurisée et
              fiable pour effectuer des paiements sur Internet. Lorsque vous
              passez une commande sur notre site, vous serez redirigé(e) vers
              Stripe Checkout, où vous pourrez saisir vos informations de
              paiement en toute sécurité. Stripe utilise des mesures de sécurité
              avancées pour protéger vos données personnelles et financières.
              Toutes les informations de paiement que vous fournissez sont
              cryptées et transmises de manière sécurisée. Nous n'avons jamais
              accès à vos informations sensibles, telles que les détails de
              votre carte de crédit. De plus, Stripe est conforme aux normes de
              sécurité les plus strictes de l'industrie des paiements en ligne,
              y compris la norme PCI DSS (Payment Card Industry Data Security
              Standard). Cette norme garantit que les informations de paiement
              sont traitées et stockées en toute sécurité. Nous mettons tout en
              œuvre pour assurer la sécurité de vos transactions et protéger
              votre confidentialité. Vous pouvez donc effectuer vos achats en
              toute tranquillité sur notre site, en sachant que vos paiements
              sont sécurisés grâce à Stripe Checkout. Si vous avez des questions
              supplémentaires concernant la sécurité des paiements ou si vous
              rencontrez des problèmes lors du processus de paiement, n'hésitez
              pas à nous contacter. Nous serons ravis de vous aider à résoudre
              tout problème ou préoccupation.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Comment utiliser un code promotionnel ?
            </h1>
          </template>
          <template #content>
            <p>
              Une fois que vous avez ajouté
              tous les articles que vous souhaitez acheter à votre panier,
              rendez-vous sur la page de
              <router-link to="/cart/checkout">checkout</router-link>. Sur la
              page de checkout, vous verrez un champ intitulé "Code
              Promo". C'est là que vous pouvez
              saisir le code que vous avez reçu. Entrez le code promotionnel
              dans le champ prévu à cet effet, en veillant à respecter la casse
              et les éventuels caractères spéciaux. Cliquez sur la flèche
              à côté du champ. Le système vérifiera le
              code et appliquera automatiquement les réductions ou avantages
              associés. Vérifiez que la réduction a bien été
              appliquée au montant total de votre commande. Vous devriez voir
              une mise à jour immédiate du prix et une alerte confirmant
              que le code a été accepté avec succès. Veuillez noter que certains
              codes promotionnels peuvent avoir des conditions spécifiques,
              telles que des dates d'expiration ou des restrictions sur certains
              produits. Assurez-vous de lire attentivement les termes et
              conditions associés au code promotionnel que vous utilisez. Si
              vous rencontrez des difficultés pour utiliser un code promotionnel
              ou si vous avez des questions supplémentaires, n'hésitez pas à
              nous contacter. Notre équipe du service clientèle sera ravie de
              vous aider à profiter pleinement des avantages offerts par les
              codes promotionnels.
            </p>
          </template>
        </CollapsingItem>
        <h1 id="livraison" class="title">Livraison</h1>

        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Sous combien de jours ma commande est-elle expédiée ?
            </h1>
          </template>
          <template #content>
            <p>
              Votre commande est traitée et prête à être expédiée dans un délai
              de 2 à 3 jours ouvrés à compter de la validation du paiement.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Combien de temps prend la livraison ?
            </h1>
          </template>
          <template #content>
            <p>
              Votre commande sera expédiée à votre domicile dans un délai de 2 à
              5 jours ouvrés, en fonction du mode de livraison choisi. Veuillez
              noter que cette période de livraison est une indication moyenne
              basée sur nos observations, et nous ne pourrons être tenus
              responsables en cas de retard de livraison.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Comment puis-je suivre ma commande ?
            </h1>
          </template>
          <template #content>
            <p>
              Une fois que votre commande aura été préparée et remise au
              transporteur, vous recevrez un e-mail de confirmation contenant un
              numéro de suivi. Ce numéro vous permettra de suivre la progression
              de la livraison de votre colis.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Je n'ai pas reçu de e-mail de confirmation ?
            </h1>
          </template>
          <template #content>
            <p>
              N'hésitez pas à vérifier vos courriels indésirables où nos
              messages pourraient se trouver. Si vous ne trouvez pas notre email
              après vérification, veuillez nous contacter à l'adresse suivante : contact@outsidog.com
            </p>
          </template>
        </CollapsingItem>
        <h1 id="retour" class="title">Retours et remboursements</h1>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              La taille du produit ne convient pas,
              est-il possible de l'échanger ?
            </h1>
          </template>
          <template #content>
            <p>
              Oui, heureusement ! Une fois que vous aurez reçu votre commande,
              vous disposez de 14 jours pour renvoyer le produit qui ne convient
              pas. Veuillez nous envoyer un e-mail à contact@outsidog.com afin
              que nous puissions réserver la référence souhaitée pour vous.
              Pour procéder au retour veuillez vous référer à la procédure décrite <router-link
              to="/conditions-generales-de-ventes/#return_process"
              style="
                color: rgba(255, 255, 255, 0.7);
                text-decoration: underline;
              "
              >ici</router-link> dans les condition générales de ventes. Une fois que nous aurons réceptionné le
              produit, nous vous enverrons le produit souhaité. Les frais de
              retour sont à votre charge. Veuillez consulter notre politique de retour et
              d'échange pour plus d'informations.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              J'ai changé d'avis, acceptez-vous les remboursements ?
            </h1>
          </template>
          <template #content>
            <p>
              Nous faisons toujours de notre mieux pour vous satisfaire, donc si
              vous n'avez pas eu de coup de cœur pour un échange, nous acceptons
              de vous rembourser. Vous disposez d'un délai de 14 jours après
              réception de votre colis pour nous retourner votre commande et
              obtenir un remboursement si elle ne vous convient pas. Veuillez
              noter que les produits abîmés, tachés ou sans leur emballage
              d'origine ne pourront pas être remboursés. Pour procéder au retour veuillez vous référer à la procédure décrite <router-link
              to="/conditions-generales-de-ventes/#return_process"
              style="
                color: rgba(255, 255, 255, 0.7);
                text-decoration: underline;
              "
              >ici</router-link> dans les condition générales de ventes. Une fois que nous aurons réceptionné le
              produit, nous vous enverrons le produit souhaité. Les frais de retour
              sont à votre charge, et le remboursement sera effectué sur la
              valeur totale des produits (hors frais de port). Veuillez consulter notre politique de retour et
              d'échange pour plus d'informations.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Sous combien de jours je serais remboursé ?
            </h1>
          </template>
          <template #content>
            <p>
              Le remboursement sera effectué sur la carte bancaire utilisée pour
              l'achat. Nous effectuons les remboursements dans un délai maximum
              de 14 jours suivant la réception de votre commande, à condition
              que les produits soient restitués en bon état.
            </p>
          </template>
        </CollapsingItem>
        <h1 id="produit" class="title">Produits</h1>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              De quoi sont constitués les produits Outdisog ?
            </h1>
          </template>
          <template #content>
            <p>Les matériaux utilisés dans nos produits varient en fonction de l'article spécifique que vous consultez. Vous trouverez des détails sur la composition des matériaux dans la section "Composition du produit" sur la page du produit concerné.</p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Comment entretenir les produits Outsidog ?
            </h1>
          </template>
          <template #content>
            <p> L'entretien de nos produits est facile, et vous trouverez des conseils détaillés dans la section "Entretien du produit" sur la page du produit concerné. En général, la plupart de nos articles peuvent être lavés en machine à l'eau froide ou tiède avec des couleurs similaires. Évitez d'utiliser de l'eau de Javel ou des agents de blanchiment similaires. Séchez les produits à l'air libre ou suivez les instructions spécifiques fournies sur l'étiquette du produit.</p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Comment trouver la taille pour mon chien ?
            </h1>
          </template>
          <template #content>
            <p>
              Pour trouver la taille idéale pour votre chien, vous pouvez consulter notre guide des tailles. Il se trouve dans la section "Guide des tailles" sur la page du produit que vous souhaitez acheter. Le guide des tailles vous fournira des informations détaillées sur les mesures appropriées en fonction de la taille de votre chien. Assurez-vous de mesurer avec précision votre chien en suivant les instructions du guide pour obtenir le meilleur ajustement possible.
            </p>
          </template>
        </CollapsingItem>
        <CollapsingItem :border="true">
          <template #title>
            <h1 >
              Le produit que je veux n'est pas en stock, quand sera t-il
              disponible ?
            </h1>
          </template>
          <template #content>
            <p>
              Si le produit que vous souhaitez n'est pas en stock, vous pouvez
              utiliser le bouton "Créer une alerte" sur la page du produit pour
              être informé(e) dès qu'il sera à nouveau disponible. Il vous
              suffit de renseigner votre adresse e-mail pour recevoir un mail
              lors du réassortiment.
            </p>
          </template>
        </CollapsingItem>
      </div>
    </div>
  </div>
</template>

<script>
import CollapsingItem from "@/components/CollapsingItem";
import FaqNav from "@/components/FaqNav";
export default {
  components: {
    CollapsingItem,
    FaqNav,
  },
  name: "FaqView",
  data() {
    return {
      path: "",
      routes: [],
    };
  },
  mounted() {
    this.path = this.$router.currentRoute;
    this.routes = this.path.path.split("/").filter(Boolean);
  },
  methods: {},

  computed: {},
};
</script>

<style lang="scss" scoped>
.page-faq {
  width: 100%;
  padding: clamp(1.2rem, 4vw, 4rem);
}
.mentions-legales {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: clamp(14px, 1vw, 16px);
  text-align: justify;
  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: clamp(20px, 1vw, 30px);
    font-weight: bold;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: clamp(20px, 1vw, 30px);
    font-weight: bold;
    margin-bottom: 1rem;
  }
  ul {
    padding-top: 1rem;
    padding-bottom: 1rem;
    list-style-type: disc;
    list-style-position: inside;
  }
}
.breadcrumb {
  display: flex;
  font-size: 0.8rem;
  a {
    margin: 0;
    padding: 0;
    color: grey;
    .active {
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

.faq {
  width: 100%;
  display: flex;
  gap: 4rem;
  justify-content: center;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
  .faqnav {
    flex: 2 0 0;
  }

  .faqbody {
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: 5 0 0;
    p {
      font-size: clamp(0.8rem, 4vw, 1rem);
    }
    h1 {
      font-size: clamp(1rem, 4vw, 1.5rem);
    }
  }
}
.title {
  padding-top: 5rem;
  padding-bottom: 1rem;
  &:first-child {
    padding-top: 0rem;
  }
}
</style>
