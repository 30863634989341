<template>
  <div
    class="page-my-account"
    style="display: flex; justify-content: center"
  >
    <div class="account">
      <div class="summarycomponent">
        <AccountNav />
      </div>
      <div class="detailed-section">
        <h1 class="title">Mes informations et adresses</h1>
        <hr />
        <PersonnalInformationsForm />
        <hr />
        <AddressForm typeAddress="shipping" :checkout="false" />
        <hr />
        <AddressForm typeAddress="billing" :checkout="false" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import AddressForm from "@/components/AddressForm.vue";
import AccountNav from "@/components/AccountNav.vue";
import PersonnalInformationsForm from "@/components/PersonnalInformationsForm.vue";
import { useVuelidate } from "@vuelidate/core";
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "AccountView",
  components: {
    AddressForm,
    AccountNav,
    PersonnalInformationsForm
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  data() {
    return {
      errors_pwd: [],
      errors_email: [],
      orders: [],
      showPassword: false,
      first_name: null,
      last_name: null,
      updateinfo:false,
    };
  },
  mounted() {
    document.title = "My account | Outsidog";
    this.getMyOrders();
    this.getUserInfo();
  },
  validations() {
    return {
      first_name: { required, $lazy: true }, // Matches this.firstName
      last_name: { required, $lazy: true }, // Matches this.lastName
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async getUserInfo() {
      await axios
        .get(`/api/v1/users/me/`)
        .then((response) => {
          this.first_name = response.data.first_name;
          this.last_name = response.data.last_name;
        })
        .catch((error) => {});
      return -1;
    },
    async getMyOrders() {
      await axios
        .get("/api/v1/orders/")
        .then((response) => {
          this.orders = response.data;
        })
        .catch((error) => {
        });
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      const error = document.getElementsByClassName("error-message");
      if (error.length > 0) {
        error[0].parentNode.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      if (isFormCorrect) {
        const formData = {
          first_name: this.first_name,
          last_name: this.last_name,
        };
        this.updateinfo = true;
        await axios
          .patch("/api/v1/users/me/", formData)
          .then((response) => {
            toast({
              message: "Vos informations ont été mise à jours !",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 4000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            this.errors = [];
            if (error.response && error.response.status === 500) {
              this.errors.push(
                "Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard."
                );
            } else if (error.message) {
              for (const property in error.response.data) {
                  this.errors.push(`${error.response.data[property]}`);
                }
            }
          });
      }
        this.updateinfo = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-my-account {
  width: 100%;
  padding-top: clamp(2rem, 2vw, 4rem);
  padding: clamp(1rem, 2vw, 4rem);
}
.account {
  width: 100%;
  display: flex;
  gap: 4rem;
  justify-content: center;
  max-width: 1800px;

  @media (max-width: 1250px) {
    flex-direction: column;
  }
  .summarycomponent {
    flex: 2 0 0;
  }
  .detailed-section {
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: 4 0 0;
  }
}
</style>
