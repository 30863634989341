<template>
  <div
    class="page-my-account"
    style="display: flex; justify-content: center"
  >
    <div class="account">
      <div class="summarycomponent">
        <AccountNav />
      </div>
      <div class="detailed-section">
        <h1 class="title">Mes commandes</h1>

        <hr />
        <div v-if="orders.length>0" class="column is-12">
          
          <OrderSummary
            v-for="order in orders"
            v-bind:key="order.id"
            v-bind:order="order"
          />
        </div>
        <div v-else style="display: flex; justify-content: center;align-items: center; flex-direction: column;">

          <h1>Vous n'avez pas de commandes pour l'instant.</h1>
          <h1 style="margin-top: 2rem;">Si vous venez de passer une commande elle peut mettre du temps avant de s'afficher.</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import OrderSummary from "@/components/OrderSummary.vue";
import AddressForm from "@/components/AddressForm.vue";
import AccountNav from "@/components/AccountNav.vue";
export default {
  name: "OrdersView",
  components: {
    OrderSummary,
    AddressForm,
    AccountNav,
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  data() {
    return {
      errors_pwd: [],
      errors_email: [],
      orders: [],
      showPassword: false,
    };
  },
  mounted() {
    this.getMyOrders();
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    async getMyOrders() {
      await axios
        .get("/api/v1/orders/")
        .then((response) => {
          this.orders = response.data;
        })
        .catch((error) => {
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-my-account {
  width: 100%;
  padding-top: clamp(2rem, 2vw, 4rem);
  padding: clamp(1rem, 2vw, 4rem);
}
.account {
  width: 100%;
  display: flex;
  gap: 4rem;
  justify-content: center;
  max-width: 1800px;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
  .summarycomponent {
    flex: 2 0 0;
  }
  .detailed-section {
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: 4 0 0;
  }
}
</style>
