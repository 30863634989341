<template>
  <div style="margin-bottom: 1rem" :class="{ border: border }">
    <div @click="collapse" class="collapsing">
      <div class="collapsing-switch" :class="{ 'flex-start': alignStart }">
        <slot name="title" class="title"></slot>
        <i ref="arrowRef" class="arrow fa-solid fa-angle-right"></i>
      </div>
    </div>
    <div class="content" ref="myRef">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CollapsingItem",
  props: {
    border: Boolean,
    alignStart: Boolean,
  },
  data() {
    return {
      collapsed: false,
      contentTL: null,
      arrowTL: null,
    };
  },
  mounted() {
    var contentTL = gsap.timeline({ paused: true });
    var arrowTL = gsap.timeline({ paused: true });
    contentTL
      .to(this.$refs.myRef, {
        height: "auto",
        ease: "power3.inOut",
        margin: "1rem 0",
        duration: 0.4,
      })
      .reverse();
    this.contentTL = contentTL;
    arrowTL.to(this.$refs.arrowRef, { rotate: +90, duration: 0.2 }).reverse();
    this.arrowTL = arrowTL;
  },
  methods: {
    collapse() {
      this.collapsed = !this.collapsed;
      this.contentTL.reversed(!this.contentTL.reversed());
      this.arrowTL.reversed(!this.arrowTL.reversed());
    },
    open() {
      if (!this.collapsed) {
        this.collapsed = !this.collapsed;
        this.contentTL.reversed(!this.contentTL.reversed());
        this.arrowTL.reversed(!this.arrowTL.reversed());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collapsing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-bottom: 1rem;
}
.flex-start {
  justify-content: start !important;
}
.border {
  border-bottom: 1px solid grey;
}
.collapsing-switch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.content {
  height: 0px;
  overflow: hidden;
}
</style>
