<template>
  <div class="page-cart" style="margin-top: 60px">
    <div>
      <h1 class="title">OUTSIDOG sitemap</h1>
      <div
        style="
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
        "
      >
      </div>
      <div>
        <h1 class="subtitle" style="padding-top: 1rem">Catégories</h1>
        <div class="grid">
          <div style="flex: 1 0 0; display: flex; flex-direction: column">
            <p>Vêtements - https://outsidog.com/collection/vetements</p>
            <p>Vestes & Gilets - https://outsidog.com/collection/vestes-et-gilets</p>
            <p>Hauts - https://outsidog.com/collection/hauts</p>
            <p>Equipements - https://outsidog.com/collection/equipements</p>
            <p>Colliers - https://outsidog.com/collection/colliers</p>
            <p>Harnais - https://outsidog.com/collection/harnais</p>
            <p>Laisses - https://outsidog.com/collection/laisses</p>
            <p>Tous les produits - https://outsidog.com/collection/all</p>
            <p>Urbaine - https://outsidog.com/collection/urbain</p>
            <p>Hivernale - https://outsidog.com/collection/hiver</p>
            <p>Pluvieuses - https://outsidog.com/collection/pluie</p>
          </div>
        </div>
      </div>
      <div>
        <h1 class="subtitle" style="padding-top: 1rem">Pages</h1>
        <div class="grid">
          <div style="display: flex; flex-direction: column">
            <p>Home - https://outsidog.com/</p>
            <p>FAQ - https://outsidog.com/faq/</p>
            <p>Contact - https://outsidog.com/contact</p>
            <p>About us - https://outsidog.com/about-us</p>
            <p>Nos actualités- https://outsidog.com/news</p>
            <p>Mentions légales - https://outsidog.com/mentions-legales</p>
            <p>Condition générales de ventes - https://outsidog.com/conditions-generales-de-ventes</p>
            <p>Mes favoris - https://outsidog.com/my-favorites</p>
            <p>Mon compte - https://outsidog.com/my-account</p>
            <p>Mes commandes - https://outsidog.com/my-orders</p>
            <p>Mes retours et remboursements - https://outsidog.com/my-return-and-refund</p>
            <p>Changer mon mot de passe ou email - https://outsidog.com/change-pwd</p>
            <p>Checkout - https://outsidog.com/cart/checkout</p>
            <p>Connexion - https://outsidog.com/log-in</p>
            <p>Créer un compte - https://outsidog.com/sign-up</p>
            <p>Mot de passe oublié - https://outsidog.com/forgot-pwd</p>
            <p>Commande annulée - https://outsidog.com/checkout/cancel</p>
            <p>Confirmation de commande - https://outsidog.com/checkout/success</p>
            <p>Site map - https://outsidog.com/sitemap</p>
            <p>Reset mot de passe - https://outsidog.com/password/reset/confirm </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SiteMapView",
  data() {
    return {
      orderref: "",
      email: "",
    };
  },
  mounted() {
    this.getOrderInfos();
  },
  methods: {
    async getOrderInfos() {
      await axios
        .get(`/api/v1/success`, {
          params: { session_id: this.$route.query.session_id },
        })
        .then((response) => {
          this.orderref = response.data.metadata["order_id"];
          this.email = response.data.customer_email;
        })
        .catch((error) => {});
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.page-cart {
  margin-top: 70px;
  padding: clamp(4px, 3vw, 200px);
  max-width: 1950px;
}

.grid {
  flex: 1 1 0;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
</style>
