<template>
  <div
    class="page-not-found"
    style="
      margin-top: 60px;
      height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    "
  >
    <h1 class="title has-text-centered">Paiement interrompu</h1>
    <h1 class="has-text-centered">
      Le processus de paiement via Stripe à été interrompu.
    </h1>
    <p style="margin: 1rem">
      <router-link
        to="/cart/checkout"
        class="link"
        style="color: black; text-decoration: underline"
        >Retour au panier
      </router-link>
      ou
      <router-link
        to="/"
        class="link"
        style="color: black; text-decoration: underline"
        >Accueil</router-link
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "CancelView",
  data() {
    return {};
  },
};
</script>

<style></style>
