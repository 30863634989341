const state = {
  images: {},
};

const getters = {
  getImage: (state) => (url) => {
    return state.images[url];
  },
};

const mutations = {
  ADD_IMAGE(state, payload) {
    if (!state.images[payload.url]) {
      const image = new Image();
      image.onload = () => {
        state.images[payload.url] = image.src;
      };
      image.onerror = (error) => {
        console.error(`Failed to load image with URL: ${payload.url}`, error);
      };
      image.src = payload.url;
    }
  },
};

const actions = {
  fetchImage({ commit, getters }, url) {
    return new Promise((resolve, reject) => {
      const cachedImage = getters.getImage(url);
      if (cachedImage) {
        resolve(cachedImage);
      } else {
        const image = new Image();
        image.onload = () => {
          commit("ADD_IMAGE", { url, data: image.src });
          resolve(image.src);
        };
        image.onerror = (error) => {
          reject(error);
        };
        image.src = url;
      }
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
