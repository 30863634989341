<template>
  <div class="page-sign-up" style="margin-top: 60px; margin: 0 auto;padding-top: clamp(1.2rem, 4vw, 4rem);">
    <div class="sign-up-form">
      <h1 class="title">Créer un compte</h1>
      <form @submit.prevent="submitForm">
        <div class="row-field">
          <div class="field" style="flex: 1 0 0">
            <label>Prénom *</label>
            <div class="control">
              <input
                type="text"
                id="id_first_name"
                class="input"
                :class="{ error: v$.first_name.$error }"
                v-model="v$.first_name.$model"
                @blur="v$.first_name.$touch"
                placeholder="ex : Frank"
              />
              <p v-if="v$.first_name.$error" class="error-message">
                Veuillez saisir un prénom.
              </p>
            </div>
          </div>
          <div class="field" style="flex: 1 0 0">
            <label>Nom *</label>
            <div class="control">
              <input
                type="text"
                id="id_last_name"
                class="input"
                :class="{ error: v$.last_name.$error }"
                v-model="v$.last_name.$model"
                @blur="v$.last_name.$touch"
                placeholder="ex : Dupond"
              />
              <p v-if="v$.last_name.$error" class="error-message">
                Veuillez saisir un nom.
              </p>
            </div>
          </div>
        </div>
        <div class="field">
          <label>Adresse e-mail *</label>
          <div class="control">
            <input
              type="text"
              class="input"
              :class="{ error: v$.email.$error }"
              v-model="v$.email.$model"
              @blur="v$.email.$touch"
              placeholder="ex : frank.dupond@gmail.com"
              autocomplete="username"
            />
            <p v-if="v$.email.$error" class="error-message">
              Veuillez saisir une adresse e-mail valide.
            </p>
          </div>
        </div>
        <label>Mot de passe *</label>
        <div class="field">
          <div class="control">
            <input
              type="password"
              class="input"
              :class="{ error: v$.password.$error }"
              v-model="password"
              @blur="v$.password.$touch"
              autocomplete="new-password"
            />
            <div class="pwd-requirements" style="display: flex; justify-content: space-between; flex-wrap: wrap;">
              <div style="flex: 1 0 0;margin-right: 1rem;">
                <p class="critere" :class="{ valide: pwdMaj }">1 majuscule</p>
                <p class="critere" :class="{ valide: pwdMin }">1 minuscule</p>
              </div>
              <div style="flex: 1 0 0;margin-right: 1rem;">
                <p class="critere" :class="{ valide: pwdNumber }">1 chiffre</p>
                <p class="critere" :class="{ valide: pwdLength > 8 }">
                  8 caractères
                </p>
              </div>
              <div style="flex: 1 0 0;margin-right: 1rem;">
                <p class="critere" :class="{ valide: pwdSpecial }">
                  1 caractère spécial
                </p>
              </div>
            </div>
            <p v-if="v$.password.$error" class="error-message">
              Le mot de passe ne respecte pas les exigences
            </p>
          </div>
        </div>
        <label>Confirmation Mot de passe *</label>
        <div class="field">
          <div class="control">
            <input
              type="password"
              class="input"
              :class="{ error: v$.password2.$error }"
              v-model="password2"
              @blur="v$.password2.$touch"
              autocomplete="new-password"
            />
            <p v-if="v$.password2.$error" class="error-message">
              Le mot de passe et ça confirmation ne sont pas identique.
            </p>
          </div>
        </div>
        <hr />
        <div id="captcha" class="field">
          <label>Captcha * </label>
          <div class="control">
            <div style="display: flex; align-items: center">
              <img id="captcha_img" src="" />
              <button @click="getCaptcha" type="button" class="button-light">
                <i class="fa-solid fa-arrow-rotate-right"></i> Rafraichir
              </button>
            </div>
            <input
              type="text"
              class="input"
              :class="{ error: v$.captcha_value.$error }"
              v-model="captcha_value"
              @blur="v$.captcha_value.$touch"
              ref="captchavalue"
              placeholder="Captcha"
            />
            <p v-if="v$.captcha_value.$error" class="error-message">
              Veuillez renseigner le captcha.
            </p>
          </div>
        </div>
        <hr />
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
        <div class="checkboxfield">
          <input
            type="checkbox"
            id="cgv"
            name="cgv"
            :class="{ error: v$.cguAccepted.$error }"
            v-model="v$.cguAccepted.$model"
          />
          <label for="cgv"
            >J’accepte les conditions générales de OUTSIDOG
            <router-link class="black-link" to="/mentions-legales/#utilisation"
              >conditions d’utilisation.</router-link
            >
            *</label
          >
        </div>
        <p v-if="v$.cguAccepted.$error" class="error-message">
          Veuillez accepter les conditions générales d'utilisation pour pouvoir
          créer un compte.
        </p>

        <div class="field">
          <div class="control" style="display: flex">
            <button class="button-dark" :class="{ 'isloading': singUpIsLoading }">
                <span v-if="singUpIsLoading" class="icon">
                  <i class="fa-solid fa-circle-notch fa-spin"></i>
                </span>
                <span v-else>
                  Créer un compte
                </span>
              </button>
          </div>
        </div>
        <p>
          Où <router-link to="/log-in">Cliquez ici</router-link> pour vous
          connecter.
        </p>
        <div class="hp-field">
          <label>Name</label>
          <input type="text" v-model="name" />
        </div>
      </form>
      <p style="margin-top: 1rem; margin-bottom: 1rem; text-align: center">
        En vous inscrivant, vous acceptez notre
        <router-link class="black-link" to="/mentions-legales/#politique"
          >politique de confidentialité.</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  minLength,
  sameAs,
} from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "SignUpView",
  data() {
    return {
      name: "",
      password: null,
      password2: null,
      email: null,
      first_name: null,
      last_name: null,
      errors: [],
      captcha_key: "",
      captcha_value: "",
      showPassword: false,
      showPasswordConfirmation: false,
      cguAccepted: null,
      singUpIsLoading:false,
    };
  },
  computed: {
    pwdLength() {
      if (this.password) {
        return this.password.length;
      }
      return 0;
    },
    pwdMaj() {
      if (this.password) {
        const containsUppercase = /[A-Z]/.test(this.password);
        return containsUppercase;
      }
      return false;
    },
    pwdMin() {
      if (this.password) {
        const containscase = /[a-z]/.test(this.password);
        return containscase;
      }
      return false;
    },
    pwdNumber() {
      if (this.password) {
        const containsNumber = /[0-9]/.test(this.password);
        return containsNumber;
      }
      return false;
    },
    pwdSpecial() {
      if (this.password) {
        const containsSpecial = /[!@#$%^&*-_+=;:'",.?~`]/.test(this.password);
        return containsSpecial;
      }
      return false;
    },
  },
  validations() {
    return {
      cguAccepted: { required, $lazy: true , checked: value => value === true}, // Matches this.contact.email
      first_name: { required, $lazy: true }, // Matches this.firstName
      last_name: { required, $lazy: true }, // Matches this.lastName
      captcha_value: { required, $lazy: true }, // Matches this.lastName
      email: { required, email, $lazy: true }, // Matches this.contact.email
      password: {
        required,
        $lazy: true,
        minLength: minLength(8), // I assume you'd want something like this too
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[!@#$%^&*-_+=;:'",.?~`]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
        containsUppercase: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          return containsUppercase;
        },
        containsNumber: function (value) {
          const containsNumber = /[0-9]/.test(value);
          return containsNumber;
        },
        containsSpecial: function (value) {
          const containsSpecial = /[!@#$%^&*-_+=;:'",.?~`]/.test(value);
          return containsSpecial;
        },
      }, // Matches this.contact.email
      password2: {
        required,
        $lazy: true,
        sameAsPassword: sameAs(this.password),
      }, // Matches this.contact.email
    };
  },
  mounted() {
    this.getCaptcha();
    this.getCaptcha();
  },
  methods: {
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      const error = document.getElementsByClassName("error-message");
      if (error.length > 0) {
        error[0].parentNode.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      if (isFormCorrect) {
        const formData = {
          resource: {
            email: this.email.toLowerCase(),
            password: this.password,
            re_password: this.password2,
            first_name: this.first_name,
            last_name: this.last_name,
          },
          captcha: {
            captcha_key: this.captcha_key,
            captcha_value: this.captcha_value,
          },
          hp: {
            hp: this.name,
          },
        };
        this.singUpIsLoading = true;
        await axios
          .post("/api/v1/users/", formData)
          .then((response) => {
            this.singUpIsLoading = false;

            toast({
            message: "Compte créé ! Un email vous à été envoyé. ",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
          this.login();
        })
        .catch((error) => {
          this.singUpIsLoading = false;
          this.errors = [];
          this.getCaptcha();
          this.captcha_value = "";
          this.v$.$reset();
          if (error.response && error.response.status === 500) {
            consol.log(",i,")
            this.errors.push(
              "Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard."
              );
            } else {
              for (const property in error.response.data) {
                this.errors.push(`${error.response.data[property]}`);
              }
            }
          });
          
        }
    },
    async getUserInfo() {
      await axios
      .get(`/api/v1/users/me/`)
        .then((response) => {
        })
        .catch((error) => {});
      return -1;
    },
    async login() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      const formData = {
        hp: this.name,
        email: this.email.toLowerCase(),
        password: this.password,
      };

      await axios
        .post("/api/v1/token/login/", formData)
        .then((response) => {
          const token = response.data.auth_token;
          this.$store.commit("setToken", token);
          axios.defaults.headers.common["Authorization"] = token
            ? `Token ${token}`
            : null;
          localStorage.setItem("token", token);
          const toPath = this.$route.query.to || "/my-account";
          this.getUserInfo();
          this.$router.push(toPath);
        })
        .catch((error) => {
          this.errors = [];
          if (error.response && error.response.status === 500) {
            this.errors.push(
              "Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard. "
              );
            } else {
            for (const property in error.response.data) {
              this.errors.push(`${error.response.data[property]}`);
            }
          }
        });
    },

    async getCaptcha() {
      await axios
        .post("/api/captcha/")
        .then((response) => {
          this.captcha_key = response.data["captcha_key"];
          var image = new Image();
          image.src = "data:image/png;base64," + response.data["captcha_image"];
          document.getElementById("captcha_img").src = image.src;
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            this.errors.push(
              "Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard."
              );
            } else if (error.message) {
            this.errors.push("Mauvais CAPTCHA" + response.status);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.critere {
  color: rgba(126, 126, 126, 0.5);
}
.valide {
  color: green;
  &::before {
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;
    font-style: normal;
    font-variant: normal;
    font: var(--fa-font-solid);
    font-weight: 900;
    font-size: 18px;
    content: "\f00c";
  }
}
input {
  accent-color: rgb(45, 45, 83);
  margin-top: 0.4rem;
}
.error {
  border: 1px solid rgb(255, 0, 0);
}
.error-message {
  color: rgb(199, 51, 51);
  background-color: rgba(253, 117, 117, 0.5);
  padding: 0.5rem;
  margin-top: 0.5rem;
  text-align: center;
}
.page-sign-up {
  display: flex;
  justify-content: center;
  max-width: 550px;
}
.sign-up-form {
  width: 80vw;
}
.black-link {
  color: black;
  text-decoration: underline;
}
.checkboxfield {
  display: flex;
  align-items: start;
  margin-top: 1rem;
  margin-bottom: 1rem;
  input {
    margin-right: 1rem;
  }
}
.pwd-requirements{
  @media(max-width:430px){
    flex-direction: column;
  }
}
</style>
