<template>
  <div
    class="page-my-account"
    style="display: flex; justify-content: center"
  >
    <div class="account">
      <div class="summarycomponent">
        <AccountNav />
      </div>
      <div class="detailed-section">
        <h1 class="title">Modification email et mot de passe</h1>
        <hr />
        <div class="form-section">
          <div style="flex: 1 0 0; margin-top: 2rem">
            <h1 class="title">Modification email</h1>
            <form @submit.prevent="resetEmail">
              <div class="field">
                <label>Nouveau Email</label>
                <div class="control">
                  <input type="email" class="input" v-model="email" autocomplete="email" />
                </div>
              </div>
              <div class="field">
                <label>Mot de passe</label>
                <div class="control">
                  <input
                    type="password"
                    class="input"
                    v-model="password"
                    autocomplete="new-password"
                  />
                </div>
              </div>
              <div class="notification is-danger" v-if="errors_email.length">
                <p v-for="error in errors_email" v-bind:key="error">
                  {{ error }}
                </p>
              </div>
              <div class="field">
                <div class="control">
                  <button class="button-dark"  :class="{ 'isloading': updateEmailIsLoading }">
                    <span v-if="updateEmailIsLoading">
                      <i class="fa-solid fa-circle-notch fa-spin"></i>
                    </span>
                    <span v-else>
                      Changer l'email
                    </span>
                  </button>
                </div>
              </div>
              <div class="hp-field">
                <label>Name</label>
                <input type="text" v-model="name" />
              </div>
            </form>
          </div>
          <div style="flex: 1 0 0; margin-top: 2rem">
            <h1 class="title">Modification du mot de passe</h1>
            <form>
              <label>Nouveau mot de passe *</label>
              <div class="field has-addons">
                <div class="control" style="flex: 1 0 0">
                  <input
                    v-if="showNewPassword"
                    type="text"
                    class="input"
                    v-model="new_password"
                    autocomplete="new-password"
                    :class="{ error: v$.new_password.$error }"
                    @blur="v$.new_password.$touch"
                  />
                  <input
                    v-else
                    type="password"
                    class="input"
                    v-model="new_password"
                    autocomplete="new-password"
                    :class="{ error: v$.new_password.$error }"
                    @blur="v$.new_password.$touch"
                  />
                </div>
                <div class="control">
                  <button class="button" @click.prevent="toggleShowNewPassword">
                    <span class="icon is-small is-right">
                      <i
                        class="fas"
                        :class="{
                          'fa-eye-slash': showNewPassword,
                          'fa-eye': !showNewPassword,
                        }"
                      ></i>
                    </span>
                  </button>
                </div>
              </div>
              <p v-if="v$.new_password.$error" class="error-message">
                Veuillez saisir un mot de passe de plus de 8 caractères,
                contenant au moins une majuscule, une minuscule, un chiffre et
                un caractère spéciale parmis les suivants !@#$%^&*-_+=;:'",.?~`
              </p>
              <label>Confirmer le nouveau mot de passe *</label>
              <div class="field has-addons">
                <div class="control" style="flex: 1 0 0">
                  <input
                    v-if="showNewPasswordConfirmation"
                    type="text"
                    class="input"
                    v-model="re_new_password"
                    autocomplete="new-password"
                    :class="{ error: v$.re_new_password.$error }"
                    @blur="v$.re_new_password.$touch"
                  />
                  <input
                    v-else
                    type="password"
                    class="input"
                    v-model="re_new_password"
                    autocomplete="new-password"
                    :class="{ error: v$.re_new_password.$error }"
                    @blur="v$.re_new_password.$touch"
                  />
                </div>
                <div class="control">
                  <button
                    class="button"
                    @click.prevent="toggleShowNewPasswordConfirmation"
                  >
                    <span class="icon is-small is-right">
                      <i
                        class="fas"
                        :class="{
                          'fa-eye-slash': showNewPasswordConfirmation,
                          'fa-eye': !showNewPasswordConfirmation,
                        }"
                      ></i>
                    </span>
                  </button>
                </div>
              </div>
              <p v-if="v$.re_new_password.$error" class="error-message">
                Le mot de passe et ça confirmation ne sont pas identique.
              </p>

              <label>Mot de passe actuel</label>
              <div class="field has-addons">
                <div class="control" style="flex: 1 0 0">
                  <input
                    v-if="showCurrentPassword"
                    type="text"
                    class="input"
                    v-model="password_change_password"
                    autocomplete="new-password"
                  />
                  <input
                    v-else
                    type="password"
                    class="input"
                    v-model="password_change_password"
                    autocomplete="new-password"
                  />
                </div>
                <div class="control">
                  <button
                    class="button"
                    @click.prevent="toggleShowCurrentPassword"
                  >
                    <span class="icon is-small is-right">
                      <i
                        class="fas"
                        :class="{
                          'fa-eye-slash': showCurrentPassword,
                          'fa-eye': !showCurrentPassword,
                        }"
                      ></i>
                    </span>
                  </button>
                </div>
              </div>
              <div class="notification is-danger" v-if="errors_pwd.length">
                <p v-for="error in errors_pwd" v-bind:key="error">
                  {{ error }}
                </p>
              </div>
              <div class="field">
                <div class="control">
                  <button class="button-dark"  :class="{ 'isloading': updatePwdIsLoading }">
                    <span v-if="updatePwdIsLoading">
                      <i class="fa-solid fa-circle-notch fa-spin"></i>
                    </span>
                    <span v-else>
                      Changer le mot de passe
                    </span>
                  </button>
                </div>
              </div>
              <div class="hp-field">
                <label>Name</label>
                <input type="text" v-model="name2" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import OrderSummary from "@/components/OrderSummary.vue";
import AddressForm from "@/components/AddressForm.vue";
import AccountNav from "@/components/AccountNav.vue";
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  sameAs,
} from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "PwdChangeView",
  components: {
    OrderSummary,
    AddressForm,
    AccountNav,
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  validations() {
    return {
      new_password: {
        required,
        $lazy: true,
        minLength: minLength(8),
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[!@#$%^&*-_+=;:'",.?~`]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
      }, // Matches this.contact.email
      re_new_password: {
        required,
        $lazy: true,
        sameAsPassword: sameAs(this.new_password),
      }, // Matches this.contact.email
      name: { required },
    };
  },
  data() {
    return {
      name: "", // for honeypot
      name2: "", // for honeypot
      re_new_password: "",
      new_password: "",
      password_change_password: "",
      password: "",
      email: "",
      errors_pwd: [],
      errors_email: [],
      orders: [],
      showPasswordResetMail: false,
      showNewPassword: false,
      showNewPasswordConfirmation: false,
      showCurrentPassword: false,
      updatePwdIsLoading:false,
      updateEmailIsLoading:false,
    };
  },
  mounted() {
    this.getMyOrders();
  },
  methods: {
    toggleShowPasswordResetMail() {
      this.showPasswordResetMail = !this.showPasswordResetMail;
    },
    toggleShowNewPassword() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleShowNewPasswordConfirmation() {
      this.showNewPasswordConfirmation = !this.showNewPasswordConfirmation;
    },
    toggleShowCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword;
    },

    async getMyOrders() {
      await axios
        .get("/api/v1/orders/")
        .then((response) => {
          this.orders = response.data;
        })
        .catch((error) => {
        });
    },
    async resetEmail() {
      this.errors_email = [];
      if (this.email === "") {
        this.errors_email.push("The new email is missing");
      }
      if (this.password === "") {
        this.errors_email.push("The password is missing");
      }
      if (!this.errors_email.length) {

        const formData = {
          new_email: this.email.toLowerCase(),
          current_password: this.password,
          name: this.name,
        };
        this.updateEmailIsLoading = true
        await axios
          .post("/api/v1/users/set_email/", formData)
          .then((response) => {
            this.updateEmailIsLoading = false;

            toast({
              message: "Votre mail a été modifié avec succès !",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            this.updateEmailIsLoading = false;
            if (error.response && error.response.status === 500) {
              this.errors_email.push("Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard.");
            } else {
              for (const property in error.response.data) {
                this.errors_email.push(`${error.response.data[property]}`);
              }
            }
          });
        }
    },
    async submitForm() {
      this.errors_pwd = [];
      if (this.new_password === "") {
        this.errors_pwd.push("The new password is missing");
      }
      if (this.re_new_password === "") {
        this.errors_pwd.push("The new password confirmation is missing");
      }
      if (this.password === "") {
        this.errors_pwd.push("The current password is missing");
      }
      if (this.new_password !== this.re_new_password) {
        this.errors_pwd.push(
          "The new password and his confirmation doesn't match"
        );
      }
      if (!this.errors_pwd.length) {
        const formData = {
          new_password: this.new_password,
          re_new_password: this.re_new_password,
          current_password: this.password_change_password,
          name: this.name2,
        };
        this.updatePwdIsLoading= true;
        await axios
          .post("/api/v1/users/set_password/", formData)
          .then((response) => {
            this.updatePwdIsLoading = false;

            toast({
              message:
                "Mot de passe changé ! Vous avez été déconnecté automatiquement. S'il vous plaît connectez vous de nouveau. ",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 8000,
              position: "bottom-right",
            });
            this.$router.push("/log-in");
          })
          .catch((error) => {
            this.updatePwdIsLoading = false;

            if (error.response && error.response.status === 500) {
              this.errors_pwd.push("Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard.");
            } else {
              for (const property in error.response.data) {
                this.errors_pwd.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          });      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-my-account {
  width: 100%;
  padding: clamp(1rem, 2vw, 4rem);
  padding-top: clamp(2rem, 2vw, 4rem);
}
.error {
  border: 1px solid red;
}
.error-message {
  color: red;
}
.form-section {
  gap: 3rem;
  display: flex;
  flex-direction: row;
  @media (max-width: 780px) {
    flex-direction: column;
  }
}
.account {
  width: 100%;
  display: flex;
  gap: 4rem;
  justify-content: center;
  max-width: 1800px;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
  .summarycomponent {
    flex: 2 0 0;
  }
  .detailed-section {
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: 4 0 0;
  }
}
</style>
