<template>
  <div class="page-forget-pwd" style="margin: 0 auto;padding-top: clamp(1.2rem, 4vw, 4rem);">
    <div style="padding: 1rem">
      <h1 class="title">Vous avez oublié votre mot de passe?</h1>
      <form @submit.prevent="sendEmail" v-show="!submit" class="submitForm">
        <p>
          Veuillez saisir votre adresse e-mail pour recevoir un e-mail de
          réinitialisation de votre mot de passe.
        </p>
        <div class="field" style="margin-top: 1rem">
          <label>Adresse e-mail *</label>
          <div class="control">
            <input type="email" class="input" v-model="email" />
          </div>
          <div class="hp-field">
            <label>Name</label>
            <input type="text" v-model="name" />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <div style="display: flex;width: 100%;">
              <button class="button-dark" :class="{ 'isloading': resetpwdIsLoading }" style="flex:1 0 0;">
                  <span v-if="resetpwdIsLoading" class="icon">
                    <i class="fa-solid fa-circle-notch fa-spin"></i>
                  </span>
                  <span v-else>
                    ENVOYER
                  </span>
                </button>
            </div>
          </div>
        </div>
        <hr />
        <div class="notification is-danger" v-if="errors.length">
          <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
        </div>
      </form>
      Déjà client ? <router-link to="/log-in">Cliquez ici </router-link> pour
      vous connecter.
      <h2 v-show="submit">
        Un e-mail pour réinitialiser votre mot de passe sera envoyé si un compte
        est lié à cette adresse e-mail: {{ this.email.toLowerCase() }}
      </h2>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ForgotPwdView",
  data() {
    return {
      errors: [],
      submit: false,
      email: "",
      name: "",
      resetpwdIsLoading:false,
    };
  },
  mounted() {
    document.title = "Log in | Outsidog";
  },
  methods: {
    async sendEmail() {
      this.errors = [];
      const formData = {
        email: this.email.toLowerCase(),
        hp: this.name,
      };
      if (this.email === "") {
        this.errors.push("Veuillez rentrer un email");
      }
      if (!this.errors.length) {
        this.resetpwdIsLoading = true;
        await axios
        .post("/api/v1/users/reset_password/", formData)
        .then((response) => {
          this.resetpwdIsLoading = false;
          this.submit = true;
          toast({
            message: `S'il y a un compte associé à l’adresse ${this.email.toLowerCase()}, vous recevrez un email avec un lien pour réinitialiser votre mot de passe.`,
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 8000,
            position: "bottom-right",
          });
          this.$router.push("/log-in");
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            this.errors.push("Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard.");
          } else if (error.message) {
                for (const property in error.response.data) {
                    this.errors.push(
                      `${property}: ${error.response.data[property]}`
                      );
                    }
              }
            });
            this.resetpwdIsLoading = false;
      }
    },
  },
};
</script>

<style>
.page-forget-pwd {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 550px;
}
</style>
