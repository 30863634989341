<template>
  <div class="test">
    <div class="summary">
      <div class="test">
        <ul>
          <li>
            <router-link active-class="active" to="/my-account"
              ><i
                class="fa-solid fa-user"
                style="padding-left: 1rem; padding-right: 1rem"
              ></i
              >Mes informations et adresses<i
                style="float: right"
                class="toggle fa-solid fa-angle-right"
              ></i
            ></router-link>
          </li>
          <li>
            <router-link
              active-class="active"
              to="/my-orders"
              ><i
                class="fa-solid fa-bag-shopping"
                style="padding-left: 1rem; padding-right: 1rem"
              ></i
              >Mes commandes
              <i style="float: right" class="toggle fa-solid fa-angle-right"></i
            ></router-link>
          </li>
          <li>
            <router-link
              active-class="active"
              class=""
              to="/my-return-and-refund"
              ><i
                class="fa-solid fa-arrows-rotate"
                style="padding-left: 1rem; padding-right: 1rem"
              ></i
              >Mes retours et remboursement
              <i style="float: right" class="toggle fa-solid fa-angle-right"></i
            ></router-link>
          </li>
          <li>
            <router-link active-class="active" class="" to="/change-pwd"
              ><i
                class="fa-solid fa-lock"
                style="padding-left: 1rem; padding-right: 1rem"
              ></i
              >Changer mon mot de passe ou email
              <i style="float: right" class="toggle fa-solid fa-angle-right"></i
            ></router-link>
          </li>
          <li>
            <a @click="logout()"
              ><i
                class="fa-solid fa-power-off"
                style="padding-left: 1rem; padding-right: 1rem"
              ></i
              >Déconnexion</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AccountNav",
  data() {
    return {};
  }, 
  methods: {
    logout() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userid");
      this.$store.commit("removeToken");
      this.$store.commit("removeFavorite");
      this.$router.push("/");
    },
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.summary {
  border: 1px solid grey;
  overflow: hidden;
  border-radius: 15px;
  display: flex;
  flex: 2 0 0;
  gap: 1rem;
  flex-direction: column;
  ul {
    display: flex;
    flex-direction: column;
  }
  a {
    border-bottom: 2px solid rgba(49, 49, 49, 0.6);
    font-size: 1rem;
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: inline-block;
    text-decoration: none;
    color: black;
    &:hover {
      font-weight: bold;
    }
  }
}
.logout-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
}
.active {
  background-color: rgb(235, 231, 231);
}
.test {
  height: 100%;
  li:last-child {
    a {
      border-bottom: 1px solid transparent;
    }
  }
}
.toggle {
  padding: 0.5rem;
  display: none;
}
</style>
