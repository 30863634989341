<template>
  <div class="home" style=" width: 100%;max-width: 100%;overflow-x: hidden;">
    <section style="height: 100vh" class="test">

      <div class="bg blurred-img" id="special">
            <img src="@/assets/images/dog-couturier.png" loading="lazy" id="bg-img"/>
        <div
          style="
            width: 100%;
            height: 100%;
            background: linear-gradient(
              0deg,
              rgb(0, 0, 0, 0.5),
              rgb(0, 0, 0, 0)
            );
            position: relative;
            z-index: 5;
          "
        ></div>
      </div>
      <div class="intro">
        <h1 class="title">Sortez-le pour sa prochaine aventure.</h1>
        <div id="legend">
          <p style="font-size: 1.5rem; margin-top: 50px; margin-bottom: 20px">
            Des produits solides, pratiques et qui donnerons du style à votre
            petit chien
          </p>
          <div style="position: relative; display: flex; font-size: 15px">
            <router-link
              class="test-arrow"
              to="/collection/all"
              style="color: white; text-decoration: underline"
              >Tout pour se promener</router-link
            >
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="content" style="background-color: white">
      <div class="adventure" style="display: flex; flex-wrap: wrap">
        <div v-if="!mobile">
          <h1 id="type" class="tapping">Quelle est votre prochaine sortie ?</h1>
        </div>
        <div v-else>
          <h1
            id="type"
            style="font-size: clamp(40px, 5vw, 2rem); display: inline-block"
          >
            Quelle est votre prochaine sortie ?
          </h1>
        </div>
        <div class="list-adventure">
          <div v-if="!mobile" style="padding: 2rem; color: grey">
            <i id="arrow" class="fa-solid fa-play fa-2xl"></i>
          </div>
          <div v-else>
            <i id="arrow"></i>
          </div>
          <div v-for="aventure in aventures" v-bind:key="aventure">
            <router-link :to="'/collection/' + aventure.url">
              <div
                class="aventure-card"
                :style="{
                  'background-image': `url(${aventure.src}), linear-gradient(black,black)`,
                }"
              >
                <div class="blurred-img card-bg" :style="{ backgroundImage: `url(${aventure.small})` }">
                  <img :src="aventure.src" alt="" >
                </div>
                <p>{{ aventure.title }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="latestProducts.length > 0"
      class="flex-section full-bleed inverse"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: clamp(1.2rem, 10vh, 100px);
      "
    >
      <h1 style="font-size: clamp(40px, 5vw, 50px)">Nos produits</h1>
      <h2 style="font-size: clamp(10px, 4vw, 20px); color: grey">
        Des nouveautés pour vos animaux
      </h2>
      <div class="latest">
        <CarouselMulitple
          v-if="mobile"
          v-bind:images="latestProducts"
          :childElement="latestProducts.length"
          :childPerSlide="1"
          :faIconNext="faIconNext"
          :faIconPrevious="faIconPrevious"
        >
          <template v-slot:image="{ image }">
            <SimpleProduitBox v-bind:key="image.id" v-bind:produit="image" />
          </template>
          <template #prev-icon>
            <i
              class="fa-regular fa-circle-left"
              style="margin-right: 5rem; position: relative; font-size: 2rem"
            ></i>
          </template>
          <template #next-icon>
            <i
              class="fa-regular fa-circle-right"
              style="margin-left: 5rem; position: relative; font-size: 2rem"
            ></i>
          </template>
        </CarouselMulitple>
        <CarouselMulitple
          v-else-if="tablette"
          v-bind:images="latestProducts"
          :childElement="latestProducts.length"
          :childPerSlide="2"
          :faIconNext="faIconNext"
          :faIconPrevious="faIconPrevious"
        >
          <template v-slot:image="{ image }">
            <SimpleProduitBox v-bind:key="image.id" v-bind:produit="image" />
          </template>
          <template #prev-icon>
            <i
              class="fa-regular fa-circle-left"
              style="
                margin-right: 11rem;
                position: relative;
                font-size: clamp(1.2rem, 4vw, 3rem);
              "
            ></i>
          </template>
          <template #next-icon>
            <i
              class="fa-regular fa-circle-right"
              style="
                margin-left: 11rem;
                position: relative;
                font-size: clamp(1.2rem, 4vw, 3rem);
              "
            ></i>
          </template>
        </CarouselMulitple>
        <CarouselMulitple
        v-else
          v-bind:images="latestProducts"
          :childElement="latestProducts.length"
          :childPerSlide="3"
          :faIconNext="faIconNext"
          :faIconPrevious="faIconPrevious"
        >
          <template v-slot:image="{ image }">
            <SimpleProduitBox v-bind:key="image.id" v-bind:produit="image" />
          </template>
          <template #prev-icon>
            <i
              class="fa-regular fa-circle-left"
              style="
                margin-right: 11rem;
                position: relative;
                font-size: clamp(1.2rem, 4vw, 3rem);
              "
            ></i>
          </template>
          <template #next-icon>
            <i
              class="fa-regular fa-circle-right"
              style="
                margin-left: 11rem;
                position: relative;
                font-size: clamp(1.2rem, 4vw, 3rem);
              "
            ></i>
          </template>
        </CarouselMulitple>
      </div>
      <router-link
        to="/collection/all"
        class="button-dark"
        style="margin-left: 1rem"
      >
        <span>Voir tous les produits</span>
      </router-link>
    </section>

    <section
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
      "
    >
      <div class="concept">
        <div style="flex: 1 0 0; margin-bottom: clamp(80px, 10vh, 250px)">
          <div class="blurred-img img1" :style="{ backgroundImage: `url(${require('@/assets/images/prairies-small.png')})` }">
            <img src="@/assets/images/prairies.png" loading="lazy" />
          </div>
          <div class="overlay">
            <div class="blurred-img img2" :style="{ backgroundImage: `url(${require('@/assets/images/zoom-studio-small.png')})` }">
              <img
                src="@/assets/images/zoom-studio.png"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div style="flex: 1 0 0; font-size: clamp(1rem, 1.5vw, 2rem)">
          <h1 style="font-size: clamp(40px, 5vw, 50px); margin-bottom: 2rem">
            Concept
          </h1>
          <p>
            Outsidog vise à offrir aux chiens le confort nécessaire pour leurs
            activités extérieures, en les habillant avec des équipements de
            qualité.
          </p>
          <p>
            En protégeant nos compagnons canins des éléments tels que le soleil,
            la pluie et le froid, nous préservons leur santé, améliorons leur
            visibilité tout en ajoutant une touche de style à leurs tenues.
          </p>
          <div style="margin-top: 2rem">
            <router-link
              to="/about-us"
              style="color: black; text-decoration: underline; float: right"
              >About-us</router-link
            >
          </div>
        </div>
      </div>
    </section>

    <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 2rem;flex-direction: column;">
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 1rem;
          width: 100%;
          margin-top: 3rem;
          margin-bottom: 3rem;
          flex-wrap: wrap;
        "
      >
        <h2
          class=""
          style="margin-right: 1rem; font-size: clamp(1.2rem, 4vw, 2.5rem)"
        >
          Follow us on instagram -
        </h2>
        <a href="https://www.instagram.com/outsidogparis/" class="insta-link">
          #OUTSIDOG
        </a>
      </div>
      <div style="display: flex; gap: 2rem;justify-content: center;
        align-items: center;max-width: 1750px;margin-top: 3rem;
          margin-bottom: 3rem;">
        <div class="instagram-wrapper">
          <div class="instagram-gallery">
            <div
              v-for="post in posts"
              v-bind:key="post"
              class="instagram-gallery-item"
            >
              <a v-bind:href="post.permalink">
                <img
                  v-bind:src="post.media_url"
                  alt=""
                  class="instagram-gallery-image"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SimpleProduitBox from "@/components/SimpleProduitBox";
import ProduitBox from "@/components/ProduitBox";

export default {
  name: "HomeView",
  data() {
    return {
      latestProducts: [],
      faIconNext: "'\\f0a9'",
      faIconPrevious: "'\\f35a'",
      posts: [],
      warningAddress: false,
      token: null,
      mobile: null,
      aventures: [
        {
          title: "Urbaine",
          src: require("@/assets/images/city.jpg"),
          small: require("@/assets/images/city-small.jpg"),
          url: "urbain",
        },
        {
          title: "Hivernale",
          src: require("@/assets/images/hiver.jpg"),
          small: require("@/assets/images/hiver-small.jpg"),
          url: "hiver",
        },
        {
          title: "Pluvieuse",
          src: require("@/assets/images/rain.jpg"),
          small: require("@/assets/images/rain-small.jpg"),
          url: "pluie",
        },
      ],
    };
  },
  components: {
    SimpleProduitBox,
    ProduitBox,
  },
  mounted() {
    document.title = "Outsidog";
    const blurredImageDivs = document.querySelectorAll(".blurred-img");

    function loaded() {
      this.parentNode.classList.add("loaded");
    }

    blurredImageDivs.forEach((blurredImage) => {
      const img = blurredImage.querySelector("img");
      if (img.complete) {
        loaded.call(img);
      } else {
        img.addEventListener("load", loaded);
      }
    });


    const bgImg = document.querySelector("#bg-img");
    const sepcial = document.querySelector("#special");
    function handleBgLoad() {
      sepcial.classList.add("bg-loaded");
    }

    if (bgImg.complete) {
      handleBgLoad();
    } else {
      bgImg.addEventListener("load", handleBgLoad);
    }

    if (window.ScrollTrigger) {
      ScrollTrigger.create({
        trigger: ".tapping",
        toggleActions: "play play play none",
        start: "top bottom",
        onEnter: () => this.run(),
      });
    }

    const titles = gsap.utils.toArray(".image-container");

    titles.forEach((text, i) => {
      gsap.to(text, {
        scrollTrigger: {
          trigger: text,
          toggleActions: "play none none none",
          start: "center bottom",
        },
        y: 0,
        duration: 0.8,
        opacity: 1,
      });
    });
    const tl = gsap.timeline();
    tl.fromTo(
      ".title",
      { opacity: 0, x: -100 },
      {
        duration: 0.8,
        opacity: 1,
        x: 0,
      },
      0.8
    );
    tl.fromTo(
      "#legend",
      { opacity: 0 },
      { opacity: 1, duration: 0.6, y: 0 },
      1.2
    );
    tl.fromTo(
      "#header",
      { opacity: 0, y: -50 },
      { opacity: 1, duration: 0.6, y: 0 },
      1.5
    );
    if (!this.mobile) {
      gsap.utils.toArray('.parallax-container').forEach((container) => {
        const image = container.querySelector('.parallax-image img');

        gsap.to(image, {
          yPercent: +100,
          ease: 'none',
          scrollTrigger: {
            trigger: container,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true
          }
        });
      });


      gsap.utils.toArray("section.test").forEach((section, i) => {
        section.bg = section.querySelector(".bg");

        section.bg.style.backgroundPosition = "50% 0px";

        gsap.to(section.bg, {
          backgroundPosition: `50% ${innerHeight / 2}px`,
          ease: "none",
          scrollTrigger: {
            trigger: section,
            start: "top top",
            end: "bottom top",
            scrub: true,
            invalidateOnRefresh: true, // to make it responsive
          },
        });
      });
    }
    this.getInstaPost();
    this.getLatestProducts();
  },
  computed: {
    typeElementStyle() {
    const element = document.getElementById("type");
    if (element) {
      return element.style;
    } else {
      return null; // ou une valeur par défaut, selon vos besoins
    }
  },  arrowElementStyle() {
    const element = document.getElementById("arrow");
    if (element) {
      return element.style;
    } else {
      return null; // ou une valeur par défaut, selon vos besoins
    }
  },
  },
  setup() {
    const isMobile = () => screen.width <= 760;
    return { isMobile ,};
  },
  beforeMount() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreen);
  },
  methods: {
    run() {
    const typeElementStyle = this.typeElementStyle;
    const arrowElementStyle = this.arrowElementStyle;

    if (typeElementStyle) {
      if (!this.mobile) {
        typeElementStyle.setProperty("--runing-state", "running");
      }
    }

    if (arrowElementStyle) {
      arrowElementStyle.setProperty("--runing-state", "running");
    }
  },
    checkScreen() {
      if (window.innerWidth <= 780) {
        this.mobile = true;
        return;
      }
      if (this.windowsWidth <= 1000 && this.windowsWidth >= 780) {
        this.tablette = true;
      }
      else{
        this.tablette = false;
      }
      this.mobile = false;
      return;
    },

    async getLatestProducts() {
      await axios
        .get("/api/v1/latest-products/")
        .then((response) => {
          this.latestProducts = response.data;
        })
        .catch((error) => {
        });
    },
    async getInstaPost() {
      await axios
        .get(`/api/v1/insta-post/`)
        .then((response) => {
          this.posts = response.data;
        })
        .catch((error) => {
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-product {
  flex: 1 1 0;
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  z-index: 0;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("@/assets/images/dog-couturier-small.png");
box-shadow: inset 0px -500px 401px -190px rgba(0,0,0,0.43);
-webkit-backface-visibility: hidden;
  backface-visibility: hidden
}
.bg2 {
  position: absolute;
  z-index: -5;
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    object-position: center;
  }
}
.shop {
  margin: 15px;
  text-decoration: none;
  list-style: none;
  color: white;
  height: 50px;
  width: 400px;
  text-align: center;
  font-style: bold;
  border-bottom: 1px solid white;
  font-size: 2rem;
  opacity: 0;
}
.title {
  font-size: clamp(40px, 5vw, 150px);
  color: white;
  text-shadow: 0 0 5px black;
  opacity: 0;
}
.test {
  position: static;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 50%;
  overflow: hidden;
  z-index: 1;
  .test-arrow {
    font-size:clamp(1.2rem, 4vw, 2.5rem);

    @media (min-width: 1250px) {
      text-decoration: none;
      transition: all ease-in-out 200ms;
      transform: translateX(-0.4em);
      &:before {
        -webkit-font-smoothing: antialiased;
        text-rendering: auto;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        font: var(--fa-font-solid);
        content: "\f061";
        position: relative;
        left: -0.2em;
        transition: all ease-in-out 0.4s;
        opacity: 0;
      }
      &:hover {
        &:before {
          opacity: 1;
          left: -0.1em;
        }
        transform: translateX(0);
      }
    }
  }
}


.section {
  font-size: 2rem;
  padding: 2rem;
}

.intro {
  margin-bottom: clamp(20px, 4vw, 200px);
  padding-bottom: 10rem;
  margin-left: clamp(20px, 4vw, 200px);
  color: white;
  width: 60vw;
  @media (max-width: 750px) {
    width: 80vw;
  }
}
.button-rounded-light {
  border-radius: 100vmax;
  border: 1px solid #4a4a4a;
  background: transparent;
  padding-left: 2rem;
  padding-right: 2rem;
  font-style: bold;
  color: #4a4a4a;
  cursor: pointer;
  &:hover {
    color: white;
    background: #4a4a4a;
  }
}

.instagram-wrapper {
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 2rem;
}
.instagram-gallery {
  display: grid;
  margin: -1rem, -1rem;
  padding-bottom: 3rem;
  grid-template-columns: repeat(3, 1fr);
  @media (min-width: 500px) and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.instagram-gallery-item {
  position: relative;
  flex: 1 0 22rem;
  margin: 1rem;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
}
.instagram-gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 1s;
  &:hover {
    scale: 115%;
  }
}
.display-modal-enter-active,
.display-modal-leave-active {
  transition: 0.1s ease all;
}

.display-modal-enter-from,
.display-modal-leave-to {
  transform: opacity(0%);
}

.display-modal-enter-to,
.display-modal-leave-from {
  transform: opacity(100%);
}

.tapping {
  margin-left: clamp(20px, 4vw, 200px);
  position: relative;
  width: max-content;
  font-family: monospace;
  font-size: clamp(1rem, 4vw, 4rem);
  --typewriterSpeed: 2.5s;
  --runing-state: paused;
  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  &:before {
    background-color: white;
    animation: typewriter var(--typewriterSpeed) steps(35) forwards;
    animation-play-state: var(--runing-state);
  }
  @keyframes typewriter {
    to {
      left: 100%;
    }
  }
  @keyframes blink {
    to {
      background: transparent;
    }
  }
  &:after {
    width: 0.125em;
    background-color: black;
    animation: typewriter var(--typewriterSpeed) steps(35) forwards,
      blink 1s steps(24) infinite;
    animation-play-state: var(--runing-state);
  }
}
.aventure-card {
  position: relative;
  z-index: 1;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  color: white;
  font-weight: bold;
  width: 25vw;
  max-width: 600px;
  min-width: 250px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  background-size: 100%;
  // background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: 1s;
  cursor: pointer;
  .card-bg{
    position: absolute;
    z-index: -1;
    img{
      transition: 1s ease all;
      scale: 100%;
    }
  }
  &:hover {
    .card-bg{
      img{
        scale: 115%;
      }
    }
  }
}
.adventure {
  flex: 1 0 0;
  @media (max-width: 950px) {
    flex-direction: column;
  }
}
.list-adventure {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  gap: 2rem;
  justify-content: right;
  align-items: center;
  @media (max-width: 1750px) {
    justify-content: center;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
}
.full-bleed {
  box-shadow: 0 0 0 100vmax var(--bg-color);
  clip-path: inset(0 -100vmax);
}
.inverse {
  --bg-color: hsl(300, 3%, 93%);
  background-color: var(--bg-color, #121212);
  color: black;
  margin: 4rem 0rem;
  padding: 4rem 0rem;
}
#arrow {
  transform: scale(1);
  animation: beat 1s infinite;
  --runing-state: paused;
  color: black;
  @keyframes beat {
    to {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
}
.latest {
  margin: 4rem 0rem;
  width: 80%;
  max-width: 1950px;
}
.concept {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 4rem 0rem;
  width: 80%;
  max-width: 1950px;
  gap: 4rem;
  flex-wrap: wrap;
  .img1 {
    aspect-ratio: 3 / 4;
    width: 60%;
    max-width: 500px;
    min-width: 150px;
    border-radius: 20px;
    object-fit: cover;
    overflow: hidden;
  }
  .img2 {
    aspect-ratio: 1 / 1;
    width: 60%;
    max-width: 500px;
    min-width: 150px;
    border-radius: 20px;
    object-fit: cover;
    overflow: hidden;
  }
  @media (max-width: 1600px) {
    flex-direction: column-reverse;
  }
}
.overlay {
  position: absolute;
  top: 55%;
  left: 20%;
  padding: 1rem;
  color: #ffffff;
  @media (max-width: 1600px) {
    left: 45%;
    top: 70%;
  }
  @media (max-width: 1600px) {
    left: 45%;
    top: 70%;
  }
}
.blurred-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.blurred-img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  animation: pulse 2.5s infinite;
  background-color: white;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

.blurred-img.loaded::before {
  animation: none;
  content: none;
}

.blurred-img img {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.blurred-img.loaded img {
  opacity: 1;
}

.bg-loaded{
  background-image: url("@/assets/images/dog-couturier.png") !important;
}
#special{
  background-image: url("@/assets/images/dog-couturier-small.png");
}
#bg-img{
 visibility: hidden;
}
.insta-link{
  font-size: clamp(1.2rem, 4vw, 2.5rem); font-weight: bold; color:grey;
  &:hover{
    color:black;
  }
}
</style>
