<template>
      <Modal ref="newsletterpopup" :clickoutside="true" style="z-index: 7;">
        <div class="newsletterpopup" style="display: flex;background-color: white;max-width: 650px;">
          <div style="flex:1 0 0; margin: clamp(0.8rem, 4vw, 2rem);;">
            <h2 class="subtitle">
              <strong>Rejoignez notre communauté </strong>
            </h2>
            <p>
              -10% offerts* pour toute première commande, ne passez pas à côté !
            </p>
            <div style="margin-top: 1rem; margin-bottom: 1rem; flex: 1 0 0;"></div>
            <form action="">
              <div class="field" style="flex: 1 0 0">
              </div>
              <div class="field">
                <div class="control">
                  <input
                    type="email"
                    class="input"
                    :class="{ error: v$.email.$error }"
                    v-model="v$.email.$model"
                    @blur="v$.email.$touch"
                    placeholder="Votre e-mail"
                  />
                  <p v-if="v$.email.$error" class="error-message">
                    Veuillez renseigner un email.
                  </p>
                </div>
                <div class="hp-field">
                  <label>Name</label>
                  <input type="text" v-model="name" />
                </div>
              </div>
            </form>
            <button  @click="submitForm()" class="button-dark" :class="{ 'isloading': newsletterIsLoading }" style="width: 100%;">
              <span v-if="newsletterIsLoading" class="icon">
                <i class="fa-solid fa-circle-notch fa-spin"></i>
              </span>
              <span v-else class="icon">
                M'inscrire
              </span>
            </button>
            <hr />
            <div style="text-align: center;font-size: 0.6rem; letter-spacing: 0.05em;">
              <p>En vous inscrivant, vous acceptez de recevoir la newsletter de Outsidog. Conformément à la réglementation, vous disposez d'un droit d'accès, de rectification et d'effacement de vos données.</p>
              <br>
              <p>*Offre valable uniquement pour des commandes d'un montant de 40€ minimum. Offre non cumuble avec les promotions.</p>
            </div>
          </div>
          <div style="flex:1 0 0;position: relative;">
            <button @click="toogleAlertModal()" style="width:30px;height:30px;position: absolute;right:20px;top: 20px;border: none;background-color: none;cursor: pointer;background: none; color:black;">
              <i style="color:black !important;" class="fa-solid fa-x fa-l"></i>
            </button>
            <img src="@/assets/images/chien-nl-small.jpg" alt="" style="width: 100%;height: 100%;object-fit: cover;">
          </div>
          </div>
      </Modal>
  </template>
  
  <script>
  import axios from "axios";
  import { toast } from "bulma-toast";
  import router from "../router";
  import useVuelidate from "@vuelidate/core";
  import { required, email } from "@vuelidate/validators";

  export default {
    setup() {
      return { v$: useVuelidate() };
    },
    name: "NewsletterPopUp",
    components: {
    },
    validations() {
      return {
        email: { required, email, $lazy: true },
      };
    },
    computed: {
    },
    data() {
      return {
        email: null,
        name: "",
        newsletterIsLoading:false,

      };
    },
    updated() {
  
    },
    mounted() {
      this.activatePopupWithDelay();
      },
    created() {
    },
    computed: {
    },
    methods: {
      toogleAlertModal() {
        this.$refs.newsletterpopup.test();
      },
      async submitForm() {
        const isFormCorrect = await this.v$.$validate();
        if (isFormCorrect) {

          const formData = {
            resource: {
              email: this.email.toLowerCase(),
            },
            hp: {
              hp: this.name,
            },
          };
          this.newsletterIsLoading = true
          await axios
          .post("/api/v1/sub-news-letter/", formData)
          .then((response) => {
            this.$store.commit("setSubscribed");
              toast({
                message: "Merci pour votre abonnement !",
                type: "is-success",
                dismissible: true,
                pauseOnHover: true,
                duration: 4000,
                position: "bottom-right",
              });
            })
          .catch((error) => {
            let message = "";
            if (error.response && error.response.status === 500) {
              for (const property in error.response.data) {
                message += `${property}: ${error.response.data[property]}`;
              }
            } else {
              message += "Quelque chose s'est mal passé. Veuillez ressayer.";
              this.errors_sub.push(
                "Quelque chose s'est mal passé. Veuillez ressayer"
                );
            }
            toast({
              message: message,
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 4000,
              position: "bottom-right",
             });
          });
          this.newsletterIsLoading = false
          this.toogleAlertModal();
        }
      },
      activatePopupWithDelay(){
        setTimeout(()=>{
          this.activatePopup()
        },30000);
      },
      activatePopup(){
        //const consentCookie = this.getCookie("mtm_cookie_consent");
        if(!this.getCookie("nl_popup") && !this.$store.state.subscribed){
          this.toogleAlertModal()
          const expirationTime = new Date(0); 
          expirationTime.setTime(expirationTime.getTime() + 60 * 60 * 1000); // 60 minutes * 60 secondes * 1000 millisecondes
          this.setCookie("nl_popup",true,expirationTime)
        }
      },
      getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(";").shift();
      },
      setCookie(name, value, expirationTime) {
        document.cookie = `${name}=${value}; expires=${expirationTime.toUTCString()}; path=/`;
      }
      }  
  };
  </script>
  
  <style lang="scss" scoped>
  .error {
  border: 1px solid red;
}
.newsletterpopup{

  @media(max-width:400px){
    flex-direction: column-reverse;
    img{
      max-height: 200px;
    }
  }
}
  </style>
  