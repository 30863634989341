<template>
  <div class="box mb-4">
    <div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
        "
      >
        <div style="display: flex; flex-wrap: wrap">
          <h5 style="font-size: 1.3rem; font-weight: bold; margin-right: 1rem">
            Ma commande du
            {{ new Date(order.created_at).toLocaleDateString("fr-FR") }}
          </h5>
          <p v-if="order.status" style="margin-right: 1rem; font-size: 1.3rem">
            - {{ order.status }}
          </p>
        </div>
        <h5 style="font-size: 1rem; text-align: center">
          # {{ order.ref }}
        </h5>
      </div>
      <div style="display: flex; margin-bottom: 1rem">
        <a v-if="order.link_shipping" v-bind:href="order.link_shipping"
          ><i class="fa-solid fa-location-dot"></i> Lien suivi de
          livraison</a>
      </div>
      <CollapsingItem :alignStart="true" :border="true">
        <template #title>
          <h1 style="font-size: 1.5rem; margin-right: 1rem">
            Résumé de la commande
          </h1>
        </template>
        <template #content>
          <div>
            <div
              style="
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
                margin-bottom: 1rem;
              "
            >
            <div style="flex: 1 0 0; display: flex; flex-direction: column;font-size: 0.8rem;" >
                <h3 style="font-size: 1rem;">Adresse de livraison:</h3>
                <strong
                  >{{ order.shipping_address.first_name.toUpperCase() }}
                  {{ order.shipping_address.last_name.toUpperCase() }}</strong
                >
                <span>{{ order.shipping_address.address.toUpperCase() }}</span>
                <span
                  >{{ order.shipping_address.zipcode }} -
                  {{ order.shipping_address.city.toUpperCase() }}</span
                >
                <span>{{ order.shipping_address.country.toUpperCase() }}</span>
              </div>
              <div style="flex: 1 0 0; display: flex; flex-direction: column;font-size: 0.8rem;">
                <h3 style="font-size: 1rem;">Adresse de Facturation:</h3>
                <strong
                  >{{ order.billing_address.first_name.toUpperCase() }}
                  {{ order.billing_address.last_name.toUpperCase() }}</strong
                >
                <span>{{ order.billing_address.address.toUpperCase() }}</span>
                <span
                  >{{ order.billing_address.zipcode }} -
                  {{ order.billing_address.city.toUpperCase() }}</span
                >
                <span>{{ order.billing_address.country.toUpperCase() }}</span>
              </div>
            </div>
            <div>
              <h3 style="font-size: 1rem;">Mode de livraison :</h3>
              <div style="margin-top: 1rem;margin-bottom: 1rem;">
                <p>{{ order.livraison.label }}</p>
              </div>
              <div v-if="order.relay_address">
                  <strong
                    >{{ order.relay_address.relay_name.toUpperCase() }}</strong
                  ><br>
                <span>{{ order.relay_address.address.toUpperCase() }}</span><br>
                <span
                  >{{ order.relay_address.zipcode }} -
                  {{ order.relay_address.city.toUpperCase() }}</span
                ><br>
              </div>
            </div>
            <div style="float: right; display: flex">
              <!-- <button class="button-outline"  @click="displayForm"><strong>Demande de Retour</strong></button> -->
              <a
                class="button-outline"
                href="./outsidog-return-form.pdf"
                target="_blank"
                ><strong>Demande de Retour</strong></a
              >
            </div>
          </div>
        </template>
      </CollapsingItem>
      <CollapsingItem :alignStart="true" :border="true" style="flex: 1 0 0">
        <template #title>
          <div
            style="
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
            "
          >
            <h1 style="font-size: 1.5rem; margin-right: 1rem">Panier</h1>
            <p style="margin-right: 1rem">{{ order.items.length }} produits</p>
          </div>
        </template>
        <template #content>
          <div>
            <div></div>
            <div>
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Produits</th>
                    <th v-show="!mobile">Prix</th>
                    <th v-show="!mobile">Quantité</th>
                    <th v-show="!mobile">Total</th>
                    <th v-show="mobile">Infos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in order.items" v-bind:key="item.id">
                    <td>
                      <div class="thumbnail">
                        <router-link
                          class="product-link"
                          :to="item.get_absolute_url[0]"
                        >
                          <img v-bind:src="item.get_thumbnail" />
                        </router-link>
                      </div>
                      <p>{{ item.name }} - Taille:{{ item.get_size }}</p>
                    </td>
                    <td v-show="!mobile">{{ item.price }} €</td>
                    <td v-show="!mobile">{{ item.quantity }}</td>
                    <td v-show="!mobile">
                      {{ parseFloat(item.quantity * item.price).toFixed(2) }} €
                    </td>
                    <td v-show="mobile">
                      <p>Prix:{{ item.price }} €</p>
                      <p>Quantité: {{ item.quantity }}</p>
                      <p>
                        Total:
                        {{ parseFloat(item.quantity * item.price).toFixed(2) }}
                        €
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div class="footer-recap">
                <div class="footer-recap-line">
                  <p >Total produits:</p>
                  <p >{{ cartTotalPrice.toFixed(2) }} €</p>
                </div>
                <div class="footer-recap-line">
                  <p >Livraison:</p>
                  <p v-if="order.livraison" >
                    {{ order.livraison.price }} €
                  </p>
                  <p v-else >N/A</p>
                </div>
                <div class="footer-recap-line">
                  <p >Réduction:</p>
                  <div v-if="order.promo_codes">
                    <tr
                      v-for="promo in order.promo_codes"
                      v-bind:key="promo.id"
                    >
                      <p >{{ promo.reduction }} €</p>
                    </tr>
                  </div>
                  <p v-else>N/A</p>
                </div>
                <div class="footer-recap-line">
                  <span >Taxe totale:</span>
                  <span >Franchise de TVA*</span>
                </div>
                <p>*TVA non applicable - article 293 B du CGI</p>
                <div class="footer-recap-line">
                  <p >Total final:</p>
                  <p >{{ order.paid_amount }} €</p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </CollapsingItem>
    </div>
    <Modal ref="return">
      <div>
        <div class="message">
          <h2 class="subtitle">
            Choissisez les produits que vous voulez retourner:
          </h2>
          <div style="max-height: 500px; overflow-y: auto; overflow-x: hidden">
            <form @submit.prevent="onSubmit" @reset="onReset">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Retourner</th>
                    <th v-show="!mobile">Produit</th>
                    <th v-show="!mobile">Quantité</th>
                    <th v-show="!mobile">
                      Pour quelle raison souhaitez-vous retourner le(s)
                      produit(s) ?
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ReturnItemForm
                    v-for="item in returnItems"
                    v-bind:key="item"
                    v-bind:item="item"
                    ref="setItemRef"
                  />
                </tbody>
              </table>
              <button class="button-dark">Créer la demande de retour</button>
            </form>
          </div>
          <div style="display: flex">
            <div>
              <button class="button-light" @click="displayForm">Annuler</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toast } from "bulma-toast";
import useVuelidate from "@vuelidate/core";
import { helpers, required, minLength } from "@vuelidate/validators";
import ReturnItemForm from "@/components/ReturnItemForm";
import axios from "axios";
import CollapsingItem from "@/components/CollapsingItem";

export default {
  // setup() {
  //   return { v$: useVuelidate() };
  // },
  components: {
    ReturnItemForm,
    CollapsingItem,
  },
  name: "OrderSummary",
  data() {
    return {
      mobile: null,
      alertModal: false,
      numberOfReturn: "",
      returnItems: [],
      itemsToReturn: [],
    };
  },
  // validations() {
      //     return{
          //         returnItems: {
              //             $each: helpers.forEach({
                  //                 quantity: { required, minLength: minLength(8), $lazy: true},
                  //                 reason: { required, minLength: minLength(8), $lazy: true }
              //             })
  //         }
  //     }
  // },
  computed: {
    cartTotalPrice() {
      let total = this.order.items.reduce((acc, curVal) => {
        return (acc += parseFloat(curVal.price).toFixed(2) * curVal.quantity);
      }, 0);
      return total;
    },
  },
  mounted() {
    this.numberOfReturn = this.order.items.length;
    this.returnItems = [...Array(this.numberOfReturn).keys()].map(
      (i) => this.order.items[i] || {}
    );
  },
  props: {
    order: Object,
  },
  methods: {
    displayForm() {
      this.$refs.return.test();
    },
    async createReturnAsking() {
      const data = {
        order: this.order.id,
        items: this.itemsToReturn,
      };
      await axios
        .post("/api/v1/create-return-asking/", data)
        .then((response) => {
          toast({
            message:
              "Votre demande à été envoyée. Elle sera traité dans les plus bref délais. Vous pouvez retrouver le status de votre demande dans l onglet mes retours et remboursement.",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 8000,
            position: "top-center",
          });
        })
        .catch((error) => {
          toast({
            message:
              "Votre demande n a pas pu être envoyée. Veuillez nous contacter directement par mail si ça ne fonctionne toujours pas.",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 8000,
            position: "top-center",
          });
        });
    },
    onSubmit(e) {
    this.v.$touch();
    if (this.v.$invalid) return;
    const data = [];
    this.$refs.setItemRef.forEach((el) => {
    const elData = el.giveDataForm();
    if (elData) {
    data.push(elData);
    }
      });
    this.itemsToReturn = data;
    if (data.length > 0) {
    this.createReturnAsking();
    } else {
    toast({
    message: "Votre demande ne contient pas de produit à retourner.",
    type: "is-danger",
    dismissible: true,
    pauseOnHover: true,
    duration: 6000,
    position: "top-center",
    });
    }
      this.displayForm();
    },
    orderTotalLength(order) {
      return order.items.reduce((acc, curVal) => {
        return (acc += curVal.quantity);
      }, 0);
    },
    checkScreen() {
      this.windowsWidth = window.innerWidth;
      if (this.windowsWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      return;
    },
  },
  beforeMount() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreen);
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  width: 120px;
  height: 120px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
  }
}
.color-light {
  background-color: #dbd7d7;
}
.message {
  padding: 1rem;
}
.button-outline {
  border: none;
  font-size: 1rem;
  text-decoration: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 0.75rem 1.5rem;
  background-color: none;
  color: grey;
  cursor: pointer;
  &:hover {
    border: 1px solid rgb(82, 82, 82);
  }
}
.footer-recap-line {
  display: flex;
  justify-content: space-between;
  p {
    font-weight: bold;
  }
}
.footer-recap {
  max-width: 450px;
  width: 70vw;
  float: right;
}
</style>
