<template>
  <div
    class="page page-checkout"
    style="margin-top: 100px; margin: 0 auto; padding: 2rem 0rem"
  >
    <div class="columns is-multiline">
      <section
        class="column is-12 counters-section"
        style="display: flex; justify-content: center"
      >
        <h3 to="/" class="title active" style="margin-right: clamp(0.5rem,4vw,2rem);margin-left:clamp(0.5rem,4vw,2rem);font-size: clamp(0.8rem, 2vw, 1.2rem);">Livraison & Paiement</h3>
        <h3 to="/" class="title disabled" style="margin-right: clamp(0.5rem,4vw,2rem);margin-left:clamp(0.5rem,4vw,2rem);font-size: clamp(0.8rem, 2vw, 1.2rem);">Confirmation</h3>
      </section>
    </div>
    <div v-if="!cartTotalLength">
      <div
        style="
          display: flex;
          width: 100%;
          justify-content: center;
          padding-top: 10rem;
          padding-bottom: 10rem;
        "
      >
        <h1>Votre panier est vide</h1>
      </div>
    </div>
    <div v-else class="checkout-content">
      <div
        class="main-content"
        style="
          padding: clamp(4px, 4vw, 50px);
          background-color: white;
        "
      >
        <div class="wrapper-content">
          <hr />
          <div class="address-section">
            <AddressForm
              typeAddress="shipping"
              :checkout="true"
              ref="shipping"
              scope="foo"
            />
            <AddressForm
              v-show="!sameAddress"
              typeAddress="billing"
              :checkout="true"
              ref="billing"
              :scope="!sameAddress ? 'foo' : ''"
            />
          </div>

          <div
            id="inout"
            style="margin-top: 2rem; margin-bottom: 2rem; display: flex"
          >
            <input
              type="checkbox"
              id="billing_and_shipping_address_is_the_same"
              v-model="sameAddress"
              style="transform: scale(1.4); margin-right: 1rem"
            />
            <label for="billing_and_shipping_address_is_the_same">
              L'adresse de livraison et de facturation sont les mêmes.
            </label>
          </div>
          <div
            id="inout"
            style="margin-top: 2rem; margin-bottom: 2rem; display: flex"
          >
            <input
              type="checkbox"
              id="same_address"
              v-model="saveAddress"
              style="transform: scale(1.4); margin-right: 1rem"
            />
            <label for="same_address">
              Sauvegarder mes donnes pour la prochaine fois.
            </label>
          </div>
          <hr />
          <h2 class="subtitle">Choix de la livraison</h2>
          <hr />
          <p v-if="v$.shippingmethod.$error" class="error-message">
            Veuillez choisir un mode de livraison
          </p>

          <div
            v-for="livraison in livraisons"
            v-bind:key="livraison"
            class="container"
          >
            <input
              @click="livraisonfunction(livraison)"
              v-if="freeShippingApplied && livraison.promo_applicable"
              type="radio"
              :id="livraison.unique_id"
              name="monster"
              style="transform: scale(1.4)"
              :value="livraison"
              v-model="v$.shippingmethod.$model"
            />
            <input
              @click="livraisonfunction(livraison)"
              v-else-if="parseFloat(livraison.amount_shipping_free) >= 0"
              type="radio"
              :id="livraison.unique_id"
              name="monster"
              style="transform: scale(1.4)"
              :value="livraison"
              v-model="v$.shippingmethod.$model"
            />
            <input
              @click="livraisonfunction(livraison)"
              v-else
              type="radio"
              :id="livraison.unique_id"
              name="monster"
              style="transform: scale(1.4)"
              :value="livraison"
              v-model="v$.shippingmethod.$model"
            />
            <label
              class="container"
              :for="livraison.unique_id"
              style="
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                padding-left: 3rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                padding-right: 3rem;
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                "
              >
                <div style="diplay: flex; flex-direction: column">
                  <p>{{ livraison.label }}</p>
                  <p style="font-size: 1rem">{{ livraison.type }}</p>
                </div>
                <p v-if="parseFloat(livraison.price).toFixed(2) >= 0.0">
                  <span v-if="freeShippingApplied && livraison.promo_applicable"
                    >Offert</span
                  >
                  <span
                    v-else-if="parseFloat(livraison.price).toFixed(2) == 0.0"
                    >Offert</span
                  >
                  <span
                    v-else-if="
                      parseFloat(livraison.amount_shipping_free).toFixed(2) <=
                        cartWithReduction &&
                      parseFloat(livraison.amount_shipping_free) >= 0
                    "
                    >Offert</span
                  >
                  <span v-else>{{ livraison.price }} €</span>
                </p>
                <p v-else>Sélctionner une option de livraison</p>
              </div>
              <div style="font-size: clamp(0.8rem,4vw,1rem);" v-if="relaySelected && livraison.type === 'Relais'">
                <hr />
                <p v-if="pointRelai.relay_name">{{ pointRelai.relay_name }}</p>
                <p v-if="pointRelai.address">{{ pointRelai.address }}</p>
                <p v-if="pointRelai.city">{{ pointRelai.city }}</p>
                <p v-if="pointRelai.country">{{ pointRelai.country }}</p>
                <p v-if="pointRelai.zipcode">{{ pointRelai.zipcode }}</p>
                <button
                  id="change-point-relai"
                  type="button"
                  class="button-dark"
                  @click="$refs.relaymodal.test()"
                >
                  Changer de point relais
                </button>
              </div>
            </label>
            <div class="checkmark"></div>
          </div>
          <hr />
          <div
            style="
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
              margin: 2rem;
            "
          >
            <div>
              <input
                type="checkbox"
                id="cgv"
                value="cgv_accepted"
                style="transform: scale(1.4); margin-right: 0.5rem"
                v-model="v$.cgvAccepted.$model"
              />
              <span>
                J'ai lu et j'accepte les Conditions Générales de Vente.<br />
              </span>
            </div>
            <p>
              En cochant cette case, vous acceptez nos termes et conditions &
              politique de confidentialit
            </p>
            <p v-if="v$.cgvAccepted.$error" class="error-message">
              Veuillez accepter les conditions de ventes pour pouvoir procéder
              au paiement
            </p>
          </div>
          <div @click="createCheckoutSession" class="button-dark"  :class="{ 'isloading': createStripeCheckoutIsLoading }">
              <span v-if="createStripeCheckoutIsLoading">
                <i class="fa-solid fa-circle-notch fa-spin"></i>
              </span>
              <span v-else>
                Payer
              </span>
          </div>
          <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>
        </div>
      </div>
      <div class="sumup" style="background-color: white; padding: 0rem;padding: clamp(4px, 4vw, 50px); ">
        <div class="pin-container">
          <div class="box" style="margin-bottom: 2rem;">
            <div style="display: flex; align-items: center; margin-bottom: 2rem;">
              <h1 class="title" style="width: 100; text-align: center;font-size:clamp(1.3rem,2.5vw,2rem);font-weight: bold;margin-bottom: 0;">
                Votre Commande
              </h1>
              <span v-if="cartTotalLength > 0" style="margin-left:1rem;font-size:clamp(0.8rem,2.5vw,1.2rem);">
                ({{ cartTotalLength }} articles)
              </span>
              <i
                style="margin-left: 1rem;"
                id="write-review"
                class="fa-solid fa-angle-down"
              ></i>
            </div>

            <div class="test">
              <CartNavItem
                v-for="item in cart.items"
                v-bind:key="item.id"
                v-bind:item="item"
                v-bind:update="false"
              />
            </div>
            <h1 class="title" style="font-size:clamp(1.5rem,2.5vw,2rem);">Récapitulatif</h1>
            <div style="margin-top: 1rem">
              Sous-Total<span style="float: right"
                >{{ cartTotalPrice.toFixed(2) }}€</span
              >
            </div>
            <CodePromo
              v-for="i in codes"
              v-bind:key="i.id"
              v-bind:initialCode="i"
              v-bind:reduction="10"
              v-on:removeCode="removeCode"
            />
            <div v-if="shippingmethod" style="margin-top: 1rem">
              Livraison
              <span
                v-if="
                  parseFloat(this.shippingmethod.amount_shipping_free).toFixed(
                    2
                  ) <= cartWithReduction ||
                  (this.freeShippingApplied &&
                    this.shippingmethod.promo_applicable) ||
                  parseFloat(this.shippingmethod.price) == 0.0
                "
                style="float: right"
              >
                Offert
              </span>
              <span v-else style="float: right">
                {{ shippingmethod.price }} €
              </span>
            </div>
            <div v-else style="margin-top: 1rem">
              Livraison<span style="float: right; font-size: 0.8rem"
                >Choissisez une option de livraion</span
              >
            </div>
            <div class="separator1"></div>
            <div class="separator"></div>

            <div>
              TOTAL<span style="float: right"
                >{{ cartWithReductionAndShipping }}€</span
              >
            </div>
            <div v-if="cartTotalReduction > 0">
              REDUCTION<span style="float: right"
                >{{ cartTotalReduction.toFixed(2) }}€</span
              >
            </div>

            <div style="display: flex; flex-wrap: wrap; gap: 2rem"></div>
            <hr />
            <h1 class="subtitle">Code Promo</h1>

            <form class="form" @submit.prevent="applyCode">
              <div class="field has-addons" :class="{ 'isloading': applyCodeIsLoading }">
                <div class="control" style="width: 100%">
                  <input
                    type="text"
                    class="input"
                    v-model="codetitle"
                    placeholder="Code promo"
                  />
                </div>
                <div class="control" >
                  <button class="button">
                    <span v-if="applyCodeIsLoading">
                      <i class="fa-solid fa-circle-notch fa-spin"></i>
                    </span>
                    <span v-else class="icon" >
                      <i
                        class="fa-solid fa-arrow-right-long"
                        style="color: black; font-size: 1.2rem"
                      ></i>
                    </span>
                  </button>
                </div>
              </div>
            </form>
            <p v-if="codeinvalid" class="error-message">
              {{ codeinvalidmsg }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <Modal ref="relaymodal" :clickoutside="false">
      <div class="relay-selection" style="height: 100%; border-radius: 0;width: 100%;max-width: 850px;max-height: 1080px;">
        <div id="Zone_Widget" class="" style="height: 100%;"></div>
        <input type="hidden" id="ParcelShopCode" name="ParcelShopCode" />
        <div class="d-flex">
          <div style="display: flex">
            <button
              id="selection-point-relai"
              type="button"
              class="button-light"
              @click="closeModal"
            >
              Annuler
            </button>
            <button
              id="selection-point-relai"
              type="button"
              class="button-dark"
              @click="selectRelay"
            >
              Choisir ce point relais
            </button>
          </div>
          <div id="point-relai-choisi" class="box-element my-4 d-none">
            <div class="d-flex flex-column">
              <strong id="point-relai-choisi-address"></strong>
              <span id="point-relai-choisi-country"></span>
              <span id="point-relai-choisi-city"></span>
              <span id="point-relai-choisi-zipcode"></span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import CartNavItem from "./../components/CartNavItem.vue";
gsap.registerPlugin(ScrollTrigger);
import useVuelidate from "@vuelidate/core";
import AddressForm from "@/components/AddressForm.vue";
import CodePromo from "@/components/CodePromo";
import { toast } from "bulma-toast";
import { required } from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";

const { withAsync } = helpers;

export default {
  setup() {
    return { v$: useVuelidate({ $scope: 'foo' }) };
  },
  name: "CheckoutView",
  components: {
    CartNavItem,
    AddressForm,
    CodePromo,
  },
  data() {
    return {
      alertModal: false,
      cart: {
        items: [],
      },
      codetitle: "",
      codes: [],
      stripe: {},
      card: {},
      addressForm: true,
      modifyForm: false,
      saveAddress: true,
      paymentmethod: null,
      shippingmethod: null,
      sameAddress: true,
      cgvAccepted: null,
      errors: [],
      livraisons: [],
      tl3: null,
      c1: null,
      codeinvalid: false,
      codeinvalidmsg: "",
      selectedShipping: null,
      freeShippingApplied: false,
      relayModal: false,
      relaySelected: false,
      createStripeCheckoutIsLoading:false,
      applyCodeIsLoading:false,
      pointRelai: {
        relay_name: null,
        address: null,
        zipcode: null,
        city: null,
        country: null,
      },
      pointRelaiTmp: {
        relay_name: null,
        address: null,
        zipcode: null,
        city: null,
        country: null,
      },
    };
  },
  validations() {
    return {
      shippingmethod: { required, $lazy: true }, // Matches this.contact.email
      cgvAccepted: { required, $lazy: true , checked: value => value === true}, // Matches this.contact.email
    };
  },
  computed: {
    ...mapGetters([
      "cartTotalPrice",
      "cartItems",
      "cartTotalLength",
      "cartUpdateStatus",
    ]),
    closeModal() {
      this.$refs.relaymodal.test();
      this.shippingmethod = null;
    },
    getStatus() {
      if (this.cartUpdateStatus) {
        this.alertModal = true;
      }
      return false;
    },

    cartWithReduction() {
      return this.cartTotalPrice - this.cartTotalReduction;
    },
    cartWithReductionAndShipping() {
      let total = this.cartTotalPrice;
    
      if (this.cartWithReduction > 0) {
        total -= this.cartTotalReduction.toFixed(2);
      }
      if (this.shippingmethod) {
        if (
          !(
            parseFloat(this.shippingmethod.amount_shipping_free).toFixed(2) <
              this.cartWithReduction ||
            (this.freeShippingApplied &&
              this.shippingmethod.promo_applicable) ||
            parseFloat(this.shippingmethod.price) == 0.0
          )
        ) {
          total += parseFloat(this.shippingmethod.price);
        }
      }
      return parseFloat(total).toFixed(2);
    },
    cartTotalReduction() {
      let reduction = 0;
      this.codes.forEach((code) => {
        let totalPercentageReduction = 0;
        if (code.all_products) {
          // Réduction pour tous les produits
          totalPercentageReduction = this.cart.items.reduce((acc, item) => {
              return acc + (item.final_price * (parseFloat(code.percentage) / 100.0) * item.quantity);
            }, 0);
        } else {
          // Réduction pour certains produits spécifiques
          totalPercentageReduction = code.tailles.reduce((acc, curVal) => {
            const item = this.cart.items.find((i) => i.id === curVal);

            if (item) {
              return acc + (item.final_price * (parseFloat(code.percentage) / 100.0) * item.quantity);
            } else {
              return acc;
            }
          }, 0);
        }
        reduction += code.fixed + totalPercentageReduction;
      });

      return reduction;
    },
    cartGetListOfReduction() {
      let res = [];
      this.codes.forEach((code) => {
        let totalPercentageReduciton = code.tailles.reduce((acc, curVal) => {
          const exists = this.cart.items.filter((i) => i.id === curVal);

          if (exists.length) {
            return (acc +=
              exists[0].final_price *
              (parseFloat(code.percentage) / 100.0) *
              exists[0].quantity);
          } else {
            return (acc += 0);
          }
        }, 0);
        const item = {
          ref: code.ref,
          reduction: code.fixed + totalPercentageReduciton,
        };
        res.push(item);
      });
      return res;
    },
  },
  created() {
    this.getShipping();
  },
  mounted() {
    this.cart = this.$store.state.cart;
    this.$store.commit("updateQuantityCart");

    document.title = "Checkout  | Outsidog";
    $(document).ready(() => {
      $("#Zone_Widget").MR_ParcelShopPicker({
        Target: "#ParcelShopCode",
        Brand: "CC22URGV",
        Country: "FR",
        EnableGeolocalisatedSearch: false,
        Responsive: true,
        OnParcelShopSelected: (data) => {
          this.pointRelaiTmp.relay_name = data.Nom;
          if (data.Adresse2) {
              this.pointRelaiTmp.address = data.Adresse1 + " " + data.Adresse2;
          } else {
              this.pointRelaiTmp.address = data.Adresse1;
          }
          this.pointRelaiTmp.zipcode = data.CP;
          this.pointRelaiTmp.city = data.Ville;
          this.pointRelaiTmp.country = data.Pays;
        },
      });
    });
    // Provoque error when load (panier vide plus rien marche comme figé) error affcihe console filter_section is null
    if (this.cartTotalLength) {
      var tl = gsap.timeline({ paused: true });
      tl.to(".test", { height: "auto", duration: 0.0 }).reverse();
      var tl2 = gsap.timeline({ paused: true });
      tl2.to("#write-review", { rotate: -180, duration: 0.2 }).reverse();
      tl.reversed(!tl.reversed());
      tl2.reversed(!tl2.reversed());
      let filter_section = document.getElementById("write-review");
      filter_section.addEventListener("click", function () {
        tl.reversed(!tl.reversed());
        tl2.reversed(!tl2.reversed());
        ScrollTrigger.refresh();
      });
      let radiobox = document.getElementById("inout");
      radiobox.addEventListener("click", function () {
        ScrollTrigger.refresh();
      });
    }
  },
  methods: {
    livraisonfunction(livraison) {
      this.selectedShipping = livraison.unique_id;
      if (livraison.type == "Relais") {
        this.$refs.relaymodal.test();
      }
    },

    selectRelay() {
      if (this.pointRelaiTmp.address || this.pointRelai.address) {
        this.relaySelected = true;
        this.pointRelai.relay_name = this.pointRelaiTmp.relay_name;
        this.pointRelai.address = this.pointRelaiTmp.address;
        this.pointRelai.zipcode = this.pointRelaiTmp.zipcode;
        this.pointRelai.city = this.pointRelaiTmp.city;
        this.pointRelai.country = this.pointRelaiTmp.country;
      } else {
        this.shippingmethod = null;
      }
      this.$refs.relaymodal.test();
    },
    getDataBilling(data) {
      return data;
    },
    getDataShipping(data) {
      return data;
    },
    async getShipping() {
      this.$store.commit("setIsLoading", true);
      await axios
        .get("/api/v1/livraison/")
        .then((response) => {
          this.livraisons = response.data;
        })
        .catch((error) => {
          this.errors = [];
          if (error.response && error.response.status === 500) {
            this.errors.push(
              "Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard. "
              );
            } else {
            for (const property in error.response.data) {
              this.errors.push(`${error.response.data[property]}`);
            }
          }
        });
        this.$store.commit("setIsLoading", false);
    },
    updateCart() {
      localStorage.setItem("cart", JSON.stringify(this.$store.state.cart));
    },
    removeFromCart(item) {
      this.cart.items = this.cart.items.filter((i) => i.id !== item.id);
    },
    removeCode(code) {
      if (code.type == "LIVRAISON") {
        this.freeShippingApplied = false;
      }
      this.codes = this.codes.filter((i) => i.id !== code.id);
    },
    async applyCode() {
      try {
        const response = await axios.post("/api/v1/apply-code/", { title: this.codetitle });
        const codeData = response.data;

        const existingCode = this.codes.find(code => code.title === codeData.title);
        if (existingCode) {
          this.handleInvalidCode("Le code est déjà appliqué.");
          return;
        }

        let promoCodeCanBeApplied = true;

        if (codeData.type_promo != "LIVRAISON") {
          if (this.cartTotalPrice < codeData.min_amount) {
            this.handleInvalidCode("Le montant du panier n'est pas suffisant");
            return;
          }

          if (this.codes.length > 1 && codeData.excluding_discount) {
            this.handleInvalidCode("Il n'est pas cumulable avec d'autres promotions");
            return;
          }

          if (!codeData.all_products && !this.cartContainsApplicableProducts(codeData.tailles)) {
            this.handleInvalidCode("Le panier ne contient pas de produits éligibles");
            return;
          }
        }

        if (this.codes.some(value => (value.type === "LIVRAISON" && (value.excluding_discount || value.ref === codeData.ref)))) {
          this.handleInvalidCode("Le code ne peut pas être appliqué");
          return;
        }

        if (promoCodeCanBeApplied) {
          if (codeData.type_promo === "LIVRAISON") {
            this.freeShippingApplied = true;
            this.shippingmethod = null;
          }

          toast({
            message: "Le code a été appliqué à votre panier.",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "top-center",
          });

          this.codes.push({
            title: codeData.title,
            type: codeData.type_promo,
            percentage: codeData.promotion_percentage,
            fixed: codeData.promotion_fixed_amount,
            tailles: Array.from(codeData.products, (x) => x.unique_id),
            ref: codeData.ref,
            min_amount: codeData.min_amount,
            excluding_discount: codeData.excluding_discount,
            all_products: codeData.all_products,
          });
        } else {
          this.handleInvalidCode("Le code ne peut pas être appliqué au panier.");
        }
      } catch (error) {
        this.handleInvalidCode("Le code ne peut pas être appliqué au panier: " + error.response.data['error']);
      } finally {
        this.applyCodeIsLoading = false;
      }
    },

    handleInvalidCode(errorMessage) {
      this.codeinvalid = true;
      this.codeinvalidmsg = errorMessage;
      toast({
        message: "Le code ne peut pas être appliqué au panier: " + errorMessage,
        type: "is-danger",
        dismissible: true,
        pauseOnHover: true,
        duration: 3000,
        position: "top-center",
      });
    },

    cartContainsApplicableProducts(productIds) {
      return this.cart.items.some(item => productIds.includes(item.id));
    },
    async createCheckoutSession() {
      const isAddressValid = await this.$refs.shipping.checkAddr();
      let isBillingAddressValid = true; // Initialisez à true par défaut

      if (!this.sameAddress) {
        isBillingAddressValid = await this.$refs.billing.checkAddr();
      }


      if (isAddressValid && isBillingAddressValid) {
        let isFormCorrect = await this.v$.$validate();
        const error = document.getElementsByClassName("error-message");
        if (error.length > 0) {
          error[0].parentNode.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
        if (isFormCorrect && this.cgvAccepted) {
          this.createStripeCheckoutIsLoading = true
          if (this.saveAddress) {
            this.$refs.shipping.sendAdress();
            if (!this.sameAddress) {
              this.$refs.billing.sendAdress();
            }
          }
          const items = [];
          const promo_codes = [];
          for (let i = 0; i < this.cart.items.length; i++) {
            const item = this.cart.items[i];
            const obj = {
              quantity: item.quantity,
              taille: item.id,
            };
  
            items.push(obj);
          }
          for (let i = 0; i < this.codes.length; i++) {
            
            const promo_code = this.codes[i];
            const obj = {
              ref: promo_code.ref,
            };
  
            promo_codes.push(obj);
          }
  
          let billing = null;
          if (this.sameAddress) {
            billing = this.$refs.shipping.giveDataForm();
          } else {
            billing = this.$refs.billing.giveDataForm();
          }
          const data = {
            items: items,
            codes: promo_codes,
            livraison: this.selectedShipping,
            shipping: this.$refs.shipping.giveDataForm(),
            relai: this.pointRelai,
            billing: billing,
          };
          // this.errors.push(
          //   "Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard où contactez-nous à l'adresse suivante : contact@outsidog.com "
          // );
              
          await axios
            .post("/api/v1/create-checkout-session/", data)
            .then((response) => {
              this.createStripeCheckoutIsLoading = false
              window.location.href = response.data.url;
            })
            .catch((error) => {
              this.createStripeCheckoutIsLoading = false
              this.errors = [];
              if (error.response && error.response.status === 500) {
                this.errors.push(
                  "Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard. "
                  );
              } else {
                  for (const property in error.response.data) {
                    this.errors.push(`${error.response.data[property]}`);
                  }
              }
            });
          
        }
      }
    },
    toggleWindowOpacity() {
      this.alertModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-checkout{
  max-width: 1950px;
}
.main-content{
  flex: 6 0 0;
  @media (max-width: 1450px) {
    flex: 7 0 0;
  }

}

.sumup{
  flex: 3 0 0;
  @media (max-width: 1450px) {
    flex: 6 0 0;
  }

}
.counters-section {
  counter-reset: section;
  .title {
    counter-increment: section;
    font-size: 1rem;
    margin-right: 2rem;
    margin-left: 2rem;

    &:before {
      content: counter(section) "- ";
    }
  }

  .active {
    text-decoration: underline;
  }

  .disabled {
    opacity: 0.5;
  }

  .checked {
    &:before {
      content: "\2713 - " !important;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
.test {
  max-width: 500px;
  height: 0px;
  overflow: hidden;
  overflow-y: auto;
}
.separator {
  width: 100%;
  height: 2px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 25%;
  background-color: rgba(36, 35, 35, 0.2);
}
.disable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

/* Customize the label (the container) */
.container {
  display: flex;
  margin-bottom: 0.2rem;
  width: 100%;
  background: white;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 5px;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  background: red;
}

/* Create a custom radio button */
.checkmark {
  display: none;
  position: absolute;
  top: 25%;
  left: 1%;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid rgba(36, 35, 35, 0.2);
}

.container input:checked + label {
  background: rgb(233, 233, 233);
  border: 1px solid transparent;
  display: flex;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  border: 1px solid black;
}

.container:hover input ~ label {
  background: rgb(233, 233, 233);
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: white;
  border: 1px solid black;
  display: flex;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  display: none;
  font-style: normal;
  font-variant: normal;
  font: var(--fa-font-solid);
  font-weight: 900;
  font-size: 18px;
  content: "\f00c";
  top: 15%;
  left: 15%;
  width: 75%;
  height: 75%;
  border-radius: 50%;
}
.container label {
  width: 100%;
  background: white;
  border: 1px solid rgba(36, 35, 35, 0.2);
}

.checkout-content {
  display: flex;
  justify-content: space-between;
  padding: clamp(4px, 3vw, 20px);
  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
}
.error {
  border: 1px solid red;
}
.error-message {
  color: red;
}
.checkboxerror {
  outline: 1px solid red !important;
}
.address-section {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
}
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  .message {
    width: 100%;
    max-width: 500px;
    padding: 1rem;
  }
}
.display-modal-updated-enter-active,
.display-modal-updated-leave-active {
  transition: 0.1s ease all;
}

.display-modal-updated-enter-from,
.display-modal-updated-leave-to {
  transform: opacity(0%);
}

.display-modal-updated-enter-to,
.display-modal-updated-leave-from {
  transform: opacity(100%);
}
.window-opacity {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  opacity: 50%;
  background-color: black;
}
.display-window-opacity-updated-enter-active,
.display-window-opacity-updated-leave-active {
  transition: 0.1s ease all;
}

.display-window-opacity-updated-enter-from,
.display-window-opacity-updated-leave-to {
  transform: opacity(50%);
}

.display-window-opacity-updated-enter-to,
.display-window-opacity-updated-leave-from {
  transform: opacity(0);
}
.relay-selection {
  padding: 1rem;
  display: flex;
  background: white;
  flex-direction: column;
}
input {
  accent-color: rgb(45, 45, 83);
}


.pin-container {
  @media (min-width: 780px) {
    position: sticky;
    top: 70px;
    background-color: white;
    z-index: 1;
  }
}
</style>
