<template>
  <div v-if="zoom" style="position: absolute; z-index: 1; right: 0px">
    <button
      @click="zoomPhoto()"
      style="
        background-color: transparent;
        border: none;
        font-size: 1.4rem;
        padding: 1rem;
        color: grey;
      "
    >
      <i class="fa-solid fa-circle-plus fa-2xl"></i>
    </button>
  </div>
  <div  class="maincarrousel" style="position: relative; height: 100%">
    <div ref="myRef" id="draggable" class="carousel-draggable">
      <div v-if="url">
        <router-link :to="url" @click="handleLinkClick"
>
        <div
          ref="dragRef"
          @pointerdown="trackInitialPosition($event)"
          class="drag carousel-drag-grid"
        >
          <div v-for="image in images" v-bind:key="image" class="carousel-image">
            <slot name="image" :image="image" />
          </div>
        </div>
        </router-link>
      </div>
      <div v-else>
        <div
          ref="dragRef"
          @pointerdown="trackInitialPosition($event)"
          class="drag carousel-drag-grid"
        >
          <div v-for="image in images" v-bind:key="image" class="carousel-image">
            <slot name="image" :image="image" />
          </div>
        </div>
      </div>
      <div v-show="pagination" class="carousel__pagination">
        <button
          v-for="n in slidesCount"
          v-bind:key="n"
          @click="goto(n - 1)"
          :class="{ active: n - 1 == index }"
        ></button>
      </div>
    </div>
    <button
      class="carousel__multiple__nav carousel__multiple__prev"
      @click.prevent="prev"
      :disabled="index <= 0"
      :class="{ disable: index <= 0 }"
      style="color: black;"
    >
      <slot name="prev-icon" />
    </button>
    <button
      class="carousel__multiple__nav carousel__multiple__next"
      @click.prevent="next"
      :disabled="index >= slidesCount - 1"
      :class="{ disable: index >= slidesCount - 1 }"
      style="color: black;"
    >
      <slot name="next-icon" />
    </button>
  </div>
  <Modal v-if="zoom" ref="relaymodal" :clickoutside="true">
    <div class="modal">
      <div class="modal-content">
        <img :src="zoomedPhotoUrl" alt="Zoomed Photo" class="zoomed-photo" />
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem;
          "
        >
          <button
            @click="closeModal"
            style="background-color: transparent; border: none; font-size: 2rem; padding: 1rem;"
          >
            <i class="fa-regular fa-circle-xmark fa-2xl"></i>
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    url: {
      type: String,
      default: "",
    },
    faIconNext: {
      type: String,
      default: "'\\f104'", // Valeur par défaut de l'icône
    },
    faIconPrevious: {
      type: String,
      default: "'\\f054'", // Valeur par défaut de l'icône
    },
    pagination: Boolean,
    childElement: Number,
    childPerSlide: {
      type: Number,
      default: 1,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
  },
  name: "CarouselMultiple",
  components: {
  },
  data() {
    return {
      currentPosition: null,
      initialPosition: null,
      moving: false,
      transform: null,
      translateTotal: 0,
      translateStep: 0,
      translateThreshold: 0,
      maxWidth: 300,
      slides: [],
      carouselLengthPx: 0,
      swipetranslate: 0,
      updateWidth: true,
      slidesCount: 0,
      index: 0,
      diff: 0,
      loop: false,
      zoomedPhotoUrl: "",
      windowWidth :null,
    };
  },
  mounted() {
    this.init();
    this.$nextTick(() =>
      setTimeout(() => {
        this.init();
      }, 0)
    );
    if (!!this.$slots["image"]) {
      this.slidesCount = this.images.length + 1 - this.childPerSlide;
    }
  },
  computed: {},

  methods: {
    handleLinkClick() {
      this.$store.commit("setIsLoading", true);
    },
    zoomPhoto() {
      this.$refs.relaymodal.test();
      this.isZoomed = true;
      let image = this.images[this.index];
      this.zoomedPhotoUrl = image.get_image;
    },
    closeModal() {
      this.$refs.relaymodal.test();
    },
    goto(n) {
      this.getWidth();
      let translate = -(this.translateStep * this.childPerSlide * n);
      this.resetPlay();
      this.translate(translate);
      this.index = n;
    },

    updateCarousel() {
      // Update du carriusel seulement si resize de plus de 50px de la windows dans la largeur
      const newWindowWidth = window.innerWidth; 
      if (Math.abs(newWindowWidth - this.windowWidth )> 80) {
        this.windowWidth = newWindowWidth;
        this.index = 0;
        var rect = this.$refs.dragRef.getBoundingClientRect();
        this.carouselLengthPx = rect.right - rect.left;
        this.translateStep = this.carouselLengthPx / this.childElement;
        this.translateThreshold = this.translateStep / 6;
        this.translate(0);
      }
    },
    init() {
      this.$refs.dragRef.style["grid-template-columns"] =
        "repeat(" + this.childElement + ", 1fr)";
      let div = this.childElement / this.childPerSlide;
      let width = 100 * div;
      this.$refs.dragRef.style["width"] = width + "%";
      this.$refs.dragRef.style["left"] = this.translatePer + "%";
      this.$refs.dragRef.style["transform"] = "translate3d(0px,0px,0px)";
      this.$refs.dragRef.style["transition-duration"] = "0.0s";
      var rect = this.$refs.dragRef.getBoundingClientRect();
      this.carouselLengthPx = rect.right - rect.left;
      this.maxWidth = width;
      this.translateStep = this.carouselLengthPx / this.childElement;
      this.translateThreshold = this.translateStep / 6;
      this.translateTotal = 0;
      this.$refs.dragRef.style["transition-duration"] = "0.3s";
    },
    getWidth() {
      if (this.updateWidth) {
        var rect = this.$refs.dragRef.getBoundingClientRect();
        this.carouselLengthPx = rect.right - rect.left;
        this.translateStep = this.carouselLengthPx / this.childElement;
        this.translateStep = this.carouselLengthPx / this.childElement;
        this.translateThreshold = this.translateStep / 6;
        this.updateWidth = false;
      }
    },
    translate(value) {
      if (
        value <= 0 &&
        value >=
          -1 * this.carouselLengthPx + this.translateStep * this.childPerSlide
      ) {
        this.translateTotal = value;
        this.$refs.dragRef.style.transform =
          "translate3d(" + this.translateTotal + "px, 0px, 0px)";
      } else if (value > 0) {
        let endTranslate =
          -1 * this.carouselLengthPx + this.translateStep * this.childPerSlide;
        this.translateTotal = endTranslate;
        this.$refs.dragRef.style.transform =
          "translate3d(" + endTranslate + "px, 0px, 0px)";
      } else {
        let endTranslate =
          -1 * this.carouselLengthPx + this.translateStep * this.childPerSlide;
        this.translateTotal = endTranslate;
        this.$refs.dragRef.style.transform =
          "translate3d(" + endTranslate + "px, 0px, 0px)";
      }
      this.diff = 0;
    },
    next() {
      if (this.index < this.slidesCount - 1) {
        this.getWidth();
        let translate = this.translateTotal - this.translateStep;
        this.resetPlay();
        this.translate(translate);
        this.index++;
      }
    },
    prev() {
    if(this.index == 1){
      this.getWidth();
      let translate = 0;
      this.translate(translate);
      this.resetPlay();
      this.index--;
    }
    else if (this.index > 0) {
        this.getWidth();
        let translate = this.translateTotal + this.translateStep;
        this.translate(translate);
        this.resetPlay();
        this.index--;
      }
      // else{
      //   this.index=0;
      // }
    },
    play() {
      if (this.loop) {
        let app = this;
        this.timer = setInterval(function () {
          app.next();
        }, 2000);
      }
    },
    resetPlay() {
      clearInterval(this.timer);
      this.play();
    },
    selectSlide(i) {
      this.current = i;
      this.resetPlay();
    },
    trackInitialPosition(event) {
      this.getWidth();
      this.$refs.dragRef.style["transition-duration"] = "0s";
      if (event.type === "touchstart") {
        this.initialPosition = event.touches[0].pageX;
      } else if (event.type === "mousedown") {
        this.initialPosition = event.pageX;
      }
      else{
        this.initialPosition = event.pageX;
      }
      this.moving = true;
      const transformMatrix = window
        .getComputedStyle(this.$refs.dragRef)
        .getPropertyValue("transform");
      if (transformMatrix != "none") {
        this.transform = parseInt(transformMatrix.split(",")[4].trim());
      }
    },
    trackCurentPosition(event) {
      //event.preventDefault();
      if (this.moving == true) {
        if (event.type === "touchmove") {
          this.currentPosition = event.touches[0].pageX;
        } else if (event.type === "mousemove") {
          this.currentPosition = event.pageX;
        }
        else{
          this.currentPosition = event.pageX;
        }
        const diff = this.currentPosition - this.initialPosition;
        this.diff = diff;
        this.swipetranslate = this.transform + diff;
        this.$refs.dragRef.style.transform =
          "translate3d(" + this.swipetranslate + "px, 0px, 0px)";
      }
    },
    trackEndPosition() {
      if (this.moving == true) {
        let step = this.translateStep;
        let swipe = -1 * Math.round(this.swipetranslate);
        let threshold = this.translateThreshold;
        let quotient = parseInt(swipe / step);
        let translate = 0;
        if (this.diff < 0) {
          if (this.diff < -threshold) {
            if (this.index < this.slidesCount - 1) {
              this.index++;
              translate = -1 * step * (quotient + 1);
            } else {
              translate = -1 * step * quotient;
            }
          } else {
            translate = -1 * step * quotient;
          }
        } else if (this.diff == 0) {
          translate = -1 * step * this.index;
        } else {
          if (this.diff > threshold) {
            if (this.index > 0) {
              this.index--;
            }
            translate = -1 * step * quotient;
          } else {
            if (this.index == 0) {
              translate = -1 * step * quotient;
            } else {
              translate = -1 * step * (quotient + 1);
            }
          }
        }
        this.$refs.dragRef.style["transition-duration"] = "0.3s";
        this.translate(translate);
      }
      this.moving = false;
    },
  },
  beforeMount() {
    this.windowWidth = window.innerWidth; 
    window.addEventListener("resize", this.updateCarousel);
    window.addEventListener("resize", this.trackCurentPosition);
    window.addEventListener("mouseup", this.trackEndPosition);
    window.addEventListener("touchup", this.trackEndPosition);
    window.addEventListener("touchend", this.trackEndPosition);
    window.addEventListener("touchcancel", this.trackEndPosition);
    window.addEventListener("pointermove", this.trackCurentPosition);
    window.addEventListener("pointerup", this.trackEndPosition);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateCarousel);
    window.removeEventListener("resize", this.trackCurentPosition);
    window.removeEventListener("mouseup", this.trackEndPosition);
    window.removeEventListener("touchup", this.trackEndPosition);
    window.removeEventListener("touchend", this.trackEndPosition);
    window.removeEventListener("touchcancel", this.trackEndPosition);
    window.removeEventListener("pointermove", this.trackCurentPosition);
    window.removeEventListener("pointerup", this.trackEndPosition);
  },
  created() {
    if (!this.$refs.myRef) {
    }

    this.$nextTick(() => {
      if (this.$refs.myRef) {
        this.init();
      }
    });

  },
};
</script>

<style lang="scss" scoped>
.carousel-drag-grid {
  cursor: pointer;
  position: static;
  display: grid;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(1, 1fr);
}

.drag {
  touch-action: pan-y !important;
  transition-duration: 0s;
  transition-timing-function: ease;
}

.carousel-draggable {
  touch-action: pan-y !important;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.carousel__multiple__nav {
  outline: none;
  text-decoration: none;
  border: none;
  color: none;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel__multiple__next {
  outline: none;
  right: 10px;
  &:before {
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font: var(--fa-font-solid);
    font-weight: 900;
    font-size: 18px;
    content: "";
    position: relative;
  }
  i{
    color: black;
    
  }
}
.carousel__multiple__prev {
  left: 10px;
  &:after {
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font: var(--fa-font-solid);
    font-weight: 900;
    font-size: 18px;
    content: "";
    position: relative;
  }
  i{
    color: black;
  }
}

.carousel__pagination {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  button {
    all: unset;
    cursor: pointer;
    margin: 0.2rem;
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: rgb(255, 255, 255);
    opacity: 0.8;
    border-radius: 50%;
  }
  .active {
    transform: 0.5s ease all;
    background-color: rgb(12, 12, 12);
  }
}
.disable {
  opacity: 0.1;
}

.carousel-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
    object-position: center;
  }
}
.modal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  z-index: 8;
  justify-content: center;
}

.modal-content {
  max-width: 100%;
  max-height: 100%;
}
.zoomed-photo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Ajoutez ces règles pour permettre à l'utilisateur de manipuler l'image zoomée */
.zoomed-photo {
  cursor: grab;
  touch-action: pan-y;
}

.zoomed-photo:active {
  cursor: grabbing;
}
</style>
