<template>
  <tr v-show="!mobile">
    <td>
      <input
        type="checkbox"
        id="billing_and_shipping_address_is_the_same"
        v-model="toreturn"
        style="transform: scale(1.4)"
      />
    </td>
    <td>{{ item.name }} - Taille:{{ item.get_size }}</td>
    <td v-show="toreturn">
      <input
        type="number"
        id="tentacles"
        name="tentacles"
        min="1"
        :max="item.quantity"
        onkeydown="return false"
        :class="{ error: v$.quantity.$error }"
        v-model="v$.quantity.$model"
        @blur="v$.quantity.$touch"
      />
      <p v-if="v$.quantity.$error" class="error-message">
        Veuillez entrer une valeur inférieur à la quantité commandée.
      </p>
    </td>
    <td v-show="toreturn">
      <div class="field" style="flex: 1 0 0">
        <div class="control">
          <div class="select">
            <select
              id="id_country"
              :class="{ error: v$.reason.$error }"
              v-model="v$.reason.$model"
              @blur="v$.reason.$touch"
            >
              <option value="WRONG_ITEMS">Mauvais produit recptionné</option>
              <option value="DEFAULT_PRODUCT">Produit endommagé</option>
              <option value="LEGAL_RETRACT">Demande de retour légal</option>
              <option value="OTHER">Autre</option>
            </select>
          </div>
          <p v-if="v$.reason.$error" class="error-message">
            Veuillez la raison du retour.
          </p>
        </div>
      </div>
    </td>
  </tr>
  <tr v-show="mobile">
    <td>
      <input
        type="checkbox"
        id="billing_and_shipping_address_is_the_same"
        v-model="toreturn"
        style="transform: scale(1.4); margin-right: 1rem"
      />
      <label for="toreturn">
        {{ item.name }} - Taille:{{ item.get_size }}
      </label>
      <div v-show="toreturn">
        <p>Quantité:</p>
        <input
          type="number"
          id="tentacles"
          name="tentacles"
          min="1"
          :max="item.quantity"
          onkeydown="return false"
          :class="{ error: v$.quantity.$error }"
          v-model="v$.quantity.$model"
          @blur="v$.quantity.$touch"
        />
        <p v-if="v$.quantity.$error" class="error-message">
          Veuillez entrer une valeur inférieur à la quantité commandée.
        </p>
      </div>
      <div v-show="toreturn">
        <div class="field" style="flex: 1 0 0">
          <div class="control">
            <p>Raison du retour:</p>
            <div class="select">
              <select
                id="id_country"
                :class="{ error: v$.reason.$error }"
                v-model="v$.reason.$model"
                @blur="v$.reason.$touch"
              >
                <option value="WRONG_ITEMS">Mauvais produit recptionné</option>
                <option value="DEFAULT_PRODUCT">Produit endommagé</option>
                <option value="LEGAL_RETRACT">Demande de retour légal</option>
                <option value="OTHER">Autre</option>
              </select>
            </div>
            <p v-if="v$.reason.$error" class="error-message">
              Veuillez la raison du retour.
            </p>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {
  requiredIf,
  integer,
} from "@vuelidate/validators";

export default {
  name: "ReturnItemForm",
  props: {
    item: Object,
  },
  methods: {
    giveDataForm() {
      if (!this.toreturn) {
        return null;
      }
      const dataForm = {
        orderItem: this.item.id,
        quantity: this.quantity,
        status: this.reason,
      };
      return dataForm;
    },
    checkScreen() {
      this.windowsWidth = window.innerWidth;
      if (this.windowsWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      return;
    },
  },
  beforeMount() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreen);
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      mobile: null,
      toreturn: true,
      quantity: null,
      reason: "",
    };
  },
  validations() {
    return {
      quantity: {
        requiredIfReturn: requiredIf(this.toreturn),
        integer,
        minValue: 1,
        $lazy: true,
      },
      reason: { requiredIfReturn: requiredIf(this.toreturn), $lazy: true },
    };
  },
};
</script>
<style lang="scss" scoped>
.error {
  border: 1px solid red;
}
.error-message {
  color: red;
}
</style>
