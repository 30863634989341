<template>
  <div>
    <header
    id="header"
    :style="{
      '--default-color': defaultColor,
      'background-color': defaultBackground,
    }"
    >
    <AnnouncementBanner />
    <div style="width: 100%;">
      <nav id="nav-bar">
        <ul
          v-show="!$route.matched.some(({ name }) => name === 'checkout')"
          class="left-navigation"
        >
          <div class="hamburger-menu">
            <li
              @click="[toggleMobileNav()]"
              class="hamburger-icon"
              :class="{ 'icon-active': mobileNav }"
            ></li>
          </div>
          <router-link
            v-show="mobile && !mobileNav"
            style="color: black; padding-left: 1rem"
            to="/"
            class="brand-name"
            >OUTSIDOG<sup style="font-size: 20px">®</sup></router-link
          >
          <router-link
            v-show="!mobile && !mobileNav && !tablette"
            @click="[(mobileNav = false), (dropSubNav = false)]"
            to="/collection/vetements"
            class="link"
            :class="{ 'color-bck': mobileNav }" style="font-size: clamp(18px, 1.3vw, 28px);"
            >Vêtements</router-link
          >
          <router-link
            v-show="!mobile && !mobileNav && !tablette"
            @click="[(mobileNav = false), (dropSubNav = false)]"
            to="/collection/equipements"
            class="link"
            :class="{ 'color-bck': mobileNav }" style="font-size: clamp(18px, 1.3vw, 28px);"
            >Equipements</router-link
          >
        </ul>
        <ul
          v-show="
            !mobile || $route.matched.some(({ name }) => name === 'checkout')
          "
          class="center-navigation"
        >
          <li
            v-show="!mobileNav"
            style="display: flex; align-items: center; justify-content: center"
          >
            <router-link
              class="link"
              to="/"
              style="
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <img
                src="@/assets/images/logo-black.png"
                alt=""
                style="height: 55px; margin-right: 1rem"
              />
              <p>OUTSIDOG<sup style="font-size: 20px">®</sup></p>
            </router-link>
          </li>
        </ul>
        <ul
          v-show="!$route.matched.some(({ name }) => name === 'checkout')"
          class="right-navigation"
        >
          <li>
            <router-link to="/my-favorites" class=""
              ><i class="fa-regular fa-heart"></i
            ></router-link>
          </li>
          <li>
            <router-link to="/my-account" class=""
              ><i class="fa-regular fa-user"></i
            ></router-link>
          </li>
          <li
            @click="toggleCartNav"
            ref="myDiv"
            v-bind:data-count="cartTotalLength"
            class="cart-icon"
          >
            <i class="fa-solid fa-bag-shopping"> </i>
          </li>
          <div class="navbar-item"></div>
        </ul>
        <transition name="display-mobilenav-opacity">
          <div
            @click="toggleWindowOpacity"
            v-show="mobileNav"
            id="window-opacity"
          ></div>
        </transition>

        <transition name="dropright-mobile-nav">
          <div v-show="mobileNav" class="mobile-nav">
            <div
              style="
                height: 100%;
                width: 100%;
                background-color: white;
                padding-top: 80px;
              "
            >
            <ul>
              <li
                v-for="category in categories"
                :key="category"
                :class="category == categoryselected && 'context'"
                class="mobile-nav-li"
                @click="handleCategoryClick(category)"
              >
                <p v-if="category.subcategories.length > 0" @touchstart="[(dropSubNav = true), selected(category)]" @mouseover="[(dropSubNav = true), selected(category)]" class="link test-arrow">
                  {{ category.name }}
                </p>
                <router-link v-else class="link test-arrow" @touchstart="[(dropSubNav = true), selected(category)]" @mouseover="[(dropSubNav = true), selected(category)]" :to="category.url" :class="category == categoryselected && 'context'">
                  {{ category.name }}
                </router-link>
              </li>
            </ul>

            </div>
            <transition name="droprigh-mobile-sub-nav">
              <div v-show="dropSubNav" class="sub-nav">
                <div class="sub-nav-positioning">
                  <div class="sub-mobile-nav about-sub-nav">
                    <ul v-if="mobile" class="ul">
                      <li>
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-left: 1rem;
                          "
                        >
                          <button
                            style="
                              margin-right: 1rem;
                              cursor: pointer;
                              z-index: 5;
                              border: none;
                              box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.07);
                              width: 40px;
                              height: 40px;
                              background-color: white;
                              border-radius: 50%;
                              color: black;
                            "
                            id="back"
                            @click="translate()"
                          >
                            <i class="fa-solid fa-arrow-left-long fa-lg"></i>
                          </button>
                          <strong>{{ categoryselected.name }} </strong>
                        </div>
                      </li>
                      <li
                        v-for="subcategorie in categoryselected.subcategories"
                        :key="subcategorie"
                        class="li sub-nav-li mobile-sub-nav-li"
                        @click="toggleMobileNav()"
                      >
                        <router-link
                          class="link test-arrow"
                          :to="subcategorie.url"
                        >
                          {{ subcategorie.name }}
                        </router-link>
                        <div v-if="subcategorie.image" style="height: 50px; width: 50px">
                          <img
                            class="img-subcategory"
                            :src="subcategorie.image"
                            alt=""
                            style="height: 100%; width: 100%; object-fit: contain;"
                          />
                        </div>
                      </li>

                    </ul>
                    <ul v-else-if="tablette" class="ul">
                      <li
                        v-for="subcategorie in categoryselected.subcategories"
                        v-bind:key="subcategorie"
                        class="li sub-nav-li img-subcategori"
                        @mouseover="
                          [(hovver = true), selectedSub(subcategorie)]
                        "
                        @mouseleave="[(hovver = false)]"
                        :class="{
                          context:
                            hovver && subcategorie == subcategoryselected,
                        }"
                        @click="[toggleMobileNav(), goTo(subcategorie.url)]"
                        style="cursor: pointer"
                      >
                        <router-link
                          @click="[toggleMobileNav()]"
                          class="link"
                          :to="subcategorie.url"
                          >{{ subcategorie.name }}</router-link
                        >
                        <div
                          v-if="subcategorie.image"
                          style="height: 50px; width: 50px"
                        >
                          <img
                            class="img-subcategory"
                            v-bind:src="subcategorie.image"
                            alt=""
                            style="
                              height: 100%;
                              width: 100%;
                              object-fit: contain;
                            "
                          />
                        </div>
                      </li>
                    </ul>
                    <ul v-else class="ul">
                      <li
                        v-for="subcategorie in categoryselected.subcategories"
                        v-bind:key="subcategorie"
                        @mouseover="
                          [(hovver = true), selectedSub(subcategorie)]
                        "
                        @mouseleave="[(hovver = false)]"
                        :class="{
                          context:
                            hovver && subcategorie == subcategoryselected,
                        }"
                        class="li sub-nav-li img-subcategori"
                        @click="[toggleMobileNav(), goTo(subcategorie.url)]"
                        style="cursor: pointer"
                      >
                        <router-link
                          @click="[toggleMobileNav()]"
                          class="link test-arrow"
                          :to="subcategorie.url"
                          >{{ subcategorie.name }}</router-link
                        >
                        <div
                          v-if="subcategorie.image"
                          style="height: 50px; width: 50px"
                        >
                          <img
                            class="img-subcategory"
                            v-bind:src="subcategorie.image"
                            alt=""
                            style="
                              height: 100%;
                              width: 100%;
                              object-fit: contain;
                            "
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </transition>
      </nav>
    </div>
    </header>
    <transition name="display-window-opacity">
      <div
        @click="toggleWindowOpacity"
        v-show="cartNav"
        class="window-opacity"
      ></div>
    </transition>
    <transition name="dropleft-cart-nav">
      <div v-show="cartNav" class="cart-nav">
        <div class="cart-header">
          <h1 style="font-style: bold">
            Votre panier
            <span v-if="cartTotalLength > 0">({{ cartTotalLength }})</span>
          </h1>
          <i
            @click="toggleCartNav"
            class="fa-solid fa-xmark"
            style="font-size: 1.4rem"
          ></i>
        </div>
        <div class="separator"></div>
        <div style="display: flex">
          <i
            class="fa-solid fa-box"
            style="margin-right: 2rem; font-size: 2rem"
          ></i>
          <p style="font-size: 0.8rem">
            LIVRAISON OFFERTE<br />
            A partir de 60€ via Collisimo
          </p>
        </div>
        <div class="separator"></div>
        <div class="cart-body">
          <div v-if="cart.items.length <= 0">
            <div
              style="
                display: flex;
                width: 100%;
                justify-content: center;
                padding-top: 10rem;
                padding-bottom: 10rem;
                align-items: center;
                flex-direction: column;
              "
            >
              <h1>Votre panier est vide</h1>
              <router-link
                @click="toggleCartNav"
                to="/collection/all"
                class="button-dark"
                style="margin-left: 1rem"
              >
                <span>Découvrez notre collection</span>
              </router-link>
            </div>
          </div>

          <tbody v-else>
            <CartNavItem
              v-for="item in cart.items"
              v-bind:key="item.id"
              v-bind:item="item"
              v-bind:update="true"
              v-on:removeFromCart="removeFromCart"
              v-on:toggleCartNav="toggleCartNav"
            />
          </tbody>
        </div>
        <div v-if="cart.items.length > 0" class="cart-footer">
          <div>
            Livraison<span style="float: right; font-size: 0.8rem"
              >Choix de livraison à l'étape suivante</span
            >
          </div>
          <div class="separator1"></div>
          <div>
            TOTAL<span style="float: right"
              >{{ cartTotalPrice.toFixed(2) }}€</span
            >
          </div>
          <div style="display: flex">
            <router-link
              @click="toggleCartNav"
              to="/cart/checkout"
              class="button-dark"
              style="margin-left: 1rem"
            >
              <span>Commander</span>
            </router-link>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CarouselMultiple from "./CarouselMultiple.vue";
import CartNavItem from "./CartNavItem.vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
import AnnouncementBanner from "@/components/AnnouncementBanner";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    onMounted(async () => {
      await router.isReady();
    });
    return { route: route.path };
  },
  name: "NavBar",
  components: {
    CarouselMultiple,
    CartNavItem,
    AnnouncementBanner
  },
  metaInfo() {
    return {
    };
  },
  data() {
    return {
      subcategoryselected: null,
      hovver: false,
      defaultColor: "black",
      defaultBackground: "white",
      mobile: null,
      tablette: null,
      mobileNav: null,
      cartNav: null,
      vetSubNav: false,
      dropSubNav: false,
      windowsWidth: null,
      shopActive: false,
      aboutActive: false,
      contactActive: false,
      hastranslate: false,
      categoryselected: {
        name: "",
        isImportant: false,
        isInNavBar: false,
        url: "/",
      },
      categories: [
        {
          name: "Vêtements",
          isImportant: false,
          isInNavBar: false,
          url: null,
          subcategories: [
            {
              name: "Tout voir",
              isImportant: false,
              isInNavBar: false,
              url: "/collection/vetements",
            },
            {
              name: "Vestes & Gilets",
              isImportant: false,
              isInNavBar: false,
              url: "/collection/vestes-et-gilets",
              image: require("@/assets/images/blouson-kaki.png"),
            },
            {
              name: "Hauts",
              isImportant: false,
              isInNavBar: false,
              url: "/collection/hauts",
              image: require("@/assets/images/pull-bleu.png"),
            },
          ],
        },
        {
          name: "Equipements",
          isImportant: false,
          isInNavBar: false,
          url: null,
          subcategories: [
            {
              name: "Tout voir",
              isImportant: false,
              isInNavBar: false,
              url: "/collection/equipements",
            },
            {
              name: "Colliers",
              isImportant: false,
              isInNavBar: false,
              url: "/collection/colliers",
              image: require("@/assets/images/png-collar.png"),
            },
            {
              name: "Harnais",
              isImportant: false,
              isInNavBar: false,
              url: "/collection/harnais",
              image: require("@/assets/images/harnais-dog.png"),
            },
            {
              name: "Laisses",
              isImportant: false,
              isInNavBar: false,
              url: "/collection/laisses",
              image: require("@/assets/images/laisse-chien.png"),
            },
          ],
        },
        {
          name: "Explorer OUTSIDOG",
          isImportant: false,
          isInNavBar: false,
          url: "/",
          subcategories: [
            {
              name: "Notre histoire",
              isImportant: false,
              isInNavBar: false,
              url: "/about-us",
              image: null,
            },
            {
              name: "Nos actualités",
              isImportant: false,
              isInNavBar: false,
              url: "/news",
              image: null,
            },
          ],
        },
        {
          name: "Informations et FAQ",
          isImportant: false,
          isInNavBar: false,
          url: "/faq",
          subcategories: [],
        },
      ],
      cart: {
        items: [],
      },
      tl: null,
      t2: null,
      myTimeline: null,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    cartTotalLength() {
      let totalLength = 0;
      for (let i = 0; i < this.cart.items.length; i++) {
        totalLength += this.cart.items[i].quantity;
      }
      return totalLength;
    },
    cartTotalPrice() {
      let total = this.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.final_price.toFixed(2) * curVal.quantity);
      }, 0);
      return total;
    },
  },
  head() {
    return {
      // bodyAttrs: {
      //   class: this.mobileNav || this.cartNav ? "hide-overflow" : "",
      // },
    };
  },
  methods: {
    handleCategoryMouseOver(category) {
    this.dropSubNav = true;
    this.selected(category);
  },
  handleCategoryClick(category) {
    if (category.subcategories.length > 0) {
      this.translate();
    } else {
      this.toggleMobileNav();
      this.selected(category);
    }
  },
    goTo(url) {
      this.$router.push(url);
      this.toggleMobileNav();
    },
    selected(category) {
      this.categoryselected = category;
      this.tl2.restart();
    },
    selectedSub(subcategory) {
      this.subcategoryselected = subcategory;
    },
    removeFromCart(item) {
      this.cart.items = this.cart.items.filter((i) => i.id !== item.id);
      
      _paq.push(['removeEcommerceItem', item.id]);
      _paq.push(['trackEcommerceCartUpdate', this.cartTotalPrice]);
    },
    checkScreen() {
      this.windowsWidth = window.innerWidth;
      if (this.windowsWidth <= 780) {
        this.mobile = true;
        return;
      }
      if (this.windowsWidth <= 1000 && this.windowsWidth >= 780) {
        this.tablette = true;
      }
      else{
        this.tablette = false;
      }
      if (document.body.classList.contains("hide-overflow")) {
        document.body.classList.remove("hide-overflow");
      }
      this.mobile = false;
      //this.mobileNav = false;
      //this.cartNav = false;
      return;
    },
    translate() {
      this.hastranslate = !this.hastranslate;
      if (this.dropSubNav) {
      }
      if (this.mobile) {
        this.tl.reversed(!this.tl.reversed());
      }
    },
    toggleMobileNav() {
      if (document.body.classList.contains("hide-overflow")) {
        document.body.classList.remove("hide-overflow");
      } else {
        document.body.classList.add("hide-overflow");
      }
      if (this.mobile) {
        if (this.hastranslate) {
          this.translate();
        }
      }
      if (this.dropSubNav) {
        this.dropSubNav = false;
      }
      this.mobileNav = !this.mobileNav;
    },
    toggleCartNav() {
      if (document.body.classList.contains("hide-overflow")) {
        document.body.classList.remove("hide-overflow");
      } else {
        document.body.classList.add("hide-overflow");
      }
      this.cartNav = !this.cartNav;
    },
    toggleWindowOpacity() {
      document.body.classList.remove("hide-overflow");
      this.dropSubNav = false;
      if (this.mobileNav) {
        this.mobileNav = !this.mobileNav;
      }
      if (this.cartNav) {
        this.cartNav = !this.cartNav;
      }
    },
    createTimeLine() {
      this.myTimeline = gsap.to("#header", {
        scrollTrigger: {
          toggleActions: "play none none reverse",
          start: "top",
          end: "+=500",
          markers: "true",
        },
        "background-color": "blue ",
        "--default-color": "green",
        duration: 0.2,
      });
    },
    destroyTimeLine() {
      if (this.myTimeline) {
        this.myTimeline.kill();
      }
    },
  },
  mounted() {
    this.cart = this.$store.state.cart;
    let trigger = 1;
    var containers = gsap.utils.toArray(trigger);
    this.$store.commit("updateQuantityCart");
    var tl4 = gsap.timeline({ paused: true });
    tl4
      .to(".mobile-nav", { x: "-100%", ease: "power3.inOut", duration: 0.4 })
      .reverse();
    this.tl = tl4;
    var rule = CSSRulePlugin.getRule(".img-subcategori::after");
    var rule2 = CSSRulePlugin.getRule(".img-subcategory");

    var tl5 = gsap.timeline();
    tl5
      .to(rule2, { duration: 0.6, opacity: "1", ease: "power1.out" }, 0)
      .to(".ul", { duration: 0.1, opacity: "1", ease: "power1.out" })
      .to(
        ".ul",
        { duration: 0.2, x: "+1px", opacity: "1", ease: "power1.out" },
        0
      )
      .to(rule, { duration: 0.4, width: "100%", ease: "power1.out" }, 0);
    this.tl2 = tl5;
  },
  beforeMount() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreen);
  },
  beforeUpdate() {
  },
  beforeDestroy() {
    this.destroyTimeLine();
  },
};
</script>

<style lang="scss" scopped>
header {
  --default-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  color: var(--default-color);
  font-size: 15px;
  background-color: transparent;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  
  nav {
    height: 70px;
    position: sticky;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (max-width: 780px) {
      width: 100%;
    }

    li {
      display: flex;
      position: relative;
    }
    .center-navigation {
      z-index: 5;
      flex: 1 1 0;
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 1.5rem;
      a {
        text-align: center;
      }
      .brand-mobile-nav {
        display: none;
      }
      .link {
        color: var(--default-color);
        font-size: 2.2rem;
      }
    }

    .left-navigation {
      flex: 1 1 0;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-size: 1rem;
      padding-left: 24px;
      z-index: 5;
      .link-left-nav {
        display: flex;
        align-items: center;
        margin: 0 10px 0 0;
        &:hover {
          background-color: #fc6500;
        }
      }

      .link {
        cursor: pointer;
        position: relative;
        color: var(--default-color);
        font-size: 1.3rem;
        text-decoration: none;
        transition: 0.5s ease all;
        padding-bottom: 10px;
        padding-top: 10px;
        margin-left: 20px;
        border-bottom: 1px solid transparent;

        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          height: 2px;
          width: 0%;
          background-color: var(--default-color);
          transition: all ease-in-out 250ms;
        }

        &:hover::after {
          width: 100%;
        }
      }

      .hamburger-icon {
        width: 25px;
        height: 25px;
        position: relative;
        cursor: pointer;
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: +15px;
          height: 3px;
          width: 100%;
          border-radius: 2px;
          background-color: var(--default-color);
          transition: 0.5s ease all;
          transform-origin: left;
        }
        &:before {
          content: "";
          display: block;
          position: absolute;
          bottom: 5px;
          height: 3px;
          width: 100%;
          border-radius: 2px;
          background-color: var(--default-color);
          transition: 0.2s ease all;
          transform-origin: left;
        }
        @media (hover: hover) {
        &:hover::before {
          width: 50%;
        }
      }
      }
      .icon-active {
        &:before {
          background-color: black;
          width: 80%;
          transform: translateX(2px) translateY(2px) rotateZ(-45deg);
        }
        &:after {
          background-color: black;
          width: 80%;
          transform: translateX(2px) translateY(-2px) rotateZ(45deg);
        }
        &:hover::before {
          background-color: #fc6500;
          width: 80%;
        }
        &:hover::after {
          background-color: #fc6500;
        }
      }
    }

    .right-navigation {
      flex: 1 1 0;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      color: var(--default-color);

      li {
        margin-left: 1rem;
        i:hover {
          cursor: pointer;
        }
      }
      i {
        border-radius: 10%;
        color: var(--default-color);
      }
    }

    .mobile-nav {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 30%;
      max-width: 780px;
      min-width: 400px;
      height: 100lvh;
      background-color: rgb(22, 214, 163);
      top: 0;
      left: 0;
      z-index: 3; // au dessus de z-index=1 du header
      @media (max-width: 780px) {
        width: 100%;
        min-width: 180px;
      }
      .mobile-nav-li {
        margin: 40px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.4);
        max-width: 780px;
        @media (max-width: 780px) {
          &:after {
            -webkit-font-smoothing: antialiased;
            text-rendering: auto;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            font: var(--fa-font-solid);
            content: "\f054";
            position: relative;
            top: 1rem;
            text-align: center;
          }
        }
      }
      .bottom-line {
        &:after {
          -webkit-font-smoothing: antialiased;
          text-rendering: auto;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          font: var(--fa-font-solid);
          content: "\f054";
          position: relative;
          top: 1rem;
          text-align: center;
        }
      }
      .sub-nav-li {
        margin: 40px;
        max-width: 780px;
      }
      .mobile-sub-nav-li {
        @media (max-width: 780px) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.4);
          &:after {
            -webkit-font-smoothing: antialiased;
            text-rendering: auto;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            font: var(--fa-font-solid);
            content: "\f054";
            position: relative;
            top: 1rem;
            text-align: center;
          }
        }
      }
      .link {
        cursor: pointer;
        margin: 0px 20px 20px 0px;
        text-align: left;
        color: black !important;
        width: 100%;
        height: 100%;
        font-size: 1.2rem;
      }
      .sub-nav {
        position: absolute;
        top: 0px;
        left: +100%;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: -1 !important;
        // background-color: rgb(245, 245, 245);

        &:before {
          // background-color: rgba(0, 0, 0, 0.5);
          content: "";
          display: fixed;
          top: 0px;
          height: 100%;
          position: absolute;
          width: 100%;
        }
        .sub-nav-positioning {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          @media (max-width: 780px) {
            justify-content: flex-start;
          }
        }
        .navside {
          width: 400px;
          background-color: white;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
        }
        .pictureside {
          width: 400px;
          background-color: white;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .image-link {
            text-decoration: none;
            margin: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 150px;
            width: 150px;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("@/assets/images/white-logo.png");
            transition: 0.5s ease all;
            box-shadow: inset 0 0 20px #000000;
            &:hover {
              color: #fc6500;
              background-size: 110%;
            }
          }
        }
        .sub-mobile-nav {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          transform: translateZ(-10px);
          background-color: rgb(223, 223, 223);
          ul {
            opacity: 1;
            padding-top: 80px;
            width: 100%;
            height: 100%;
          }
          .mobile-nav-li {
            margin: 40px;
            border-bottom: 1px solid rgb(51, 51, 51);
            @media (max-width: 780px) {
              border-bottom: 1px solid transparent;
            }
          }
          .link {
            margin: 0px 20px 20px 0px;
            text-align: left;
            color: black !important;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.ul {
  transform: translateX(-20px);
}
.cart-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  max-width: 500px;
  height: 100lvh;
  background-color: #f6f4f2;
  top: 0;
  right: 0;
  z-index: 3;
  padding: 2rem;
  color: black;
  .separator {
    width: 100%;
    height: 2px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 25%;
    background-color: rgba(36, 35, 35, 0.2);
  }
  .separator1 {
    width: 100%;
    height: 1px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 25%;
    background-color: rgba(36, 35, 35, 0.2);
  }

  .cart-header {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .cart-info-shipping {
    display: flex;
  }
  .cart-body {
    display: flex;
    flex-direction: column;
    height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    .cart-product-row {
      flex: 1 1 0;
      display: flex;
      justify-content: space-between;
    }
  }
  .cart-footer {
    margin-bottom:2rem;
    display: flex;
    flex-direction: column;
  }
  .fa-solid {
    transition: 0.2s ease;
    &:hover {
      color: #fc6500 !important;
      cursor: pointer;
    }
  }
  .button-light {
    flex: 1;
    text-align: center;
    border-radius: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    background: white;
    color: black;
    border: 1px solid rgb(240, 240, 240);
    transition: 0.2s ease all;
    &:hover {
      background: rgb(240, 240, 240);
    }
  }
}
.li-active {
  color: purple;
}

.window-opacity {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  opacity: 50%;
  background-color: black;
}
#window-opacity {
  position: relative;
}
#window-opacity:after {
  content: "";
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  opacity: 50%;
  background-color: black;
}
.subNav-mode {
  z-index: 1 !important;
}

.hide-overflow {
  //overflow: hidden;
  // position: fixed;
}

.appear-li-enter-active,
.appear-li-leave-active {
  transition: 0.4s ease all;
}

.appear-li-enter-from,
.appear-li-leave-to {
  transform: translateX(-50%) !important;
}

.appear-li-enter-to,
.appear-li-leave-from {
  transform: translateX(0%) !important;
}
.dropright-mobile-nav-enter-active,
.dropright-mobile-nav-leave-active {
  transition: 0.4s ease all;
}

.dropright-mobile-nav-enter-from,
.dropright-mobile-nav-leave-to {
  transform: translateX(-100%) !important;
}

.dropright-mobile-nav-enter-to,
.dropright-mobile-nav-leave-from {
  transform: translateX(0%) !important;
}
.droprigh-mobile-sub-nav-enter-active,
.droprigh-mobile-sub-nav-leave-active {
  transition: 0.4s ease all;
  @media (max-width: 780px) {
    transition: 0s ease all;
  }
}

.droprigh-mobile-sub-nav-enter-from,
.droprigh-mobile-sub-nav-leave-to {
  transform: translateX(-100%) !important;
}

.droprigh-mobile-sub-nav-enter-to,
.droprigh-mobile-sub-nav-leave-from {
  transform: translateX(0%) !important;
}

.dropleft-cart-nav-enter-active,
.dropleft-cart-nav-leave-active {
  transition: 0.5s ease all;
}

.dropleft-cart-nav-enter-from,
.dropleft-cart-nav-leave-to {
  transform: translateX(+500px);
}

.dropleft-cart-nav-nav-enter-to,
.dropleft-cart-nav-nav-leave-from {
  transform: translateX(0);
}

.display-window-opacity-enter-active,
.display-window-opacity-leave-active {
  transition: 0.4s ease all;
}

.display-window-opacity-enter-from,
.display-window-opacity-leave-to {
  transform: opacity(50%);
}

.display-window-opacity-enter-to,
.display-window-opacity-leave-from {
  transform: opacity(0);
}
.display-window-opacity-fast-enter-active,
.display-window-opacity-fast-leave-active {
  transition: 0.1s ease all;
}

.display-window-opacity-fast-enter-from,
.display-window-opacity-fast-leave-to {
  transform: opacity(50%);
}

.display-window-opacity-fast-enter-to,
.display-window-opacity-fast-leave-from {
  transform: opacity(0);
}

.display-vet-sub-nav-enter-active,
.display-vet-sub-nav-leave-active {
  transition: 0s ease all;
}

.display-vet-sub-nav-enter-from,
.display-vet-sub-nav-leave-to {
  opacity: 1;
}

.display-vet-sub-nav-enter-to,
.display-vet-sub-nav-leave-from {
  opacity: 0;
}
.display-equ-sub-nav-enter-active,
.display-equ-sub-nav-leave-active {
  transition: 0s ease all;
}

.display-equ-sub-nav-enter-from,
.display-vet-sub-nav-leave-to {
  opacity: 1;
}

.display-equ-sub-nav-enter-to,
.display-equ-sub-nav-leave-from {
  opacity: 0;
}
.context {
  // context is there to save the status of actual position in nav (select category)
  .test-arrow {
    &:before {
      opacity: 1;
      left: -0.1em;
    }
    transform: translateX(0);
  }
}
.img-subcategory {
  opacity: 0;
}
.img-subcategori {
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 1px;
    height: 1px;
    width: 10%;
    background-color: black;
  }
}
.test-arrow {
  @media (min-width: 780px) {
    text-decoration: none;
    font-size: 1em;
    transition: all ease-in-out 200ms;
    transform: translateX(-0.4em);
    &:before {
      -webkit-font-smoothing: antialiased;
      text-rendering: auto;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      font: var(--fa-font-solid);
      //font-family: "Font Awesome Kit";
      content: "\f061";
      //content: '>';
      position: relative;
      left: -0.2em;
      transition: all ease-in-out 0.4s;
      opacity: 0;
    }
    &:hover {
      &:before {
        opacity: 1;
        left: -0.1em;
      }
      transform: translateX(0);
    }
  }
}

hr {
  background-color: gray !important;
}

.color-bck {
  color: black !important;
  &:after {
    background-color: black !important;
  }
}
.cart-icon::before {
  font-size: 1rem;
  content: attr(data-count);
  position: absolute;
  right: -0.4em;
  top: -0.5em;
  width: 1em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: transparent;
  color: black;
}
.centered {
  justify-content: center;
  align-items: center;
}
.brand-name{
  font-size: 1.5rem;
  @media(max-width:310px){
    font-size: 1rem;
  }
}

</style>
