<template>
  <div class="card" style="margin-left: 0.75rem;margin-right: 0.75rem;height: 100%;width: 100%;">
    <div
      v-if="produit.get_discount_percentage"
      style="
        position: absolute;
        z-index: 1;
        background-color: white;
        width: 50px;
        text-align: center;
        font-weight: bold;
      "
    >
      -{{ produit.get_discount_percentage * 100 }}%
    </div>
    <div>
      <router-link v-if="url" v-bind:to="url">
        <figure class="image image--interactive">
          <div v-if="produit.get_image" class="carousel-image" style="gap: 1rem;">
            <ImageLoader v-bind:url="produit.get_image" />
          </div>
        </figure>
      </router-link>
      <div class="product-header">
        <i
          v-if="isFavori(produit.unique_id) && !firstload"
          @click="unFav(produit)"
          class="fa-solid fa-heart heart-animation"
          style="color: red; cursor: pointer; margin: 10px; font-size: 20px"
        ></i>
        <i
          v-else
          @click="putFav(produit)"
          class="fa-regular fa-heart"
          style="cursor: pointer; margin: 10px; font-size: 20px; color: black"
        ></i>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: center;
        "
      >
        <router-link v-bind:to="url"
          ><h3 class="product-title">{{ produit.label }}</h3></router-link
        >
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <p v-if="!multiple" class="is-size-6 has-text-grey">
          <span v-if="!produit.get_discount_percentage">
            <strong> {{ parseFloat(produit.price).toFixed(2) }}€</strong>
          </span>
          <span v-else>
            <s>{{ parseFloat(produit.price).toFixed(2) }}€</s>
            <strong> {{ produit.get_final_price.toFixed(2) }}€</strong>
          </span>
        </p>
        <p v-else>
          <strong>{{ priceRange }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "bulma-toast";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "SimpleProduitBox",
  props: {
    produit: Object,
  },
  components: {},
  data() {
    return {
      favoris: [],
      url:
        this.produit.get_absolute_url +
        "?variant=" +
        encodeURIComponent(this.produit.unique_id),
      fav: false,
      final_price: this.produit.get_final_price,
      price: this.produit.price,
      multiple: false,
      priceRange: null,
      mobile: false,
      firstload: true,
    };
  },
  mounted() {
    this.firstload = false;
    if (this.produit.tailles.length > 1) {
      this.multiple = true;
      const min = this.produit.tailles.reduce(function (prev, curr) {
        return prev.get_final_price < curr.get_final_price ? prev : curr;
      });
      const max = this.produit.tailles.reduce(function (prev, curr) {
        return prev.get_final_price > curr.get_final_price ? prev : curr;
      });
      if (min.get_final_price == max.get_final_price) {
        this.priceRange = min.get_final_price + "€";
      } else {
        this.priceRange =
          min.get_final_price + "€ - " + max.get_final_price + "€";
      }
    }
    gsap.to(".card", {
      scrollTrigger: {
        trigger: ".card",
        toggleActions: "start none reverse none",
      },
      y: -30,
      duration: 0.5,
    });
  },
  methods: {
    getPriceRange() {
      const min = this.produit.tailles.reduce(function (prev, curr) {
        return prev.Cost < curr.Cost ? prev : curr;
      });
      const max = this.produit.tailles.reduce(function (prev, curr) {
        return prev.Cost > curr.Cost ? prev : curr;
      });
      this.priceRange = min + "€ - " + max + "€";
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "cartItems", "isFavori"]),
    isFavoris() {
      const res = this.produit.tailles.some((obj) => {
        return this.$store.state.favorites.some((favObj) => {
          return favObj.unique_id === obj.unique_id;
        });
      });
      return res;
    },
  },
  methods: {
    checkScreen() {
      this.windowsWidth = window.innerWidth;
      if (this.windowsWidth <= 950) {
        this.mobile = true;
        return;
      }
    },
    changeproduitactive(variant) {
      this.tailles = variant.tailles;
      this.thumbnail = variant.get_thumbnail;
      this.url =
        this.produit.get_absolute_url +
        "?variant=" +
        encodeURIComponent(variant.unique_id);
      this.unique_id = variant.unique_id;
      this.label = variant.label;
      this.final_price = variant.tailles[0].get_final_price;
      this.price = variant.tailles[0].price;
    },
    putFav(variant) {
      _paq.push(["trackEvent", "Favoris", "Fav Click", variant.unique_id]);
      if (this.isAuthenticated) {
        this.$store.commit("addToFavoris", variant.unique_id);
        this.$store.commit("addToFavorite", variant);
        toast({
          message: "Produit ajouté a vos favoris !",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 4000,
          position: "top-right",
        });
        axios
          .post("/api/v1/favoris/ajouter/", {
            produit: variant.unique_id,
          })
          .then((response) => {
          })
          .catch((error) => {
          });
      } else {
        this.$router.push("/my-favorites");
      }
    },
    unFav(variant) {
      if (this.isAuthenticated) {
        this.$store.state.favoris = this.$store.state.favoris.filter(
          (i) => i !== variant.unique_id
        );
        this.$store.state.favorites = this.$store.state.favorites.filter(
          (i) => i.unique_id !== variant.unique_id
        );
        this.$emit("removeFromFavorites", variant);
        localStorage.setItem(
          "favoris",
          JSON.stringify(this.$store.state.favoris)
        );
        this.fav = false;
        axios
          .post("/api/v1/favoris/supprimer/", {
            produit: variant.unique_id,
          })
          .then((response) => {
          })
          .catch((error) => {
          });
      }
    },
  },
  watch: {
    "this.$store.state.favoris": function (newVal) {
      this.favoris = newVal;
    },
  },
  created() {
    this.favoris = this.$store.state.favoris;
  },
  beforeMount() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreen);
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  box-shadow: none;
  background-color: transparent;
  .image {
    width: 100%;
    stroke: 1px solid black;
    object-fit: cover;

    &--interactive {
      position: relative;
      overflow: hidden;
      max-height: 80vh;
    }
  }

  figcaption {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 1;
    .figcaption-content {
      display: flex;
      background-color: grey;
      border-radius: 5px;
      align-items: center;
      width: 80%;
      justify-content: center;
      opacity: 1;
    }
  }

  @media (hover: hover) {
    & figcaption {
      transform: translateY(100%);
      transition: transform 500ms;
    }
    & .figcaption-content {
      opacity: 1;
      transition: opacity 500ms linear 2s;
    }

    & .carousel__multiple__next {
      opacity: 0;
      transition: transform 500ms;
    }
    & .carousel__multiple__prev {
      opacity: 0;
      transition: transform 500ms;
    }
    &:hover figcaption {
      transform: translateY(0%);
    }
    &:hover .carousel__multiple__next {
      opacity: 1;
    }
    &:hover .carousel__multiple__prev {
      opacity: 1;
    }
  }

  .thumbnail {
    display: flex;
    position: relative;
    height: 40px;
    width: 40px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 10%;
    border-color: none;
    border: 1px solid transparent;
    cursor: pointer;
    &--active {
      border: 1px solid black;
      cursor: not-allowed;
      pointer-events: none;
    }
    &:hover {
      border: 1px solid rgba(128, 128, 128, 0.2);
    }

    img {
      padding: 5px;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .variant-section {
    display: flex;
  }
  .size {
    margin: 7px;
    display: flex;
    height: 30px;
    width: 40px;
    justify-content: center;
    align-items: center;
    font-style: bold;
    font-size: 0.75rem;
    border: 1px solid transparent;
    background: transparent;
    cursor: pointer;
    &:hover {
      border: 1px solid rgb(41, 41, 41);
    }
  }
  .disable {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
  .product-card {
    display: flex;
    background: #f6f4f2;
  }
  .product-header {
    display: flex;
    justify-content: space-between;
  }
  .product-title {
    color: black;
    font-size: 1.5rem;
    text-decoration: none;
  }
  .half-star {
    &:before {
      -webkit-transform: translateY(+50%);
      -moz-transform: translateY(+50%);
      -ms-transform: translateY(+50%);
      transform: translateY(+50%);
      -webkit-font-smoothing: antialiased;
      text-rendering: auto;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      font: var(--fa-font-solid);
      content: "\f005";
      color: rgba(225, 225, 225, 0.8);
      z-index: -1;
      font-size: 0.8rem;
      position: absolute;
    }
  }
  .heart-animation {
    position: relative;
    animation: heart-pulse 0.5s;
    animation-fill-mode: backwards;
    animation-direction: reverse;

    &:after {
      -webkit-font-smoothing: antialiased;
      text-rendering: auto;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      content: "\f004";
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      animation: heart-pulse-before 0.5s;
      transition: opacity 0.25s ease-in;
    }
  }

  @keyframes heart-pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes heart-pulse-before {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    25% {
      opacity: 0.25;
      transform: scale(1.8);
    }
    50% {
      opacity: 0.6;
      transform: scale(1.8);
    }
    100% {
      opacity: 0.7;
      transform: scale(0);
    }
  }
  .carousel-image {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
      object-position: center;
    }
  }
}
</style>
