<template>
  <div class="test">
    <div class="summary">
      <div class="test">
        <ul>
          <li>
            <a href="#mainquestions"
              ><i
                class="fa-regular fa-circle-question"
                style="padding-left: 1rem; padding-right: 1rem"
              ></i
              >Principales Questions</a
            >
          </li>
          <li>
            <a href="#compte"
              ><i
                class="fa-solid fa-user"
                style="padding-left: 1rem; padding-right: 1rem"
              ></i
              >Mon comptes</a
            >
          </li>
          <li>
            <a href="#commandes"
              ><i
                class="fa-solid fa-bag-shopping"
                style="padding-left: 1rem; padding-right: 1rem"
              ></i
              >Commandes</a
            >
          </li>
          <li>
            <a href="#livraison"
              ><i
                class="fa-solid fa-truck-fast"
                style="padding-left: 1rem; padding-right: 1rem"
              ></i
              >Livraison</a
            >
          </li>
          <li>
            <a href="#retour"
              ><i
                class="fa-solid fa-arrows-rotate"
                style="padding-left: 1rem; padding-right: 1rem"
              ></i
              >Retours et remboursements</a
            >
          </li>
          <li>
            <a href="#produit"
              ><i
                class="fa-solid fa-paw"
                style="padding-left: 1rem; padding-right: 1rem"
              ></i
              >Produits</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FaqNav",
  data() {
    return {
    };
  },
  methods: {
    logout() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userid");
      this.$store.commit("removeToken");
      this.$router.push("/");
    },
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.summary {
  border: 1px solid grey;
  overflow: hidden;
  border-radius: 15px;
  display: flex;
  flex: 2 0 0;
  gap: 1rem;
  flex-direction: column;
  ul {
    display: flex;
    flex-direction: column;
  }
  a {
    border-bottom: 2px solid rgba(49, 49, 49, 0.6);
    font-size: 1rem;
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: inline-block;
    text-decoration: none;
    color: black;
    &:hover {
      font-weight: bold;
    }
  }
}
.logout-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
}
.active {
  background-color: rgb(235, 231, 231);
}
.test {
  height: 100%;
  li:last-child {
    a {
      border-bottom: 1px solid transparent;
    }
  }
}
.toggle {
  padding: 0.5rem;
  display: none;
}
</style>
