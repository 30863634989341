<template>
    <div v-if="isLoading" class="loading-screen">
      <div class="loader-pawn">
<i class="fa-solid fa-paw fa-bounce fa-2xl"></i>      </div>
    </div>
  </template>
  
  <script>
  export default {
    computed: {
      isLoading() {
        return this.$store.state.isLoading;
      },
    },
  };
  </script>
  
  <style>
  .loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader-pawn {
    font-size: 50px;
    color: #333; /* You can adjust the color as needed */
  }
  </style>