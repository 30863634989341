<template>
  <div class="header-about">
    <div class="intro">
      <div class="text">
        <h1>Améliorer leur bien-être</h1>
        <h2>
          Des équipements et des vêtements de qualité pour leurs activités
          extérieures
        </h2>
      </div>
    </div>
  </div>

  <div
    class="page-news"
    style="
      margin-top: 60px;
      margin-left: 1rem;
      margin-right: 1rem;
      margin: 0 auto;
    "
  >
    <h1 class="title" style="display: flex; justify-content: center">
      OUTSIDOG c'est quoi ?
    </h1>
    <div class="breadcrumb">
      <router-link to="/">ACCUEIL</router-link>
      <div
        style="display: flex"
        v-for="(route, index) in routes"
        v-bind:key="route"
      >
        <span>&nbsp;>&nbsp;</span>
        <router-link v-bind:to="'/' + route">
          <span v-bind:class="index == routes.length - 1 ? 'active' : ''">{{
            route.toUpperCase()
          }}</span>
        </router-link>
      </div>
    </div>

    <section class="flex-section full-bleed inverse" style="ba">
      <div style="flex: 1 0 0; max-height: 600px"></div>
      <div
        style="
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          justify-content: center;
          padding: 4rem 0rem;
        "
      >
        <h1 class="subtitle">
          OUTSIDOG c'est une histoire centrée autour des chiens
        </h1>
        <p>
          Notre inspiration pour créer OUTSIDOG vient naturellement de nos
          compagnons à quatre pattes, qui représentent une partie importante de
          nos vies. Nous sommes responsables de leur bien-être et il est
          important de leur donner en retour tout ce qu'ils nous apportent
          chaque jour. 
        </p>
        <p style="margin-top: 2rem;">C'est pourquoi nous avons décidé de leur fournir des
          équipements et des vêtements de qualité pour les activités extérieures
          qu'ils apprécient tant. Que ce soit pour explorer de nouveaux endroits
          et rencontrer de nouveaux amis dans le parc, pour une promenade
          quotidienne, une petite balade, une randonnée ou une course pour les
          plus sportifs, nos chiens sont heureux de passer du temps à
          l'extérieur.
        </p>
      </div>
    </section>

    <section class="flex-section" style="ba">
      <div style="flex: 1 0 0; max-height: 600px"></div>
      <div
        style="
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          justify-content: center;
          padding: 4rem 0rem;
        "
      >
        <h1 class="subtitle">Notre But 🚀</h1>
        <p class="">
          Le but d'OUTSIDOG est de prolonger les moments à l'extérieur en offrant à
          nos compagnons canins le confort dont ils ont besoin pour toutes leurs
          activités. Même s'il peut sembler contre-intuitif
          d'habiller un chien, cela peut offrir de nombreux avantages. En effet,
          nos chiens sont sensibles aux conditions environnementales comme nous.
        </p>
        <p style="margin-top: 2rem;">
          Ils ont besoin de protection contre le soleil, la chaleur, la pluie et le froid.
          En leur fournissant des barrières contre ces éléments, nous pouvons
          préserver leur santé, améliorer leur visibilité et les garder propres
          plus longtemps. Nous pensons qu'il est important de pouvoir fournir
          des équipements de qualité pour que nos compagnons à quatre pattes
          puissent profiter au maximum de leurs activités en plein air.
        </p>
      </div>
    </section>
    <section class="flex-section full-bleed inverse" style="ba">
      <div style="flex: 1 0 0; max-height: 600px"></div>
      <div
        class="photo-section"
        style="
          display: flex;
          flex: 1 0 0;
          flex-direction: row;
          justify-content: center;
          padding: 4rem 0rem;
          gap: 2rem;
        "
      >
        <div style="flex: 2 0 0">
          <h1 class="subtitle">Du style sans compromis ✨</h1>
          <p>
            Chez OUTSIDOG, notre objectif est de vous permettre de sortir votre
            chien sans aucun souci grâce à des équipements faciles à utiliser et
            solides.
          </p>
          <br>
          <p>
            En outre, nous souhaitons que nos produits apportent une touche de
            style aux vêtements et équipements de votre chien pour qu'ils
            reflètent au mieux sa personnalité et ses préférences.
          </p>
          <br>
        </div>
      </div>
    </section>
    <section class="flex-section" style="ba">
      <div style="flex: 1 0 0; max-height: 600px"></div>
      <div
        style="
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          justify-content: center;
          padding: 4rem 0rem;
        "
      >
        <h1 class="subtitle">N'oublions pas tous nos amis 🐶</h1>
        <p>
          Chez OUTSIDOG, nous sommes également conscients de l'importance de
          soutenir les amis à quatre pattes qui se trouvent en refuge. C'est
          pourquoi nous reversons 1 € à la SPA pour chaque commande passée.
        </p>
      </div>
    </section>
    <section class="flex-section" style="ba">
      <div style="flex: 1 0 0; max-height: 600px"></div>
      <div
        style="
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          justify-content: center;
          padding: 4rem 0rem;
          text-align: center;
        "
      >
        <h1 class="subtitle">Nous avons besoin de vous ! 📢</h1>
        <p>
          Nous sommes conscients que cette aventure ne peut être menée seul et
          nous sommes à l'écoute de vos suggestions pour améliorer nos produits
          et notre concept. Pour cela, n'hésitez pas à nous contacter par e-mail
          ou directement sur les réseaux sociaux, notamment Instagram.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  name: "AboutUsView",
  data() {
    return {
      path: "",
      routes: [],
    };
  },
  mounted() {
    this.path = this.$router.currentRoute;
    this.routes = this.path.path.split("/").filter(Boolean);
  },
  methods: {},

  computed: {},
};
</script>
<style lang="scss" scoped>
.page-news {
  width: 100%;
  padding: clamp(1.2rem, 4vw, 4rem);
  max-width: 1780px;
  font-size: 1.2rem;
}
.full-bleed {
  box-shadow: 0 0 0 100vmax var(--bg-color);
  clip-path: inset(0 -100vmax);
}
.inverse {
  --bg-color: hsl(300, 3%, 93%);
  background-color: var(--bg-color, #121212);
  color: black;
}
.breadcrumb {
  display: flex;
  font-size: 0.8rem;
  a {
    margin: 0;
    padding: 0;
    color: grey;
    .active {
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
.title {
  font-size: clamp(1.8rem, 5vw, 3rem);
}
.subtitle {
  font-size:clamp(1.7rem, 5vw, 2.5rem);
}
.header-about {
  padding-left: clamp(40px, 5vw, 90px);
  padding-bottom: 10rem;
  padding-top: 10rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("@/assets/images/blue-dog.png");
  color: white;
  h1 {
    font-size: clamp(30px, 5vw, 100px);
  }
  h2 {
    font-size: clamp(20px, 3vw, 40px);
  }
  @media (max-width: 1250px) {
    background-image: url("@/assets/images/blue-dog-short.png");
    align-items: left;
    justify-content: left;
    padding-top: 3rem;
    padding-bottom: 20rem;
  }
  .intro {
    display: flex;
    align-items: left;
    justify-content: left;
    margin: 0px auto;
    max-width: 1780px;
    .text {
      max-width: 750px;
    }
  }
}
.photo-section {
  @media (max-width: 900px) {
    img {
      display: none;
    }
  }
}
</style>
