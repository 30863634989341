<template>
  <div>
    <transition name="display-window-opacity">
      <div
        @click="[toggleWindowOpacity, acceptAll()]"
        v-show="!consentStored && cookiesBandeau"
        class="window-opacity"
      ></div>
    </transition>
    <transition name="display-window-opacity">
      <div
        @click="toggleWindowOpacity"
        v-show="cookiesConfig"
        class="window-opacity"
        style="z-index: 8 !important; background-color: rgb(255, 255, 255, 0.9)"
      ></div>
    </transition>
    <transition>
      <div v-show="!consentStored && cookiesBandeau" class="cookies-footer">
        <div class="cookies-text">
          <h1 class="cookies-title">Paramétrer mes cookies</h1>
          <div class="cookies-infos">
            <p>
              Nous utilisons sur notre site des
              <router-link
                to="/mentions-legales/#ml-cookie"
                class="link"
                style="color: black; text-decoration: underline"
                >cookies</router-link
              >
              destinées à assurer le bon fonctionnement du site, à personnaliser
              selon vos préférences son contenues et nos publiicités sur des
              sites tiers, et à réaliser des statistiques. En continuant sur le site vous accepter les cookies.
            </p>
          </div>
        </div>
        <div class="cookies-buttons">
          <div class="cookies-choices">
            <button class="button-light" @click="[(cookiesConfig = true)]">
              Paramétrer les cookies
            </button>
            <button class="button-dark" @click="[acceptAll(),(cookiesBandeau = false)]">
              Accepter les cookies
            </button>
          </div>
          <button
            href=""
            class="cookies-deny-all"
            @click="[denyAll(),(cookiesBandeau = false)]"
          >
            Continuer sans accepter
          </button>
        </div>
      </div>
    </transition>
    <transition name="display-modal">
      <div v-show="cookiesConfig" class="modal">
        <div class="modal-message" style="padding-top: 2rem;">
          <h1 class="title-cookies-config">Paramétrer mes cookies</h1>
          <div style="font-size: 0.8rem; margin-top: 1rem">
            <p>
              Sélectionnez votre choix pour chaque catégorie de cookies puis
              cliquez sur "Sauvegarder la sélection" pour confirmer. Vous pouvez
              modifier à tout moment votre choix depuis la page
              <router-link
                to="/mentions-legales/#ml-cookies"
                class="link"
                style="color: black; text-decoration: underline"
                >Politique de confidentialité.</router-link
              >
            </p>
          </div>
          <div>
            <button class="button-dark" @click="acceptAll()" style="margin-top: 1rem;">
              Accepter et continuer
            </button>
          </div>
          <h1 class="title-cookies-config">Sélectionnez vos préférences</h1>
          <div class="cookies-config-choices">
            <div class="cookies-config-choice disabled">
              <CollapsingItem :alignStart="true" style="flex: 9 0 0">
                <template #title>
                  <h2
                    style="
                      font-size: 1rem;
                      margin-right: 1rem;
                      font-weight: bold;
                    "
                  >
                    Cookies nécessaires
                  </h2>
                </template>
                <template #content>
                  <div style="text-align: start; font-size: 0.8rem">
                    <p>
                      Ce site utilise des cookies essentiels au fonctionnement technique de base. Ils incluent les cookies de session nécessaires pour une navigation fluide, le stockage temporaire des paniers et favoris, ainsi que des cookies indispensables au traitement sécurisé des paiements via Stripe.
                    </p>
                  </div>
                </template>
              </CollapsingItem>
              <div style="flex: 2 0 0">
                <label class="switch switch-disabled">
                  <input type="checkbox" checked disabled />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="cookies-config-choice">
              <CollapsingItem :alignStart="true" style="flex: 9 0 0">
                <template #title>
                  <h2
                    style="
                      font-size: 1rem;
                      margin-right: 1rem;
                      font-weight: bold;
                    "
                  >
                    Cookies de performances
                  </h2>
                </template>
                <template #content>
                  <div style="text-align: start; font-size: 0.8rem">
                    <p>
                      Nous utilisons des cookies de performances pour collecter des informations anonymes sur la manière dont vous utilisez notre site. Ces cookies nous aident à analyser et améliorer les performances du site, à comprendre comment les utilisateurs interagissent avec lui et à identifier les éventuels problèmes.
                    </p>
                  </div>
                </template>
              </CollapsingItem>
              <div style="flex: 2 0 0">
                <label class="switch">
                  <input type="checkbox" v-model="isPerfCookieConsentGiven"/>
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="config-button">
            <button
              class="button-light"
              @click="[denyAll(), (cookiesBandeau = false), (cookiesConfig = false)]"
            >
              Tout refuser
            </button>
            <button
              class="button-dark"
              @click="[saveConfig(), (cookiesBandeau = false), (cookiesConfig = false)]"
            >
              Sauvegarder la sélection
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CollapsingItem from "@/components/CollapsingItem";
export default {
  components: {
    CollapsingItem,
  },
  name: "CookiesConfig",
  data() {
    return {
      cookiesBandeau: true,
      cookiesConfig: false,
      isPerfCookieConsentGiven:false,
      consentStored: false, 
      cookieConsent: true, 
    };
  },
  methods: {
    acceptAll() {
      this.cookiesBandeau = false;
      this.cookiesConfig = false;
      _paq.push(["rememberCookieConsentGiven"]);
      _paq.push(['trackPageView']);
      localStorage.setItem('cookieConsent',this.cookieConsent);
    },
    denyAll(){
      _paq.push(['forgetCookieConsentGiven']);
      localStorage.setItem('cookieConsent', this.cookieConsent);
    },
    saveConfig(){
      if(this.isPerfCookieConsentGiven){
        _paq.push(["rememberCookieConsentGiven"]);
        _paq.push(['trackPageView']);
      }
      else{
        _paq.push(['forgetCookieConsentGiven']);
      }
      localStorage.setItem('cookieConsent', this.cookieConsent);
    },
    displayCookies() {
      this.cookiesConfig = true;
    },
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    checkConsentCookie() {
      const consentCookie = this.getCookie("mtm_cookie_consent"); // Assurez-vous que "_pk_id" est le bon nom de cookie
      
      if (consentCookie) {
        this.cookiesBandeau = false; // Masquer le bandeau de cookie s'il y a un consentement enregistré
      }
    },
  },
  mounted() {
    const storedConsent = localStorage.getItem('cookieConsent');
    if(storedConsent){
      this.consentStored = true
    }
    this.checkConsentCookie();    
  },
};
</script>

<style lang="scss" scoped>
.test1 {
  max-width: 500px;
  height: 0px;
  overflow: hidden;
}
.cookies-footer {
  display: flex;
  padding-right: 3rem;
  padding-left: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: space-between;
  --default-color: black;
  position: fixed;
  width: 100%;
  z-index: 5;
  background-color: rgb(240, 240, 240);
  align-items: center;
  bottom: 0px;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
  a {
    font-style: none;
  }
}
.window-opacity {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  opacity: 50%;
  background-color: black;
}
.display-window-opacity-enter-active,
.display-window-opacity-leave-active {
  transition: 0s ease all;
}

.display-window-opacity-enter-from,
.display-window-opacity-leave-to {
  transform: opacity(50%);
}

.display-window-opacity-enter-to,
.display-window-opacity-leave-from {
  transform: opacity(0);
}
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  .message {
    max-height: 80vh;
  }
  button{
    margin: 0px;
  }
}
.display-modal-enter-active,
.display-modal-leave-active {
  transition: 0s ease all;
}

.display-modal-enter-from,
.display-modal-leave-to {
  transform: opacity(0%);
}

.display-modal-enter-to,
.display-modal-leave-from {
  transform: opacity(100%);
}

.cookies-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  @media (max-width: 1250px) {
    width: 100%;
  }
}
.cookies-buttons {
  font-size: 1.2rem !important;
  width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 1250px) {
    width: 100%;
  }
  @media (max-width: 700px) {
    .button-light {
      margin-bottom: 0rem !important;
    }
  }
}
.cookies-choices {
  display: flex;
  gap: 2rem;
  width: 100%;
  @media (max-width: 700px) {
    flex-direction: column;
  }
}
.cookies-title {
  font-weight: bold;
  @media (max-width: 1250px) {
    text-align: center;
    margin-bottom: 1rem;
  }
}
.cookies-infos {
  font-size: 0.9rem;
}
.cookies-deny-all {
  color: black;
  text-decoration: underline;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 1rem;
  float: right;
}
.title-cookies-config {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 1rem;
}
.modal-message {
  text-align: center;
  border-radius: 4px;
  max-width: 750px;
  padding: 2rem;
  // height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  overflow-y: auto;
}
/* The switch - the box around the slider */
.switch {
  margin-left: 2rem;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 1.8em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.5em;
  width: 1.5em;
  left: 0.2em;
  bottom: 0.15em;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1d3346;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1d3346;
}

.switch-disabled {
  input:checked + .slider {
    background-color: #858585;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #87898a;
  }
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.6em);
  -ms-transform: translateX(1.6em);
  transform: translateX(1.6em);
}

/* Rounded sliders */
.slider.round {
  border-radius: 1.8em;
}

.slider.round:before {
  border-radius: 50%;
}
.cookies-config-choice {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.cookies-config-choices {
  width: 100%;
  font-size: 8px;
  i {
    cursor: pointer;
  }
}
.disabled {
  color: rgba(138, 138, 138, 0.753) !important;
}

.config-button {
  display: flex;
  gap: 2rem;
  width: 100%;
  @media (max-width: 700px) {
    flex-direction: column;
  }
}
</style>
