<template>
  <tr class="promo-code">
    <td>
      <span style="font-size: clamp(0.8rem, 4vw, 1.2rem);" class="tag is-success is-light is-large"
        >{{ initialCode.title
        }}<button
          class="delete"
          style="margin-left: 1rem"
          @click="removeCode(initialCode)"
        ></button
      ></span>
    </td>
    <td v-if="getReduction > 0">-{{ getReduction.toFixed(2) }}€</td>
  </tr>
</template>

<script>

export default {
  name: "CodePromo",
  props: {
    reduction: Number,
    initialCode: Object,
  },
  computed: {
    getReduction() {
      let totalPercentageReduction = 0;
      if(this.initialCode.all_products){
        // Réduction pour tous les produits
        totalPercentageReduction = this.$store.state.cart.items.reduce((acc, item) => {
              return acc + (item.final_price * (parseFloat(this.initialCode.percentage) / 100.0) * item.quantity);
            }, 0);
      }
      else{
        totalPercentageReduction = this.initialCode.tailles.reduce((acc, ) => {
          const exists = this.$store.state.cart.items.filter(
            (i) => i.id === curVal
          );
  
          if (exists.length) {
            return (acc +=
              exists[0].final_price *
              (parseFloat(this.initialCode.percentage) / 100.0) *
              exists[0].quantity);
          } else {
            return (acc += 0);
          }
        }, 0);
      }
      return this.initialCode.fixed + totalPercentageReduction;
    },
  },
  data() {
    return {
    };
  },
  methods: {
    updateCart() {
      localStorage.setItem("cart", JSON.stringify(this.$store.state.code));
    },
    removeCode(code) {
      this.$emit("removeCode", code);
      this.updateCart();
    },
  },
};
</script>

<style lang="scss" scoped>
.promo-code {
  display: flex;
  color: rgb(0, 179, 0);
  margin-top: 1rem;
  justify-content: space-between;
  align-items: center;
}
</style>
