<template>
  <div id="wrapper">
    <NavBar/>
    <NewsletterPopUp id="nl-popup"/>
    <CookiesConfig ref="cookies" />
    <div v-if="getAnoucementBanner" style="width: 100%;height:28px;"></div>
    <section class="main_section" style="min-height: 42vh; margin-top: 70px; width: 100%;
     max-width: 100%;">
      <router-view :key="$route.fullPath" />
      <LoadingScreen />
    </section>
    <footer class="footer">
      <div class="footer-info">
        <div class="box-info">
          <i class="fa-solid fa-box fa-4x"></i>
          <h2>Livraison offerte</h2>
          <p>A partir de 60€ la livraison est offerte</p>
          <span>
            <router-link
              to="/faq/#livraison"
              style="
                color: rgba(255, 255, 255, 0.7);
                text-decoration: underline;
              "
              >En savoir plus</router-link
            >
          </span>
        </div>
        <div class="box-info">
          <i class="fa-solid fa-credit-card fa-4x"></i>
          <h2>Paiement 100% sécurisé</h2>
          <p>Paiement via Stripe</p>
          <span>
            <router-link
              to="/faq/#commandes"
              style="
                color: rgba(255, 255, 255, 0.7);
                text-decoration: underline;
              "
              >En savoir plus</router-link
            >
          </span>
        </div>
        <div class="box-info">
          <i class="fa-solid fa-truck-fast fa-4x"></i>
          <h2>Suivi de votre commande</h2>
          <p>Un compte pour suivre vos commandes</p>
          <span>
            <router-link
              to="/faq/#mainquestions"
              style="
                color: rgba(255, 255, 255, 0.7);
                text-decoration: underline;
              "
              >En savoir plus</router-link
            >
          </span>
        </div>
      </div>
      <div
        style="height: 3px; width: 100%; border-radius: 20%; background: white"
      ></div>
      <div class="footer-nav">
        <div class="footer-nav-elem">
          <h1 style="font-size: clamp(40px, 4vw, 70px); color: white">
            OUTSIDOG
          </h1>
        </div>
        <div class="footer-nav-elem">
          <p style="font-size: clamp(18px, 2vw, 25px); color: white">
            Parce que chaque chien mérite le meilleur, notre boutique d'accessoires propose des produits soigneusement sélectionnés pour rendre la vie de votre fidèle compagnon encore plus spéciale.
          </p>
        </div>
      </div>
      <div class="footer-nav">
        <div class="footer-nav-elem">
          <div class="newsletter">
            <h1 style="font-size: 2rem; padding-bottom: 1rem">Newsletter</h1>
            <p style="font-size: 1.2rem; padding-bottom: 1rem">
              Abonnez-vous pour avoir les toutes dernières informations et obtenez -10% sur votre première commande*.
            </p>
            <form class="form" @submit.prevent="submitForm">
              <label>Email</label>
              <div class="field has-addons">
                <div class="control" style="width: 100%">
                  <input
                    type="email"
                    class="input"
                    v-model="email"
                    placeholder="Votre e-mail"
                  />
                </div>
                <div class="hp-field">
                  <label>Name</label>
                  <input type="text" v-model="name" />
                </div>
                <div class="control">
                  <button class="button" :class="{ 'isloading': newsletterIsLoading }">
                <span v-if="newsletterIsLoading" class="icon">
                  <i class="fa-solid fa-circle-notch fa-spin"></i>
                </span>
                <span v-else class="icon">
                      <i
                        class="fa-solid fa-arrow-right-long"
                        style="color: black; font-size: 1.2rem"
                      ></i>
                    </span>
              </button>
                  </div>
              </div>
            </form>
            <div style="text-align: left;font-size: 0.6rem; letter-spacing: 0.05em;margin-top: 1rem;">
              <p>*Offre valable uniquement pour des commandes d'un montant de 40€ minimum. Offre non cumuble avec les promotions.</p>
            </div>
          </div>
        </div>
        <div class="footer-nav-elem">
          <div class="nav-row">
            <h3>Support</h3>
            <ul>
              <li>
                <router-link
                  to="/faq/"
                  class="link"
                  >FAQ</router-link
                >
              </li>
              <li>
                <router-link
                  to="/faq/#retour"
                  class="link"
                  >Retour & Remboursement</router-link
                >
              </li>
              <li>
                <router-link
                  to="/faq/#livraison"
                  class="link"
                  >Suivi de livraison</router-link
                >
              </li>
              <li>
                <router-link
                  to="/contact"
                  class="link"
                  >Contact</router-link
                >
              </li>
            </ul>
          </div>
          <div class="nav-row">
            <h3>A propos</h3>
            <ul>
              <li>
                <router-link
                  to="/about-us"
                  class="link"
                  >Qui sommes-nous ?</router-link
                >
              </li>
              <li>
                <router-link
                  to="/news"
                  class="link"
                  >Nos actualités</router-link
                >
              </li>
              <li>
                <a
                href="https://www.instagram.com/outsidogparis/"
                  class="link"
                  >Instagram</a
                >
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@outsidog"
                  class="link"
                  >TikTok</a
                >
              </li>
            </ul>
          </div>
          <div class="nav-row">
            <h3>Liens utiles</h3>
            <ul>
              <li>
                <router-link
                  to="/conditions-generales-de-ventes"
                  class="link"
                  >Conditions générales de ventes</router-link
                >
              </li>
              <li>
                <router-link
                  to="/mentions-legales/#politique"
                  class="link"
                  >Politique de confidentialité</router-link
                >
              </li>
              <li>
                <router-link
                  to="/mentions-legales"
                  class="link"
                  >Mentions Légales</router-link
                >
              </li>
              <li>
                <button
                  @click="changeCookiesSettings"
                  class="link"
                  style="font-size: 100%;cursor: pointer;"
                >
                  Gestion des cookies
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p class="has-text-centered" style="padding: 1rem">
        Copyright © {{ new Date().getFullYear() }}
      </p>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar";
import CookiesConfig from "@/components/CookiesConfig";
import NewsletterPopUp from "@/components/NewsletterPopUp";
import { toast } from "bulma-toast";
import { mapGetters } from 'vuex';
import LoadingScreen from '@/components/LoadingScreen.vue';
export default {
  data() {
    return {
      showMobileMenu: false,
      cart: {
        items: [],
      },
      errors_sub: [],
      name: "",
      currentMeta: {},
      email:"",
      newsletterIsLoading:false,
    };
  },
  components: {
    NavBar,
    CookiesConfig,
    LoadingScreen,
    NewsletterPopUp
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        this.currentMeta = newVal.meta;
      },
    },
  },
  beforeCreate() {
    this.$store.commit("initializeStore");
    const token = this.$store.state.token;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
  mounted() {
    this.cart = this.$store.state.cart;
    const tl = gsap.timeline();
    let modal = document.getElementById("modal");
  },
  computed: {
    cartTotalLength() {
      let totalLength = 0;
      for (let i = 0; i < this.cart.items.length; i++) {
        totalLength += this.cart.items[i].quantity;
      }
      return totalLength;
    },
    ...mapGetters(['getAnoucementBanner']),
  },
  methods: {
    changeCookiesSettings() {
      this.$refs.cookies.displayCookies();
    },
    async submitForm() {
      const formData = {
        resource: {
          email: this.email.toLowerCase(),
        },
        hp: {
          hp: this.name,
        },
      };
      this.newsletterIsLoading = true
      await axios
      .post("/api/v1/sub-news-letter/", formData)
      .then((response) => {
        this.$store.commit("setSubscribed");

          toast({
            message: "Merci pour votre abonnement !",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 4000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          let message = "";
          if (error.response && error.response.status === 500) {
            for (const property in error.response.data) {
              message += `${property}: ${error.response.data[property]}`;
            }
          } else {
            message += "Quelque chose s'est mal passé. Veuillez ressayer.";
            this.errors_sub.push(
              "Quelque chose s'est mal passé. Veuillez ressayer"
              );
            }
            toast({
              message: message,
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 4000,
              position: "bottom-right",
            });
          });
          this.newsletterIsLoading = false
    },

  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "";
  font-family: "HelveticaNowText", "Helvetica", "Roboto", Arial;
}


html,
body {
  margin: 0px !important;
  padding: 0px !important;
  width: 100%;
  // height:100%;
  // position: relative;
}
form {
  .hp-field {
    display: none;
  }
}
.button-dark {
  cursor: pointer;
  border-radius: 15px;

  justify-content: center;
  display: flex;
  flex: 1;
  color: white;
  border-radius: none;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 1.2rem;
  background: rgb(34, 33, 33);
  font-size: 1rem;
  transition: 0.2s ease all;
  border: none;
  -webkit-tap-highlight-color: transparent;

  
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: rgb(34, 33, 33, 0.5);
    }
  }
  /* Styles de l'effet de clic pour les tablettes */
  @media only screen and (max-width: 1024px) {
    &:active {
      background: rgb(34, 33, 33, 0.5);
      /* Styles de l'effet de clic */
    }
  }
}

.button-light {
  border-radius: 15px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-radius: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 1.2rem;
  font-size: 1rem;
  background: white;
  color: black;
  border: 1px solid rgb(240, 240, 240);
  transition: 0.2s ease all;
  &:hover {
    background: rgb(240, 240, 240);
  }
}
.page-enter-active,
.page-leave-active {
  transition: opacity 0.5s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
@import "../node_modules/bulma";
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: "";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
// @keyframes lds-dual-ring {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

.is-loading-bar {
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  &.is-loading {
    height: 80px;
  }
}

.no-scroll {
  // position: fixed;
  // overflow: hidden;
}

.bck {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
.bckcolor {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #f6f4f2;
  z-index: -3;
}
.footer {
  background: rgb(47, 56, 61);
  color: white;
  margin: 0;
  padding: 0;
  .footer-info {
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: white;
    flex-wrap: wrap;
    .box-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 3rem;
      margin-top: 3rem;
      margin-left: 1rem;
      margin-right: 1rem;
      font-size: clamp(14px, 1vw, 30px);
      i {
        margin: 20px;
      }
      h2 {
        font-size: 1.5rem;
        margin: 15px;
      }
      router-link:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .footer-nav {
    display: flex;
    justify-content: space-between;
    padding: 3rem;
    color: white;
    @media (max-width: 1250px) {
      flex-direction: column;
    }
    .footer-nav-elem {
      flex: 1 1 0;
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding-bottom: 3rem;
      flex-wrap: wrap;
      .newsletter {
        display: flex;
        flex-direction: column;
        max-width: 500px;
      }
      .nav-row {
        font-size: clamp(14px, 1vw, 30px);
        h3 {
          font-size: 1.5rem;
          text-decoration: underline;
        }
        padding-right: 3rem;
        display: flex;
        flex-direction: column;
        li {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
    .link {
      border: none;
      background: none;
      color: white;
      position: relative;
      color: white;
      text-decoration: none;
      transition: 0.5s ease all;
      padding-bottom: 10px;
      margin-bottom: 40px;
      border-bottom: 1px solid transparent;

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 0%;
        background-color: white;
        transition: all ease-in-out 250ms;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }
}
.isloading {
  position: relative;
  pointer-events: none; /* Désactive les interactions avec le bouton pendant le chargement */
  &:before {
   content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
  }
}
.page{
  padding-top:150px;
}
#nl-popup{
  .window-opacity{
    z-index: 6;
    backdrop-filter: blur(100px);
  }
}
</style>
