import { createRouter, createWebHistory } from "vue-router";

import HomeView from "../views/HomeView.vue";
import ProduitView from "../views/ProduitView.vue";
import CategoryView from "../views/CategoryView.vue";
import SignUpView from "../views/SignUpView.vue";
import LogInView from "../views/LogInView.vue";
import AccountView from "../views/AccountView.vue";
import CheckoutView from "../views/CheckoutView.vue";
import SuccessView from "../views/SuccessView.vue";
import NotFoundView from "../views/NotFoundView.vue";
import ActivateView from "../views/ActivateView.vue";
import ResetPwdView from "../views/ResetPwdView.vue";
import ForgotPwdView from "../views/ForgotPwdView.vue";
import UnsubView from "../views/UnsubView.vue";
import AboutUsView from "../views/AboutUsView.vue";
import OrdersView from "../views/OrdersView.vue";
import PwdChangeView from "../views/PwdChangeView.vue";
import ContactView from "../views/ContactView.vue";
import CancelView from "../views/CancelView.vue";
import ReturnAndRefundView from "../views/ReturnAndRefundView.vue";
import SiteMapView from "../views/SiteMapView.vue";
import CgvView from "../views/CgvView.vue";
import MentionsLegales from "../views/MentionsLegales.vue";
import FaqView from "../views/FaqView.vue";
import NewsView from "../views/NewsView.vue";
import ArticleView from "../views/ArticleView.vue";
import FavoritesView from "../views/FavoritesView.vue";
import store from "@/store";
import axios from "axios";

const fetchArticles = () => {
  return axios
    .get("/api/v1/articles")
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return [];
    });
};

const routeGuard = async (to, from, next) => {
  const articles = await fetchArticles();
  const exists = articles.some(
    (article) => article.slug === to.params.article_slug
  );
  if (!exists) {
    next({ name: "notfound" });
  } else {
    next();
  }
};

const fetchCategories = () => {
  return axios
    .get("/api/v1/categories")
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return [];
    });
};

const routeGuardCategory = async (to, from, next) => {
  const categories = await fetchCategories();
  const exists = categories.some(
    (category) => category.slug === to.params.category_slug
  );
  if (!exists) {
    next({ name: "notfound" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
      transparent: true,
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      title: "About",
    },
  },
  {
    path: "/product/:product_slug",
    name: "produit",
    component: ProduitView,
    meta: {
      transparent: true,
      title: "Produit",
    },
  },
  {
    path: "/collection/:category_slug",
    name: "category",
    component: CategoryView,
    meta: {
      transparent: false,
      title: "Category",
    },
    beforeEnter: routeGuardCategory,
  },
  {
    path: "/news/:article_slug",
    name: "articles",
    component: ArticleView,
    meta: {
      transparent: false,
      title: "Articles",
    },
    beforeEnter: routeGuard,
  },
  {
    path: "/news",
    name: "news",
    component: NewsView,
    meta: {
      title: "Les actualités",
    },
  },
  {
    path: "/sign-up",
    name: "signup",
    component: SignUpView,
    meta: {
      title: "Sign-up",
    },
  },
  {
    path: "/log-in",
    name: "login",
    component: LogInView,
    meta: {
      title: "Log-In",
    },
  },
  {
    path: "/my-account",
    name: "my-account",
    component: AccountView,
    meta: {
      requiredLogin: true,
      title: "My Account",
    },
  },
  {
    path: "/my-favorites",
    name: "my-favorites",
    component: FavoritesView,
    meta: {
      requiredLogin: true,
      title: "Mes Favoris",
    },
  },
  {
    path: "/my-orders",
    name: "oders",
    component: OrdersView,
    meta: {
      requiredLogin: true,
      title: "Mes commandes",
    },
  },
  {
    path: "/my-return-and-refund",
    name: "returnandrefund",
    component: ReturnAndRefundView,
    meta: {
      requiredLogin: true,
      title: "Retour et Remboursement",
    },
  },
  {
    path: "/change-pwd",
    name: "pwdchange",
    component: PwdChangeView,
    meta: {
      requiredLogin: true,
      title: "Changement mot de passe",
    },
  },
  {
    path: "/cart/checkout",
    name: "checkout",
    component: CheckoutView,
    meta: {
      requiredLogin: true,
      title: "Checkout",
    },
  },
  {
    path: "/cart/success",
    name: "success",
    component: SuccessView,
    meta: {
      title: "Success",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: NotFoundView,
    meta: {
      title: "Page Not Found (404)",
    },
  },
  {
    path: "/activate/:uid/:token",
    name: "activate",
    component: ActivateView,
    meta: {
      title: "Activation",
    },
  },
  {
    path: "/forgot-pwd",
    name: "firgotpwd",
    component: ForgotPwdView,
    meta: {
      title: "Forgot password",
    },
  },
  {
    path: "/password/reset/confirm/:uid/:token",
    name: "resetpwd",
    component: ResetPwdView,
    meta: {
      title: "Reset password",
    },
  },
  {
    path: "/unsubNewsLetter/:uid/:token",
    name: "unsub",
    component: UnsubView,
    meta: {
      title: "Unsub newsletter",
    },
  },
  {
    path: "/about-us",
    name: "aboutus",
    component: AboutUsView,
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
    meta: {
      title: "Contact",
    },
  },
  {
    path: "/checkout/success",
    name: "success",
    component: SuccessView,
    meta: {
      title: "Success",
    },
  },
  {
    path: "/checkout/cancel",
    name: "cancel",
    component: CancelView,
    meta: {
      title: "Cancel",
    },
  },
  {
    path: "/sitemap",
    name: "sitemap",
    component: SiteMapView,
    meta: {
      title: "Site Map",
    },
  },
  {
    path: "/conditions-generales-de-ventes",
    name: "Conditions générales de ventes",
    component: CgvView,
    meta: {
      title: "Conditions générales de ventes",
    },
  },
  {
    path: "/mentions-legales",
    name: "Mentions Legales",
    component: MentionsLegales,
    meta: {
      title: "Mentions Legales",
    },
  },
  {
    path: "/faq",
    name: "Aide et FAQ",
    component: FaqView,
    meta: {
      title: "Aide & Faq",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // const navHeight = document.querySelector(".faqbody").offsetHeight;
      // document.documentElement.style.setProperty(
      //   "--scroll-padding",
      //   navHeight + 1 + "px"
      // );
      return {
        el: to.hash,
        behavior: "smooth",
        top: +70,
      };
    }
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          // create an Observer instance
          const resizeObserver = new ResizeObserver((entries) => {
            if (
              entries[0].target.clientHeight >=
              savedPosition.top + screen.height
            ) {
              resolve(savedPosition);
              resizeObserver.disconnect();
            }
          });
          // start observing a DOM node
          resizeObserver.observe(document.body);
        }, 200);
      });
    } else {
      return { top: 0 };
      // resolve({ top: 0 , behavior: 'smooth'});
    }
  },
});

router.beforeEach((to, from, next) => {
  store.commit("setIsLoading", false);
  if (to.path.startsWith('/product/')) {
    store.commit('incrementProductPageVisits');
  } else {
    store.commit('resetProductPageVisits');
  }
  if (
    to.matched.some((record) => record.meta.requiredLogin) &&
    !store.state.isAuthenticated
  ) {
    next({ name: "login", query: { to: to.path } });
  } else {
    next();
  }
  document.title = to.meta.title + " | Outsidog";
  useHead({
    title: to.meta.title + " | Outsidog",
    meta: [
      {
        name: `description`,
        content: to.meta.title,
      },
    ],
  });
});

router.afterEach((to, from) => {
  // Réinitialisez isLoading après le chargement de la nouvelle page
  store.commit("setIsLoading", false);
});

export default router;
