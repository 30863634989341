<template>
  <div>
    <transition name="display-window-opacity">
      <div
        v-show="alertModal"
        @click="toggleWindowOpacity"
        class="window-opacity"
      ></div>
    </transition>
    <transition name="display-modal">
      <div v-show="alertModal" class="modal" id="modal">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    clickoutside: Boolean,
  },
  data() {
    return {
      alertModal: false,
    };
  },
  mounted() {
    if (this.clickoutside) {
      document.addEventListener("click", this.closeModalOutside);
    }
  },
  beforeDestroy() {
    if (this.clickoutside) {
      document.removeEventListener("click", this.closeModalOutside);
    }
  },
  methods: {
    toggleWindowOpacity() {
      this.alertModal = false;
    },
    test() {
      this.alertModal = !this.alertModal;
    },
    closeModalOutside(event) {
      if (
        this.alertModal &&
        this.clickoutside &&
        event.target.id === "modal"
      ) {
        this.alertModal = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.window-opacity {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  opacity: 0.5;
  background-color: black;
}
.display-window-opacity-enter-active,
.display-window-opacity-leave-active {
  transition: 0.7s ease all;
}

.display-window-opacity-leave-to,
.display-window-opacity-leave-from {
  opacity: 0.0;
}
.modal {
  display: flex;
  // border-radius: 50px;
  justify-content: center;
  align-items: center;
  .message {
    max-height: 80vh;
  }
}
.display-modal-enter-active,
.display-modal-leave-active {
  transition: 0.5s ease all;
}

.display-modal-enter-from,
.display-modal-leave-to {
  transform: translateY(20px);
  opacity :0;
}

.display-modal-enter-to,
.display-modal-leave-from {
  transform: translateY(0px);
  opacity: 1;
}
</style>
