<template>
  <div class="address">
    <h1 class="subtitle" v-if="this.typeAddress == 'shipping'">
      Adresse de livraison
    </h1>
    <h1 class="subtitle" v-if="this.typeAddress == 'billing'">
      Adresse de facturation
    </h1>
    <hr />
    <form v-show="addressForm">
      <div class="address-form">
        <div class="row-field">
          <div class="field" style="flex: 1 0 0">
            <label>Prénom*</label>
            <div class="control">
              <input
                type="text"
                class="input"
                :class="{ error: v$.first_name.$error }"
                v-model="v$.first_name.$model"
                @blur="v$.first_name.$touch"
              />
              <p v-if="v$.first_name.$error" class="error-message">
                Veuillez renseigner un prénom.
              </p>
            </div>
          </div>
          <div class="field" style="flex: 1 0 0">
            <label>Nom*</label>
            <div class="control">
              <input
                type="text"
                class="input"
                :class="{ error: v$.last_name.$error }"
                v-model="v$.last_name.$model"
                @blur="v$.last_name.$touch"
              />
              <p v-if="v$.last_name.$error" class="error-message">
                Veuillez renseigner un nom.
              </p>
            </div>
          </div>
        </div>
        <div class="field" v-click-outside="doStuff">
          <label>Adresse*</label>
          <div class="control">
            <input
              @keydown="handleArrowUpDown"
              @input="changed()"
              @keyup.enter="handleEnter"
              id="id_address"
              type="text"
              class="input"
              :class="{ error: v$.address.$error }"
              v-model="v$.address.$model"
              @blur="v$.address.$touch"
            />
            <p v-if="v$.address.$error" class="error-message">
              Veuillez renseigner une adresse.
            </p>
            <div
              id="choices"
              v-show="listAdressFetched.length > 0"
              style="
                position: absolute;
                z-index: 4;
                background-color: white;
                width: 100%;
                border: 1px solid grey;
              "
            >
              <div style="display: flex; flex-direction: column">
                <div
                  v-for="(address, index) in listAdressFetched"
                  v-bind:key="address.id"
                  @click="selectAddress(address)"
                  :class="{ highlighted: index === selectedIndex }"
                >
                  <strong>{{ address[0] }}</strong>
                  <p>{{ address[1] }} &nbsp;&#45;&nbsp; {{ address[2] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-field">
          <div class="field" style="flex: 1 0 0">
            <label>Pays*</label>
            <div class="control">
              <div class="select" style="z-index: 1">
                <select
                  id="id_country"
                  :class="{ error: v$.country.$error }"
                  v-model="v$.country.$model"
                  @blur="v$.country.$touch"
                >
                  <option value=""></option>
                  <option value="FRANCE">🇫🇷 FRANCE</option>
                </select>
              </div>
              <p v-if="v$.country.$error" class="error-message">
                Veuillez renseigner un pays.
              </p>
            </div>
          </div>
          <div class="field" style="flex: 8 0 0">
            <label>Téléphone*</label>
            <div class="control">
              <input
                type="text"
                class="input"
                :class="{ error: v$.phone.$error }"
                v-model="v$.phone.$model"
                @blur="v$.phone.$touch"
              />
              <p v-if="v$.phone.$error" class="error-message">
                Veuillez renseigner un numéro de téléphone.
              </p>
            </div>
          </div>
        </div>
        <div class="row-field">
          <div class="field" style="flex: 1 0 0">
            <label>Code postal*</label>
            <div class="control">
              <input
                type="text"
                id="id_zipcode"
                class="input"
                :class="{ error: v$.zipcode.$error }"
                v-model="v$.zipcode.$model"
                @blur="v$.zipcode.$touch"
              />
              <p v-if="v$.zipcode.$error" class="error-message">
                Veuillez renseigner un code postal.
              </p>
            </div>
          </div>
          <div class="field" style="flex: 1 0 0">
            <label>Ville*</label>
            <div class="control">
              <input
                type="text"
                id="id_city"
                class="input"
                :class="{ error: v$.city.$error }"
                v-model="v$.city.$model"
                @blur="v$.city.$touch"
              />
              <p v-if="v$.city.$error" class="error-message">
                Veuillez renseigner une ville.
              </p>
            </div>
          </div>
        </div>
        <div v-if="!checkout" @click="setAddress" class="button-dark" :class="{ 'isloading': updateAddress }">
            <span v-if="updateAddress" class="icon">
              <i class="fa-solid fa-circle-notch fa-spin"></i>
            </span>
            <span v-else>
              Enregistrer l'adresse
            </span>
        </div>
      </div>
    </form>
    <div v-show="!addressForm">
      <div>
        <strong
          >{{ this.first_name.toUpperCase() }}
          {{ this.last_name.toUpperCase() }}</strong
        >
        <p>{{ this.address.toUpperCase() }}</p>
        <p>{{ this.zipcode }} - {{ this.city.toUpperCase() }}</p>
        <p>{{ this.country.toUpperCase() }}</p>
        <p>{{ this.phone }}</p>
      </div>
      <a
        @click="[(this.addressForm = true), (this.modifyForm = true), (this.continue = false)]"
        style="
          text-decoration: underline;
          color: black;
          margin-top: 2rem;
          margin-bottom: 2rem;
        "
        >Modifier</a
      >
    </div>
    <transition name="display-window-opacity">
      <div
        @click="warningAddress = false"
        v-show="warningAddress"
        class="window-opacity"
      ></div>
    </transition>
    <transition name="display-modal">
      <div v-show="warningAddress" class="modal">
        <div class="message">
          <h2 class="subtitle">Veuillez vérifier votre adresse.</h2>
          <hr />
          <p>
            Pour assurer un service de livraion nous vérifions votre adresse et
            elle semble être incohérente telle que ci dessous:
          </p>
          <div style="margin-top: 1rem; margin-bottom: 1rem">
            <strong
              >{{ this.address.toUpperCase() }} - {{ this.zipcode }} -
              {{ this.city.toUpperCase() }}</strong
            >
          </div>
          <p>Veuillez modifier l'adresse où choisir l'adresse conseillé.</p>
          <p>
            Vous pouvez aussi choisir de conserver votre adresse mais nous
            risquons de rencontrer des problèmes de livraison.
          </p>
          <hr />
          <div style="margin-top: 1rem; margin-bottom: 1rem">
            <p>Adresse conseillés:</p>
            <strong
              >{{ this.addressAdvised.toUpperCase() }} -
              {{ this.zipcodeAdvised }} -
              {{ this.cityAdvised.toUpperCase() }}</strong
            >
            <button @click="selectAdvised" class="button-light">
              Choisir cette adresse
            </button>
          </div>
          <div style="display: flex; width: 100%; justify-content: center">
            <button @click="continuer" class="button-light">Continuer</button>
            <button @click="[(warningAddress = false), (this.continue = false)]" class="button-dark">
              Modifier
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup(props) {
    const v$ = useVuelidate({ $scope: props.scope });
    return { v$ };
    return { v$: useVuelidate() };
  },
  name: "AddressForm",
  props: {
    typeAddress: String,
    checkout: Boolean,
    scope: String,
  },
  validations() {
    return {
      first_name: { required, $lazy: true },
      last_name: { required, $lazy: true },
      phone: {
        required,
        $lazy: true,
        valid: function (value) {
          return /^(0|\+33)[1-9]( *[0-9]{2}){4}$/.test(value);
        },
      },
      address: { required, $lazy: true },
      city: { required, $lazy: true },
      zipcode: { required, $lazy: true },
      country: { required, $lazy: true },
    };
  },
  data() {
    return {
      addressAdvised: "",
      zipcodeAdvised: "",
      cityAdvised: "",
      warningAddress: false,
      listAdressFetched: [],
      first_name: "",
      last_name: "",
      phone: "",
      address: "",
      zipcode: "",
      city: "",
      country: "",
      countries: ["France"],
      addressForm: true,
      modifyForm: false,
      continue: false,
      selectedIndex: -1,
      updateAddress: false,
    };
  },
  mounted() {
    this.getAddress();
    const clickListener = (e) => {
      if (!this.$el.contains(e.target)) {
        this.listAdressFetched = [];
      }
    };
  },
  created() {
    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.listAdressFetched = [];
      }
    });
  },
  // beforeMount(){
  //   window.addEventListener("click", this.clickListener)

  // },
  // beforeUnmount() {
  //   window.removeEventListener("click", this.clickListener);
  // },
  methods: {
    handleArrowUpDown(event) {
      if (event.keyCode === 38) {
        // Flèche haut
        if (this.selectedIndex > 0) {
          this.selectedIndex--;
        }
      } else if (event.keyCode === 40) {
        // Flèche bas
        if (this.selectedIndex < this.listAdressFetched.length - 1) {
          this.selectedIndex++;
        }
      }
    },
    handleEnter(event) {
      if (
        event.keyCode === 13 &&
        this.selectedIndex >= 0 &&
        this.selectedIndex < this.listAdressFetched.length
      ) {
        // Touche Entrée
        this.selectAddress(this.listAdressFetched[this.selectedIndex]);
      }
    },
    doStuff() {
      this.listAdressFetched = [];
    },
    async selectAdvised() {
      this.address = this.addressAdvised;
      this.zipcode = this.zipcodeAdvised;
      this.city = this.cityAdvised;
      this.warningAddress = false;
      if (!this.checkout) {
        this.sendAdress();
      }
    },
    async continuer() {
      if (!this.checkout) {
        this.sendAdress();
      }
      this.continue = true;
      this.warningAddress = false;
    },
    getSatus() {
      return this.continue;
    },
    async fetchAddr(addr) {
      var url =
        "https://api-adresse.data.gouv.fr/search/?q=" + addr + "&limit=5";
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      let data = await response.json();
      let res = [];
      for (var i = 0; i < data["features"].length; i++) {
        res.push([
          data["features"][i].properties.name,
          data["features"][i].properties.city,
          data["features"][i].properties.postcode,
        ]);
      }
      this.listAdressFetched = res;
      return res;
    },
    async checkAddr() {
      if(this.continue && this.checkout){
        return true
      }
      var addr = this.address + " " + this.zipcode + " " + this.city;
      var url =
        "https://api-adresse.data.gouv.fr/search/?q=" +
        addr +
        "&type=housenumber&autocomplete=1";
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        return true;
        throw new Error(message);
      }
      let data = await response.json();
      if (data.hasOwnProperty("features")) {
        if (data["features"][0]) {
          if (data["features"][0].properties.score < 0.9) {
            this.addressAdvised = data["features"][0].properties.name;
            this.zipcodeAdvised = data["features"][0].properties.postcode;
            this.cityAdvised = data["features"][0].properties.city;
            this.warningAddress = true;

            return false;
          }
        }
      }
      return true;
    },
    giveDataForm() {
      const dataForm = {
        civility: "MONSIEUR",
        last_name: this.last_name,
        first_name: this.first_name,
        phone_number: this.phone,
        address: this.address,
        country: this.country,
        additional_info: "this.shipping.additional_info",
        city: this.city,
        zipcode: this.zipcode,
      };
      return dataForm;
    },
    async saveOrderAddress() {
      var addr = this.address + " " + this.zipcode + " " + this.city;
      var url =
        "https://api-adresse.data.gouv.fr/search/?q=" +
        addr +
        "&type=housenumber&autocomplete=1";
      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      let data = await response.json();
      if (data["features"][0].properties.score < 0.9) {
        this.addressAdvised = data["features"][0].properties.name;
        this.zipcodeAdvised = data["features"][0].properties.postcode;
        this.cityAdvised = data["features"][0].properties.city;
        this.warningAddress = true;
      } else {
        const dataForm = {
          civility: "MONSIEUR",
          last_name: this.last_name,
          first_name: this.first_name,
          phone_number: this.phone,
          address: this.address,
          country: this.country,
          additional_info: "this.shipping.additional_info",
          city: this.city,
          zipcode: this.zipcode,
        };
        await axios
          .post("/api/v1/order/set-" + this.typeAddress + "-address/", data)
          .then((response) => {
            this.first_name = response.data.first_name;
            this.last_name = response.data.last_name;
            this.city = response.data.city;
            this.country = response.data.country;
            this.zipcode = response.data.zipcode;
            this.phone = response.data.phone_number;
            this.address = response.data.address;
            this.addressForm = false;
            this.modifyForm = true;
          })
          .catch((error) => {
          });
      }
    },
    async sendAdress() {
      const dataForm = {
        civility: "MONSIEUR",
        last_name: this.last_name,
        first_name: this.first_name,
        phone_number: this.phone,
        address: this.address,
        country: this.country,
        additional_info: "this.shipping.additional_info",
        city: this.city,
        zipcode: this.zipcode,
      };
      if (this.modifyForm) {
        this.putAddress(dataForm);
      } else {
        this.postAddress(dataForm);
      }
    },
    selectAddress(address) {
      this.listAdressFetched = [];
      this.address = address[0];
      this.city = address[1];
      this.zipcode = address[2];
    },
    async changed() {
      await this.fetchAddr(this.address);
    },
    async setAddress() {
      this.updateAddress=true
      const isFormCorrect = await this.v$.$validate();
      const error = document.getElementsByClassName("error-message");
      if (error.length > 0) {
        error[0].parentNode.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      if (isFormCorrect) {
        const promise = await new Promise((resolve, reject) => {
          resolve(this.checkAddr()), setTimeout(() => resolve(true), 100);
        });
        if (promise) {
          this.sendAdress();
        }
      }
      this.updateAddress=false
    },
    async postAddress(data) {
      await axios
        .post("/api/v1/set-" + this.typeAddress + "-address/", data)
        .then((response) => {
          this.first_name = response.data.first_name;
          this.last_name = response.data.last_name;
          this.city = response.data.city;
          this.country = response.data.country;
          this.zipcode = response.data.zipcode;
          this.phone = response.data.phone_number;
          this.address = response.data.address;
          this.addressForm = false;
          this.modifyForm = true;
        })
        .catch((error) => {
        });
    },
    async putAddress(data) {
      await axios
        .put("/api/v1/get-" + this.typeAddress + "-address/", data)
        .then((response) => {
          this.first_name = response.data.first_name;
          this.last_name = response.data.last_name;
          this.city = response.data.city;
          this.country = response.data.country;
          this.zipcode = response.data.zipcode;
          this.phone = response.data.phone_number;
          this.address = response.data.address;
          this.addressForm = false;
        })
        .catch((error) => {
        });
    },
    async getAddress() {
      await axios
        .get("/api/v1/get-" + this.typeAddress + "-address/")
        .then((response) => {
          this.first_name = response.data.first_name;
          this.last_name = response.data.last_name;
          this.city = response.data.city;
          this.country = response.data.country;
          this.zipcode = response.data.zipcode;
          this.phone = response.data.phone_number;
          this.address = response.data.address;
          this.addressForm = false;
          this.modifyForm = true;
        })
        .catch((error) => {
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.address {
  width: 100%;
}
.address-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .row-field {
    display: flex;
    @media (max-width: 450px) {
      flex-direction: column;
    }
  }
}
.button-light {
  flex: 1;
  text-align: center;
  border-radius: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background: white;
  color: black;
  border: 1px solid rgb(240, 240, 240);
  transition: 0.2s ease all;
  &:hover {
    background: rgb(240, 240, 240);
  }
}

.disable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.error {
  border: 1px solid red;
}
.error-message {
  color: red;
}
.checkboxerror {
  outline: 1px solid red !important;
}
.window-opacity {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  opacity: 50%;
  background-color: black;
}
.display-window-opacity-enter-active,
.display-window-opacity-leave-active {
  transition: 0.1s ease all;
}

.display-window-opacity-enter-from,
.display-window-opacity-leave-to {
  transform: opacity(50%);
}

.display-window-opacity-enter-to,
.display-window-opacity-leave-from {
  transform: opacity(0);
}
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  .message {
    width: 100%;
    max-width: 500px;
    padding: 1rem;
  }
}
.display-modal-enter-active,
.display-modal-leave-active {
  transition: 0.1s ease all;
}

.display-modal-enter-from,
.display-modal-leave-to {
  transform: opacity(0%);
}

.display-modal-enter-to,
.display-modal-leave-from {
  transform: opacity(100%);
}
.highlighted {
  border: 1px solid black;
}
</style>
