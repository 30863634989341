<template>
  <div class="page-unsub-news-letter" style="padding-top: 70px; margin: 0 auto">
    <form
      @submit.prevent="submitForm"
      style="border: 1px solid black; padding: 4rem; border-radius: 10px"
    >
      <div class="field">
        <h1 class="title">Quelques choses s'est mal passé ?</h1>
        <hr />
        <div class="control">
          <select
            type="text"
            class="input"
            v-model="v$.reason.$model"
            :class="{ error: v$.reason.$error }"
            @blur="v$.reason.$touch"
          >
            <option disabled value="">Veuillez sélectionner un choix</option>
            <option value="NOT_INTERESTED">Je ne suis pas intéressé.</option>
            <option value="SPAM">Je reçoit trop de messages</option>
            <option value="NO_MSG">Je ne reçoit pas les messages</option>
            <option value="NO_CONSENT">
              Je me suis jamais inscrit à cette newsletter.
            </option>
            <option value="OTHER">Autres raisons</option>
          </select>
          <p v-if="v$.reason.$error" class="error-message">
            Veuillez choisir un mode de paiement.
          </p>
        </div>
        <div class="hp-field">
          <label>Name</label>
          <input type="text" v-model="name" />
        </div>
      </div>
      <div class="field">
        <div class="control">
          <button class="button-dark"  :class="{ 'isloading': unsubIsLoading }">
                    <span v-if="unsubIsLoading">
                      <i class="fa-solid fa-circle-notch fa-spin"></i>
                    </span>
                    <span v-else>
                      Se désabonner
                    </span>
                  </button>
        </div>
      </div>
      <hr />
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "UnsubView",
  data() {
    return {
      reason: "",
      name: "",
      errors: [],
      unsubIsLoading:false,
    };
  },
  validations() {
    return {
      reason: { required, $lazy: true }, // Matches this.contact.email
    };
  },
  methods: {
    async submitForm() {
      this.errors = [];
      const formData = {
        resource: {
          reason_unsubscribe: this.reason,
        },
        hp: {
          hp: this.name,
        },
      };
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.unsubIsLoading = true
        await axios
          .post(
            "/api/v1/unsub-news-letter/" +
              this.$route.params.uid +
              "/" +
              this.$route.params.token +
              "/",
            formData
          )
          .then((response) => {
            this.unsubIsLoading = false
            this.$store.commit("setUnsubscribed");
            toast({
              message: "Vous n'êtes plus inscrit à la newsletter. ",
              type: "is-success is-light",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
            this.$router.push("/");
          })
          .catch((error) => {
            this.unsubIsLoading = false
            if (error.response && error.response.status === 500) {
              this.errors.push("Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard.");
            } else {
              for (const property in error.response.data) {
                this.errors.push(
                  `${property}: ${error.response.data[property]}`
                );
              }
            }
          });
          
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-unsub-news-letter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.error {
  border: 1px solid red;
}
.error-message {
  color: red;
}
</style>
