<template>
  <div
    v-if="article"
    class="header-about"
    :style="{ 'background-image': `url(${article.get_image})` }"
  ></div>

  <div
    v-if="article"
    class="page-news"
    style="
      margin-top: 60px;
      margin-left: 1rem;
      margin-right: 1rem;
      margin: 0 auto;
    "
  >
    <div class="breadcrumb">
      <router-link to="/">ACCUEIL</router-link>
      <div
        style="display: flex"
        v-for="(route, index) in routes"
        v-bind:key="route"
      >
        <span>&nbsp;>&nbsp;</span>
        <router-link v-if="route == 'news'" v-bind:to="'/news/'">
          <span v-bind:class="index == routes.length - 1 ? 'active' : ''">{{
            route.toUpperCase()
          }}</span>
        </router-link>
        <router-link v-else v-bind:to="'/news/' + route">
          <span v-bind:class="index == routes.length - 1 ? 'active' : ''">{{
            route.toUpperCase()
          }}</span>
        </router-link>
      </div>
    </div>
    <div v-if="article" class="article">
      <p class="" style="display: flex; justify-content: left">
        <span v-if="article.author">&nbsp;{{ article.author }}&nbsp;</span
        >&#8226;<span v-if="article.pub_date"
          >&nbsp;{{ formattedDate }}&nbsp;</span
        >&#8226;<span v-if="article.time_to_read"
          >&nbsp;{{ article.time_to_read }}&nbsp;</span
        >
        read
      </p>
      <h1
        class="title"
        style="display: flex; justify-content: center; margin-top: 2rem"
      >
        {{ article.title }}
      </h1>
      <div ref="content" class="content" v-html="article.content"></div>
      <hr />
    </div>
    <div class="other-articles">
      <div style="display: flex; justify-content: space-between">
        <h1 class="title">Articles récents</h1>
        <router-link to="/news" style="color: grey; font-style: italic"
          >Tout voir</router-link
        >
      </div>
      <section class="grid">
        <div
          v-for="article in articles"
          v-bind:key="article"
          class="article-card"
        >
          <img alt="" :src="article.get_image" />
          <div style="padding: 1rem 2rem">
            <h1 style="font-size: 2rem; font-weight: bold">
              {{ article.title }}
            </h1>
            <p class="card_preview-text">{{ article.preview }}</p>
            <router-link class="read-more" v-bind:to="'/news/' + article.slug">
              <span>Continuer à lire</span>
            </router-link>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { toast } from "bulma-toast";

import axios from "axios";
import DOMPurify from "dompurify";
export default {
  components: {},
  name: "ArticleView",
  props: {
  },
  data() {
    return {
      path: "",
      routes: [],
      slug: "",
      article: null,
      articles: [
      ],
    };
  },
  mounted() {
    this.path = this.$router.currentRoute;
    this.routes = this.path.path.split("/").filter(Boolean);
    this.fetchArticle();
    this.fetchLastArticle();
  },
  updated() {
    this.sanitizeContent();
  },
  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.$router.currentRoute);
        toast({
              message: "Lien copié dans le presse-papier !",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 4000,
              position: "top-center",
            });
      } catch (err) {
        toast({
              message: "Impossible de copier le lien. ",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 4000,
              position: "bottom-center",
            });
      }
    },
    fetchArticle() {
      this.$store.commit("setIsLoading", true);
      axios
        .get(`/api/v1/articles/${this.routes[this.routes.length - 1]}`)
        .then((response) => {
          this.article = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
        this.$store.commit("setIsLoading", false);
    },
    sanitizeContent() {
      const contentElement = this.$refs.content;
      if (contentElement) {
        // Filtrer et nettoyer le contenu HTML côté client avec une bibliothèque comme DOMPurify
        const sanitizedHTML = DOMPurify.sanitize(contentElement.innerHTML);
        contentElement.innerHTML = sanitizedHTML;
      }
    },
    fetchLastArticle() {
      axios
        .get(`/api/v1/lastarticles`)
        .then((response) => {
          this.articles = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sanitizeContent() {
      const contentElement = this.$refs.content;
      if (contentElement) {
        // Filtrer et nettoyer le contenu HTML côté client avec une bibliothèque comme DOMPurify
        const sanitizedHTML = DOMPurify.sanitize(contentElement.innerHTML);
        contentElement.innerHTML = sanitizedHTML;
      }
    },
  },

  computed: {
    formattedDate() {
      if (this.article) {
        const date = new Date(this.article.pub_date);
        const options = { day: "numeric", month: "short", year: "numeric" };
        return date.toLocaleDateString("fr-FR", options);
      }
    },
  },
  

  
};
</script>
<style lang="scss" scoped>
.page-news {
  width: 100%;
  padding: clamp(1.2rem, 4vw, 4rem);
  max-width: 1200px;
}
.full-bleed {
  box-shadow: 0 0 0 100vmax var(--bg-color);
  clip-path: inset(0 -100vmax);
}
.inverse {
  --bg-color: hsl(315, 22%, 93%);
  background-color: var(--bg-color, #121212);
  color: black;
}
.breadcrumb {
  display: flex;
  font-size: 0.8rem;
  a {
    margin: 0;
    padding: 0;
    color: grey;
    .active {
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

.header-about {
  max-height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("@/assets/images/blue-dog.png");
  color: white;
  @media (max-width:600px) {
    max-height: 300px;

  }
}
.tab {
  background-color: blue;
}
.share {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  i {
    cursor: pointer;
    &:hover {
      color: grey;
    }
  }
}
.article {
  border: 1px solid rgb(207, 207, 207);
  border-radius: 15px;
  padding: clamp(1.2rem, 4vw, 2rem);
}
.content {
  margin-bottom: 4rem;
}
.other-articles {
  margin-top: 4rem;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (min-width: 900px) and (max-width: 1250px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
  gap: 2rem;
}
.article-card {
  color: rgb(68, 68, 68);
  background-color: #f3f3f3;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  overflow: hidden;
  padding-bottom: 2rem;
  img {
    height: 250px;
    width: 100%;
    object-fit: cover;
  }
}
.flex-section {
  display: flex;
  gap: 4rem;
  padding: 4rem 0;
  @media (max-width: 750px) {
    flex-direction: column;
  }
}
.card_preview-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 2rem;
}
.read-more {
  color: white;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  font-family: inherit;
  font-size: 100%;
  background-color: hsl(0, 0%, 48%);
  cursor: pointer;
}
</style>
