<template>
  <div class="page-pwd-reset-confirm" style="margin: 0 auto;  max-width: 550px;padding-top: clamp(1.2rem, 4vw, 4rem);">
    <form @submit.prevent="submitForm">
      <div class="field">
        <label>Nouveau mot de passe *</label>
        <div class="control">
          <input
              type="password"
              class="input"
              :class="{ error: v$.password.$error }"
              v-model="password"
              @blur="v$.password.$touch"
              autocomplete="new-password"
            />
          <p v-if="v$.password.$error" class="error-message">
                  Veuillez saisir un mot de passe de plus de 8 caractères,
                  contenant au moins une majuscule, une minuscule, un chiffre et
                  un caractère spéciale parmis les suivants !@#$%^&*-_+=;:'",.?~`
            </p>
        </div>
      </div>
      <div class="field">
        <label>Confirmer le nouveau mot de passe *</label>
        <div class="control">
          <input
              type="password"
              class="input"
              :class="{ error: v$.password2.$error }"
              v-model="password2"
              @blur="v$.password2.$touch"
              autocomplete="new-password"
            />
          <p v-if="v$.password2.$error" class="error-message">
                  Le mot de passe et ça confirmation ne sont pas identique.
                </p>
        </div>
      </div>
      <div class="hp-field">
        <label>Name</label>
        <input type="text" v-model="name" />
      </div>
      <div class="notification is-danger" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
      </div>
      <div style="display: flex; gap:1rem;flex-wrap: wrap;align-items: center;">
        <div class="field">
            <a class="button-dark" href="https://outsidog.com/log-in" style="margin-top: 2rem;">Annuler</a>
        </div>
        <div class="field">
          <div class="control">
            <button class="button-dark">Enregistrer</button>
          </div>
        </div>
      </div>
      <hr />
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  sameAs,
} from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "ResetPwdView",
  data() {
    return {
      password: "",
      password2: "",
      errors: [],
      name: "",
    };
  },
  validations() {
    return {
      password: {
        required,
        $lazy: true,
        minLength: minLength(8),
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[!@#$%^&*-_+=;:'",.?~`]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
      }, // Matches this.contact.email
      password2: {
        required,
        $lazy: true,
        sameAsPassword: sameAs(this.password),
      },
    };
  },
  mounted() {
    document.title = "Log in | Outsidog";
    this.logout();
  },
  methods: {
    logout() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      this.$store.commit("removeToken");
    },
    async submitForm() {
      this.errors = [];
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {

        const formData = {
          uid: this.$route.params.uid,
          token: this.$route.params.token,
          new_password: this.password,
          re_new_password: this.password2,
          hp: this.name,
        };
        
        await axios
          .post("/api/v1/users/reset_password_confirm/", formData)
          .then((response) => {
            toast({
              message:
                "Votre mot de passe à été mis à jour ! Vous pouvez maintenant vous connecter. ",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 6000,
              position: "bottom-right",
            });
            this.$router.push("/log-in");
          })
          .catch((error) => {
            if (error.response && error.response.status === 500) {
              this.errors.push("Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard.");
            } else {
              for (const property in error.response.data) {
                if(property == 'token'){
                  this.errors.push(
                   `Lien expiré: Veuillez renouveler votre demande de "Mot de passe oublié".`
                 );
                }
               else{
                 this.errors.push(
                   `${property}: ${error.response.data[property]}`
                 );
               }
              }
            }
          });
      }    
    },
  },
};
</script>

<style></style>
