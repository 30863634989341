<template>
  <div class="container" v-bind:class="item.quantity <= 0 ? 'no' : ''">
    <div class="thumbnail">
      <router-link :to="item.url">
        <img v-bind:src="item.thumbnail" />
      </router-link>
    </div>
    <div class="product-info">
      <router-link @click="toggleCartNav" class="product-link" :to="item.url">{{
        item.name
      }}</router-link>
      <p>Taille {{ item.size }}</p>
      <p v-if="item.price === parseFloat(item.final_price).toFixed(2)">
        {{ item.price }}€
      </p>
      <p v-else>
        <s>{{ item.price }}€</s>
        <strong> {{ item.final_price.toFixed(2) }}€</strong>
      </p>
      <div
        v-if="item.quantity <= 0"
        style="position: relative; display: block; z-index: 2"
      >
        <p style="font-size: 0.8rem; color: red">
          Désolé cette article n'est plus disponible <br />dans les quantité
          souhaitée.
        </p>
        <i
          @click="removeFromCart(item)"
          class="fa-regular fa-trash-can"
          style="color: red; cursor: pointer"
          >MAJ</i
        >
      </div>
      <div v-else>
        <div>
          <p>
            Quantité:
            <a
              v-if="update"
              @click="decrementQuantity(item)"
              style="color: black"
              >-</a
            >
            {{ item.quantity }}
            <a
              v-if="update"
              @click="incrementQuantity(item)"
              style="color: black"
              v-show="item.quantity < item.stock"
              >+</a
            >
          </p>
        </div>
        <p>{{ getItemTotal(item).toFixed(2) }}€</p>
        <i
          v-if="update"
          @click="removeFromCart(item)"
          class="fa-regular fa-trash-can"
          style="color: grey; cursor: pointer"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CartNavItem",
  props: {
    item: Object,
    update: Boolean,
  },
  data() {
    return {
    };
  },
  methods: {
    getItemTotal(item) {
      return item.quantity * parseFloat(item.final_price).toFixed(2)
    },
    decrementQuantity(item) {
      item.quantity -= 1;
      if (item.quantity === 0) {
        this.$emit("removeFromCart", item);
      }
      this.updateCart();
      let total = this.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.final_price.toFixed(2) * curVal.quantity);
      }, 0);
      this.$store.commit("updateMatomoEcommerceCart");
      // _paq.push(["addEcommerceItem",item.id,item.name,item.category,item.final_price]);
      // _paq.push(['trackEcommerceCartUpdate', total]);
    },
    incrementQuantity(item) {
      item.quantity += 1;
      this.updateCart();
      let total = this.$store.state.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.final_price.toFixed(2) * curVal.quantity);
      }, 0);
      this.$store.commit("updateMatomoEcommerceCart");

      // _paq.push(["addEcommerceItem",item.id,item.name,item.category,item.final_price]);
      // _paq.push(['trackEcommerceCartUpdate', total]);
    },
    updateCart() {
      localStorage.setItem("cart", JSON.stringify(this.$store.state.cart));
    },
    removeFromCart(item) {
      this.$emit("removeFromCart", item);
      this.updateCart();
    },
    toggleCartNav() {
      this.$emit("toggleCartNav");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
}
.no {
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255, 0.5);
    z-index: 1;
    position: absolute;
    display: flex;
  }
}
.thumbnail {
  width: 150px;
  height: 150px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
  }
}
.product-info {
  font-size: 0.9rem;
  flex-direction: column;
  .product-link {
    color: black;
  }
  .product-link:link {
    text-decoration: none;
  }

  .product-link:visited {
    text-decoration: none;
  }

  .product-link:hover {
    text-decoration: underline;
  }

  .product-link:active {
    text-decoration: underline;
  }
}
</style>
