<template>
  <div class="page-product" style="margin: 0 auto">
    <div class="produit-container">
      <div class="gallery-section">
        <Gallery
          v-for="produit in produits"
          v-bind:key="produit.id"
          v-bind:images="this.images"
          v-on:refresh="refresh"
        />
      </div>
      <div
        class="pin-container"
        style="
          padding: clamp(10px, 3vw, 200px);
          background-color: white;
        "
      >
      <div class="pin-container info-section" style="padding-bottom: 0rem">
        <div v-if="!mobile" style="margin-bottom: 1rem;">
        <i class="fa-solid fa-arrow-left fa" @click="goBack" style="color:grey;text-decoration:outline;font-style:italic;cursor: pointer;"><span style="font-style: italic; margin-left: 1rem;font-size: 0.8rem;font-weight: normal;">Retour</span></i>
        
        </div>
          <div class="breadcrumb">
            <router-link to="/">HOME</router-link>
            <div
              style="display: flex"
              v-for="(category, index) in categories"
              v-bind:key="category"
            >
              <span>&nbsp;>&nbsp;</span>
              <router-link v-bind:to="'/collection/' + category">
                <span
                v-bind:class="index == categories.length - 1 ? 'active' : ''"
                >{{ category.toUpperCase() }}</span
                >
              </router-link>
            </div>
          </div>
          <div style="display:flex;width: 100%;justify-content: space-between;align-items: center;">
            <h1 style="font-weight: bold; font-size: 2rem">
              {{ produit.label }}
            </h1>
            <p style="float:right;font-weight: bold;" v-if="taille.get_discount_percentage">-{{ taille.get_discount_percentage * 100 }}%</p>
          </div>
          <h2 style="font-size: 1.2rem; padding-bottom: 1rem">
            <span
              v-if="
                parseFloat(taille.price).toFixed(2) ===
                parseFloat(taille.get_final_price).toFixed(2)
              "
              :class="{ grey: taille.quantity <= 0 }"
            >
              <span>{{ taille.price }} € </span>
            </span>
            <span v-else>
              <s>{{ taille.price }}</s>
              {{ taille.get_final_price.toFixed(2) }} €
            </span>
            <div
              v-if="taille.quantity <= 0"
              style="
                display: flex;
                flex-direction: column;
                padding-top: 1rem;
                padding-bottom: 1rem;
              "
            >
              <strong>Rupture de stock</strong>
            </div>
            <div v-else-if="taille.quantity < 6 && taille.quantity > 1" class="warning-stock">
              <strong
                >Seulement {{ taille.quantity }} exemplaires en stock !</strong
              >
            </div>
          </h2>

          <div v-if="variations">
            <div v-if="variations.length > 0">
              <h3>Existe aussi en coloris:</h3>
              <div class="variant-section">
                <div
                  v-for="variant in variations"
                  v-bind:key="variant.id"
                  class="thumbnail"
                  style="margin: 7px"
                >
                  <router-link
                    v-bind:to="
                      variant.get_absolute_url +
                      '?variant=' +
                      encodeURIComponent(variant.unique_id)
                    "
                  >
                    <img
                      class="variant-thumbnail"
                      v-bind:src="variant.get_thumbnail"
                      :class="{
                        'thumbnail--active':
                          produit.unique_id == variant.unique_id,
                      }"
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-if="tailles">
            <div v-if="tailles.length > 0">
              <div style="display: flex; justify-content: space-between">
                <h3>Existe aussi en taille:</h3>
                <button @click="toogleTaille" class="button-link" style="color:black !important;">
                  Guide des tailles
                </button>
              </div>
              <div style="display: flex; flex-wrap: wrap;">
                <div v-for="taile in tailles" v-bind:key="taile.id">
                  <button
                    v-if="taile.quantity > 0"
                    @click="changeSizeProduct(taile)"
                    style="border-color: black;"
                    class="size"
                    :class="{
                      'size--active': taile.unique_id == taille.unique_id,
                    }"
                  >
                    <strong style="color:black">{{ taile.size }}</strong>
                  </button>
                  <button
                    v-else
                    @click="changeSizeProduct(taile)"
                    class="size"
                    :class="{
                      'size--active': taile.unique_id == taille.unique_id,
                    }"
                  >
                    <strong style="color:rgb(175, 175, 175)">{{ taile.size }}</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="taille.quantity"
            @click="addToCart"
            class="button-dark"
            style=""
          >
            <span>Ajouter au panier</span>
          </div>
          <div v-else class="button-grey" @click="toogleAlertModal()">
            <span>Créer une alerte</span>
          </div>
          <div>
            <div>
              <p style="white-space: pre-line">
                {{ produit.description }}
              </p>
            </div>
          </div>
          <hr />
          <CollapsingItem :border="true" :collapsed="false">
            <template #title>
              <h1 style="font-size: 1.5rem">
                <i class="fa-solid fa-truck" style="margin-right: 1rem"></i
                >Délai de livraison
              </h1>
            </template>
            <template #content>
              <p>
                Nos délais de livraison varient de <strong>3 à 5 jours ouvrés</strong> en fonction
                de l'option de livraison choisie lors de la commande. Si vous souhaitez plus
                d'informations sur les conditions de lirvaison vous pouvez
                parcourir notre <a href="">faq</a> où les
                <a href="">Conditions générales de ventes</a>.
              </p>
            </template>
          </CollapsingItem>
          <CollapsingItem :border="true" ref="taille" id="taille" v-if="produit.chart_size">
            <template #title>
              <h1 style="font-size: 1.5rem;color:black !important;">
                <i class="fa-solid fa-ruler" style="margin-right: 1rem"></i
                >Guide des Tailles
              </h1>
            </template>
            <template #content v-if="produit.chart_size[0]">
              <table  class="table table-bordered">
                <thead>
                  <tr v-if="produit.chart_size[0]">
                    <th v-if="produit.chart_size[0].size" scope="col">Taille</th>
                    <th v-if="produit.chart_size[0].length" scope="col">Longueur du dos</th>
                    <th v-if="produit.chart_size[0].chest" scope="col">Tour de poitrail</th>
                    <th v-if="produit.chart_size[0].neck" scope="col">Tour de cou</th>
                    <th v-if="produit.chart_size[0].colar_width" scope="col">Largeur du collier</th>
                    <th v-if="produit.chart_size[0].colar_length" scope="col">Longueur du collier</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="size in produit.chart_size" v-bind:key="size">
                    <td v-if="size.size">
                      <strong>{{ size.size  || '-'}}</strong>
                    </td>
                    <td v-if="size.length">{{ size.length  || '-'}} cm</td>
                    <td v-if="size.chest">{{ size.chest || '-' }} cm</td>
                    <td  v-if="size.neck" >{{ size.neck  || '-'}} cm</td>
                    <td  v-if="size.colar_width" >{{ size.colar_width  || '-'}} cm</td>
                    <td  v-if="size.colar_length" >{{ size.colar_length  || '-'}} cm</td>
                  </tr>
                </tbody>
              </table>
              <img class="chart-size" src="@/assets/images/chart_size.png" />
              <p style="font-size: 0.8rem;">Pour les harnais où vêtements réglables, la taille de poitrine recommandée sera d'environ 5 cm inférieure à la taille du produit dans le tableau des tailles, ce qui pourrait fournir un espace pour le réglage. 
              </p> 
              <p style="font-size: 0.8rem;">Si la poitrine de votre chien s'approche de la taille maximale de la poitrine dans le tableau, vous pouvez choisir la taille au-dessus. </p>
            </template>
            <template #content v-else>
              Taille Unique
            </template>
          </CollapsingItem>
          <CollapsingItem :border="true" v-if="produit.maintain">
            <template #title>
              <h1 style="font-size: 1.5rem">
                <i class="fa-solid fa-heart" style="margin-right: 1rem"></i
                >Entretien Produit
              </h1>
            </template>
            <template #content>
              <p>
                {{produit.maintain}}
              </p>
            </template>
          </CollapsingItem>
          <CollapsingItem :border="true" v-if="produit.composition">
            <template #title>
              <h1 style="font-size: 1.5rem">
                <i class="fa-solid fa-feather" style="margin-right: 1rem"></i
                >Composition Produit
              </h1>
            </template>
            <template #content>
              <p>
                {{produit.composition}}
              </p>
            </template>
          </CollapsingItem>
        </div>
      </div>
    </div>
    <div
      class="column"
      style="padding: clamp(4px, 3vw, 200px); background: white"
    >
      <div class="column is-12">
        <div class="separator"></div>
        <div v-show="produit.total_avis" >
          <h1 class="title">
            Avis ( {{ produit.total_avis }})
          </h1>
          <div class="subtitle">
            <star-rating
              :rating="produit.average_avis"
              read-only
              :round-start-rating="false"
              :star-size="40"
              :border-width="2"
              :star-points="[
                381.2, 150.3, 524.9, 171.5, 529.3, 172.4, 537.3, 176, 543.9,
                181.7, 548.9, 188.9, 550.6, 193.1, 551.7, 197.5, 552, 206.3, 550,
                214.8, 545.7, 222.5, 542.7, 225.9, 438.5, 328.1, 463.1, 474.7,
                463.5, 479.2, 462.5, 488, 459.2, 496.1, 453.7, 503.1, 450.2, 506,
                446.3, 508.4, 438, 511.4, 429.3, 511.9, 420.6, 510.1, 416.5,
                508.3, 288.1, 439.8, 159.8, 508.3, 155.7, 510.1, 147, 511.9,
                138.2, 511.4, 129.9, 508.4, 126, 506, 122.5, 503.1, 117, 496.1,
                113.7, 488, 112.7, 479.2, 113.2, 474.7, 137.8, 328.1, 33.6, 225.9,
                30.6, 222.5, 26.3, 214.8, 24.3, 206.3, 24.6, 197.5, 25.7, 193.1,
                27.4, 188.9, 32.3, 181.7, 39, 176, 47, 172.4, 51.4, 171.5, 195,
                150.3, 259.4, 18, 261.7, 14, 267.6, 7.4, 275.1, 2.8, 283.6, 0.3,
                288.1, -0, 292.7, 0.3, 301.2, 2.8, 308.7, 7.4, 314.6, 14, 316.9,
                18, 381.2, 150.3, 381.2, 150.3,
              ]"
            ></star-rating>
          </div>
        </div>
        <div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: end;
            "
          >
            <button
              id="write-review"
              class="button-light"
              style="font-size: 1rem; max-width: 300px"
            >
              Ecrire un avis
            </button>
          </div>
          <div class="test">
            <h2 class="subtitle">Poster votre avis</h2>
            <form @submit.prevent="postReview">
              <div class="field">
                <label>Title</label>
                <div class="control">
                  <input type="text" class="input" v-model="title" />
                </div>
              </div>
              <div class="field">
                <label>Note</label>
                <star-rating
                  v-model="note"
                  @update:rating="setRating"
                  :border-width="2"
                  :star-size="40"
                  :star-points="[
                    381.2, 150.3, 524.9, 171.5, 529.3, 172.4, 537.3, 176, 543.9,
                    181.7, 548.9, 188.9, 550.6, 193.1, 551.7, 197.5, 552, 206.3,
                    550, 214.8, 545.7, 222.5, 542.7, 225.9, 438.5, 328.1, 463.1,
                    474.7, 463.5, 479.2, 462.5, 488, 459.2, 496.1, 453.7, 503.1,
                    450.2, 506, 446.3, 508.4, 438, 511.4, 429.3, 511.9, 420.6,
                    510.1, 416.5, 508.3, 288.1, 439.8, 159.8, 508.3, 155.7,
                    510.1, 147, 511.9, 138.2, 511.4, 129.9, 508.4, 126, 506,
                    122.5, 503.1, 117, 496.1, 113.7, 488, 112.7, 479.2, 113.2,
                    474.7, 137.8, 328.1, 33.6, 225.9, 30.6, 222.5, 26.3, 214.8,
                    24.3, 206.3, 24.6, 197.5, 25.7, 193.1, 27.4, 188.9, 32.3,
                    181.7, 39, 176, 47, 172.4, 51.4, 171.5, 195, 150.3, 259.4,
                    18, 261.7, 14, 267.6, 7.4, 275.1, 2.8, 283.6, 0.3, 288.1,
                    -0, 292.7, 0.3, 301.2, 2.8, 308.7, 7.4, 314.6, 14, 316.9,
                    18, 381.2, 150.3, 381.2, 150.3,
                  ]"
                ></star-rating>
              </div>
              <div class="field">
                <label>Content</label>
                <div class="control">
                  <textarea
                    type="text"
                    class="textarea"
                    v-model="content"
                  ></textarea>
                </div>
              </div>
              <div class="notification is-danger" v-if="errors_review.length">
                <p v-for="error in errors_review" v-bind:key="error">
                  {{ error }}
                </p>
              </div>
              <div class="field">
                <div class="control">
                  <button class="button-dark">Poster votre avis</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="separator"></div>
        <div style="display: flex; justify-content: end">
          <div style="float: right; margin: 1.5rem; display: block">
            <label for="filtres">Filtrer par:</label>
            <div class="control">
              <!-- add z-index to overide defaut z-index of select (bulma..) -->
              <div class="select" style="z-index: 1">
                <select name="filtres" id="filtres" v-model="filter">
                  <option value="note" @click="refreshReviews">
                    Moins bonne note
                  </option>
                  <option value="-note" @click="refreshReviews">
                    Meilleures note
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="" style="">
          <div>
            <ReviewItem
              ref="myRef"
              v-for="avi in avis"
              v-bind:key="avi.id"
              v-bind:review="avi"
            />
          </div>
          <div style="display: flex; justify-content: center; width: 100%">
            <div v-show="loading">
              <button
                class="button-light"
                style="width: 400px"
                @click="loadNext()"
              >
                Load more
              </button>
            </div>
        
          </div>
      
          <template v-if="showPrevButton"> </template>
          <template v-if="showNextButton"> </template>
        </div>
      </div>
    </div>
    <Modal ref="modalalert" :clickoutside="true">
      <div class="message">
        <h2 class="subtitle">
          Créer une alerte<i
            @click="toogleAlertModal()"
            class="fa-solid fa-x"
            style="float: right; cursor: pointer"
          ></i>
        </h2>
        <hr />
        <p>
          Si le produit que vous souhaitez n'est pas en stock, vous pouvez
          renseigner votre e-mail pour être informé(e) dès qu'il sera à nouveau
          disponible.
        </p>
        <div style="margin-top: 1rem; margin-bottom: 1rem"></div>
        <form action="">
          <div class="field" style="flex: 1 0 0">
          </div>
          <div class="field">
            <label>E-mail*</label>
            <div class="control">
              <input
                type="email"
                class="input"
                :class="{ error: v$.email.$error }"
                v-model="v$.email.$model"
                @blur="v$.email.$touch"
              />
              <p v-if="v$.email.$error" class="error-message">
                Veuillez renseigner un email.
              </p>
            </div>
            <div class="hp-field">
              <label>Name</label>
              <input type="text" v-model="name" />
            </div>
          </div>
        </form>
        <button
          @click="[toogleAlertModal(), createAlert()]"
          class="button-dark"
        >
          M'alerter
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
import router from "../router";
import ReviewItem from "@/components/ReviewItem";
import StarRating from "vue-star-rating";
import Gallery from "@/components/Gallery";
import CollapsingItem from "@/components/CollapsingItem";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { mapGetters } from "vuex";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "ProduitView",
  components: {
    ReviewItem,
    StarRating,
    Gallery,
    CollapsingItem,
  },
  validations() {
    return {
      email: { required, email, $lazy: true },
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "cartItems", "isFavori"]),
  },
  data() {
    return {
      mobile:null,
      hasScroll: false,
      alertTaille: null,
      subnewletter: null,
      email: null,
      produits: [],
      name: "",
      variations : [],
      tailles : [],
      //isAuthenticated : false,
      produit: {
        id: null,
        name: null,
        thumbnail: null,
        get_absolute_url: null,
        variations: [],
        unique_id: null,
        description: null,
        get_thumbnail: null,
        tailles: [],
        images: [],
        get_image : null,
        total_avis: null,
        average_avis: null,
        main_category: null,
      },
      taille: {
        unique_id: null,
        label: null,
        price: null,
        get_final_price: null,
        stock: null,
      },
      final_price: null,
      price: null,
      quantity: 1,
      errors_review: [],
      title: "",
      content: "",
      note: null,
      currentPage: 1,
      avis: [],
      totalavis: 0,
      showNextButton: false,
      showPrevButton: false,
      filter: "note",
      categories: [],
      drift: null,
      zoomI: true,
      drift1: null,
      zoomI1: true,
      alertModal: false,
      loading: false,
    };
  },
  updated() {

  },
  beforeMount() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreen);
  },
  mounted() {
    
    //you must call trackPageView when tracking an Ecommerce view
    
    if (true) {
      var tl = gsap.timeline({ paused: true });
      tl.to(".test", { height: "auto", duration: 0.4 }).reverse();
      let filter_section = document.getElementById("write-review");
      filter_section.addEventListener("click", function () {
        tl.reversed(!tl.reversed());
      });
    }
    
    this.getProduit();
    
    //set the commerce view
    // _paq.push(['trackPageView']);
  },
  created() {
    if (!this.$refs.myRef) {
    }

    this.$nextTick(() => {
      if (this.$refs.myRef) {
        this.$emit("refresh");
      }
    });
  },
  computed: {

  },
  methods: {
    toogleAlertModal() {
      this.$refs.modalalert.test();
    },
    toogleTaille() {
      this.$refs.taille.open();
      const element = document.getElementById("taille");
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
    refresh() {
      ScrollTrigger.refresh(true);
    },
    toggleWindowOpacity() {
      this.alertModal = false;
    },
    zoom() {
      this.drift.enable();
      this.zoomI = !this.zoomI;
      if (this.zoomI) {
        this.drift.disable();
      }
    },
    zoomDisable() {
      if (!this.zoomI) {
        this.zoomI = !this.zoomI;
      }
      this.drift.disable();
    },
    zoom1() {
      this.drift1.enable();
      this.zoomI1 = !this.zoomI1;
      if (this.zoomI1) {
        this.drift1.disable();
      }
    },
    zoomDisable1() {
      if (!this.zoomI1) {
        this.zoomI1 = !this.zoomI1;
      }
      this.drift1.disable();
    },
    changeSizeProduct(taille) {
      this.taille = taille;
      this.$router.push({
        path: this.$route.path,
        query: { variant: this.taille.unique_id }
      });


    },
    setRating(note) {
      this.note = note;
    },
    loadNext() {
      this.currentPage += 1;
      this.getReviews();
    },
    loadPrev() {
      this.currentPage -= 1;
      this.getReviews();
    },
    refreshReviews() {
      this.avis = [];
      this.currentPage = 1;
      this.getReviews();
    },
    async getProduit() {
      const unique_id = this.$route.query.variant;

      await axios
        .get(`/api/v1/produit/id/${unique_id}`)
        .then((response) => {
          this.produit = response.data;
          const newImage = { get_image: this.produit.get_image };
          this.images = this.produit.images;
          // Utilisez la méthode unshift() pour ajouter le nouvel objet au début de la liste
          this.images.unshift(newImage);
          this.produits = [this.produit];
          document.title = this.produit.label + " | Outsidog";
          (this.final_price = this.produit.get_final_price),
            (this.price = this.produit.price),
          this.variations = this.produit.variations;
          const groupby = this.variations.reduce(function (rv, x) {
            (rv[x["color"]] = rv[x["color"]] || []).push(x);
            return rv;
          }, {});
          const sizeOrder = ["130 cm","150 cm","200 cm","XXS", "XS", "S", "M", "L", "XL", "XXL"];
          this.tailles = this.produit.tailles;
          this.tailles.sort((a, b) => {
            const indexA = sizeOrder.indexOf(a.size);
            const indexB = sizeOrder.indexOf(b.size);
            
            if (indexA === -1) {
              return 1; // Move items with unknown sizes to the end
            }
            if (indexB === -1) {
              return -1; // Move items with unknown sizes to the end
            }
            
            return indexA - indexB;
          });
          this.tailles.slice().reverse();

          this.taille = this.produit;
          this.categories = this.produit.main_category
            .split("/")
            .filter(Boolean);
          _paq.push(['setEcommerceView',
              this.taille.unique_id, // Product SKU is not applicable for a category view.
              this.produit.name, // Product name is not applicable for a category view.
              this.lastCategory || this.categories, // Product category, or array of up to 5 categories
              this.produit.get_final_price, // Product category, or array of up to 5 categories
          ]);

          this.getReviews();
        })
        .catch((error) => {
          toast({
            message: "Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard.",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 4000,
            position: "top-center",
          });
        });
    },
    sortArrays(variations) {
      const array = variations.flatMap((x) => x.pressInformation);
      return array.sort((a, b) => a.id - b.id);
    },
    addToCart() {
      if (isNaN(this.quantity) || this.quantity < 1) {
        this.quantity = 1;
      }
      console.log(this.lastCategory)
      if (this.produit.unique_id) {
        const item = {
          id: this.taille.unique_id,
          name: this.produit.label,
          quantity: this.quantity,
          category: this.lastCategory || this.produit.main_category.split("/").filter(Boolean),
          size: this.taille.size,
          url:
            this.taille.get_absolute_url + "?variant=" + this.taille.unique_id,
          titre: this.produit.name,
          price: this.taille.price,
          final_price: this.taille.get_final_price,
          discount_type: null,
          thumbnail: this.produit.get_thumbnail,
          stock: this.taille.quantity,
          valid: true,
        };

        this.$store.commit("addToCart", item);

      } else {
        toast({
          message: "Sélectionner une taille",
          type: "is-danger",
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: "top-center",
        });
      }
    },
    async postReview() {
      this.errors_review = [];
      if (!this.$store.state.isAuthenticated) {
        this.errors_review.push("Vous devez être connecté pour publier un avis");
      }
      if (this.title === "") {
        this.errors_review.push("Le titre est manquant");
      }
      if (this.content === "") {
        this.errors_review.push("Le contenu de l'avis ne peut pas être vide");
      }
      if (this.note === "") {
        this.errors_review.push("Veuillez donner une note.");
      }
      if (!this.errors_review.length) {
        const formData = {
          title: this.title,
          content: this.content,
          note: this.note,
          produit: this.produit.id,
        };
        await axios
          .post("/api/v1/review/", formData)
          .then((response) => {
            toast({
              message: "Avis posté ! Merci pour votre feedback. ",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 4000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            toast({
              message: error.response.data['error'],
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: "top-center",
            });
          });
      }
    },
    async getReviews() {
      await axios
        .get(
          `/api/v1/avis/?page=${this.currentPage}&produit=${this.produit.id}&ordering=${this.filter}`
        )
        .then((response) => {
          this.showNextButton = false;
          this.showPrevButton = false;
          if (response.data.next) {
            this.loading = true;
            this.showNextButton = true;
          } else {
            this.loading = false;
          }
          if (response.data.previous) {
            this.showPrevButton = true;
          }
          this.avis = this.avis.concat(response.data.results);
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            for (const property in error.response.data) {
            }
          } else if (error.message) {
          }
        });
    },
    async createAlert() {
      const formData = {
        resource: {
          email: this.email.toLowerCase(),
          produit: this.taille.id,
        },
        hp: {
          hp: this.name,
        },
      };
      await axios
        .post("/api/v1/create-alert/", formData)
        .then((response) => {
          toast({
            message:
              "Alerte créée ! Nous vous enverrons un mail dès que le produit est de nouveau en stock. ",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            for (const property in error.response.data) {
            }
          } else if (error.message) {
          }
        });
    },
    checkScreen() {
      this.windowsWidth = window.innerWidth;
      if (this.windowsWidth <= 700) {
        this.mobile = true;
        return;
      }
      if (this.windowsWidth <= 1000 && this.windowsWidth >= 700) {
        this.tablette = true;
      }
      else{
        this.tablette = false;
      }
      this.mobile = false;
      return;
    },
    goBack(){  
      if(this.$store.state.productPageVisits){
        this.$router.go(-this.$store.state.productPageVisits);
      }
      else{
        this.$router.push("/home")
      }
    }
  },
  // pour verifier si la route exitse sinon 404 ?
  beforeRouteEnter(to, from, next) {
    const lastCategory = from.params.category_slug;
    console.log(lastCategory)
    axios
      .get(`/api/v1/produit/id/${to.query.variant}`)
      .then((response) => {
        next((vm) => {
          vm.product = response.data;
        });
      })
      .catch((error) => {
        router.push({
          name: "notfound",
          params: { pathMatch: to.path.split("/").slice(1) },
          query: to.query,
          hash: to.hash,
        });
      });
      next(vm => {
          vm.lastCategory = lastCategory;
        });
  },
};
</script>

<style lang="scss" scoped>
.picture-grid {
  margin-right: 3rem;
  display: grid;
  grid-template-areas:
    "one two"
    "one two"
    "three four";
  gap: 0.5rem;
  margin-inline: auto;
}
.page-product {
  flex: 1 0 0;
  @media (min-width: 750px) {
    margin-top: 70px;
  }
  max-width: 1950px;
}
.breadcrumb {
  display: flex;
  font-size: 0.8rem;
  a {
    margin: 0;
    padding: 0;
    color: grey;
    .active {
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
.coloris--active {
  border: 1px solid rgb(128, 128, 128, 0.7);
}
.size {
  margin: 7px;
  display: flex;
  height: 30px;
  width: 40px;
  justify-content: center;
  align-items: center;
  font-style: bold;
  font-size: 0.75rem;
  border: 1px solid rgb(128, 128, 128, 0.7);
  background: transparent;
  cursor: pointer;
  &--active {
    border-radius: 10%;
    border: 3px solid black;
    cursor: not-allowed;
    pointer-events: none;
  }
  &:hover {
    border: 3px solid black;
  }
}
.disable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.thumbnail {
  display: flex;
  position: relative;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 10%;
  border-color: none;
  border: 1px solid transparent;
  cursor: pointer;
  &--active {
    border-radius: 10%;
    border: 3px solid black;
    cursor: not-allowed;
    pointer-events: none;
  }
  &:hover {
    border: 1px solid rgba(128, 128, 128, 0.2);
  }

  img {
    padding: 5px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.variant-section {
  display: flex;
}
.button-grey {
  cursor: pointer;
  width: 100%;
  flex: 1;
  color: white;
  border-radius: none;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 15px;
  background: rgb(139, 138, 138);
  transition: 0.2s ease all;
  &:hover {
    background: rgba(109, 108, 108, 0.5);
  }
}
.separator {
  width: 100%;
  border-bottom: 1px solid black;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.button-light {
  flex: 1;
  text-align: center;
  border-radius: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background: white;
  color: black;
  border: 1px solid rgb(240, 240, 240);
  transition: 0.2s ease all;
  &:hover {
    background: rgb(240, 240, 240);
  }
}
.test {
  max-width: 500px;
  height: 0px;
  overflow: hidden;
}
.test1 {
  max-width: 500px;
  height: 0px;
  overflow: hidden;
}
.test2 {
  max-width: 500px;
  height: 0px;
  overflow: hidden;
}
.image {
  &:hover {
    cursor: zoom-in;
  }
}
.image1 {
  &:hover {
    cursor: zoom-in;
  }
}
.demo-trigger {
  height: 100%;
  object-fit: cover;
}
.demo-trigger1 {
  height: 100%;
  object-fit: contain;
}
.info-section {
  //min-width:500px;
}
.window-opacity {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  opacity: 50%;
  background-color: black;
}
.display-window-opacity-enter-active,
.display-window-opacity-leave-active {
  transition: 0.1s ease all;
}

.display-window-opacity-enter-from,
.display-window-opacity-leave-to {
  transform: opacity(50%);
}

.display-window-opacity-enter-to,
.display-window-opacity-leave-from {
  transform: opacity(0);
}
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  .message {
    width: 100%;
    max-width: 500px;
    padding: 1rem;
  }
}
.display-modal-enter-active,
.display-modal-leave-active {
  transition: 0.1s ease all;
}

.display-modal-enter-from,
.display-modal-leave-to {
  transform: opacity(0%);
}

.display-modal-enter-to,
.display-modal-leave-from {
  transform: opacity(100%);
}
.produit-container {
  display: flex;
  flex-direction: row;
  @media (max-width: 700px) {
    flex-direction: column;
  }
}
.warning-stock {
  strong {
    color: red;
  }
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.grey {
  color: grey;
}
:root {
  --card_width: 650px;
  --card_border_radius: 16px;
  --row_increment: 10px;
  --card_small: 26;
  --card_medium: 33;
  --card_large: 45;
}
.pin_container {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;

  display: grid;
  grid-template-columns: repeat(auto-fill, 550px);
  grid-auto-rows: 5px;
  justify-content: center;
  // align-items:start;
  //column-gap:10px;

  background-color: transparent;
}
.button-link {
  border: none;
  background: none;
  font-size: 1rem;
  border-bottom: 1px solid black;
  cursor: pointer;
}

.img-guidesize {
  width: 50vw;
  height: 50vh;
  background: white;
  padding: 2rem;
}
.chart-size {
  padding: 2rem;
  @media (min-width: 1250px) {
    padding: 2rem;
  }
}
.gallery-section {
  flex: 6 0 0;
  @media(min-width:1250px){
    flex:7 0 0;
  }
}

.pin-container {
  flex: 5 0 0;
  @media(min-width:1250px){
    flex:4 0 0;
  }
  @media (min-width: 780px) {
    position: sticky;
    top: 70px;
    background-color: white;
    z-index: 1;
  }
}
</style>
