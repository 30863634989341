<template>
  <div class="box mb-4">
    <div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
        "
      >
        <div style="display: flex; flex-wrap: wrap">
          <h5 style="font-size: 1.3rem; font-weight: bold; margin-right: 1rem">
            Demande de retour du
            {{ new Date(returnAsking.timestamp).toLocaleDateString("fr-FR") }}
          </h5>
          <p
            v-if="returnAsking.status"
            style="margin-right: 1rem; font-size: 1.3rem"
          >
            - {{ returnAsking.status }}
          </p>
        </div>
        <h5 style="font-size: 1rem; text-align: center">
          # {{ returnAsking.ref }}
        </h5>
      </div>
    </div>
    <CollapsingItem :alignStart="true" :border="true" style="margin-top: 1rem">
      <template #title>
        <h1 style="font-size: 1.5rem; margin-right: 1rem">
          Produit à retourner
        </h1>
        <p style="margin-right: 1rem">
          {{ returnAsking.items.length }} produits
        </p>
      </template>
      <template #content>
        <div>
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th width="10%">Produit</th>
                <th v-show="!mobile" width="20%">Quantité</th>
                <th v-show="!mobile" width="50%">Raison du retour</th>
                <th v-show="mobile">Infos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in returnAsking.items" v-bind:key="item.id">
                <div class="thumbnail">
                  <router-link
                    :to="
                      item.get_absolute_url +
                      '?variant=' +
                      encodeURIComponent(item.unique_id)
                    "
                  >
                    <img v-bind:src="item.get_thumbnail" />
                  </router-link>
                </div>
                <td v-show="!mobile">{{ item.quantity }}</td>
                <td v-show="!mobile">{{ item.status }}</td>
                <td v-show="mobile">
                  <p>Quantité : {{ item.quantity }}</p>
                  <p>{{ item.status }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </CollapsingItem>
  </div>
</template>

<script>
import CollapsingItem from "@/components/CollapsingItem";

export default {
  name: "ReturnSummary",
  components: {
    CollapsingItem,
  },
  data() {
    return {
      return: "",
      mobile: null,
    };
  },
  props: {
    returnAsking: Object,
  },
  methods: {
    checkScreen() {
      this.windowsWidth = window.innerWidth;
      if (this.windowsWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      return;
    },
  },
  beforeMount() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreen);
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  width: 150px;
  height: 150px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
  }
}</style>
