<template>
  <div
    class="page-log-in"
    style="margin-top: 100px; max-width: 1250px; margin: 0 auto;padding-top:clamp(1.2rem, 4vw, 4rem);"
  >
    <div
      style="
        display: flex;
        justify-content: center;
        margin: clamp(4px, 3vw, 200px);
      "
    >
      <div class="existing-client">
        <h1 class="title">Déjà client</h1>
        <form @submit.prevent="submitForm">
          <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>
          <div class="field">
            <label>Adresse e-mail *</label>
            <div class="control">
              <input
                type="email"
                class="input"
                :class="{ error: v$.email.$error }"
                v-model="v$.email.$model"
                @blur="v$.email.$touch"
                autocomplete="username"
              />
              <p v-if="v$.email.$error" class="error-message">
                Veuillez saisir un email valide.
              </p>
            </div>
          </div>
          <div class="field">
            <label>Mot de passe *</label>
            <div class="control">
              <input
                type="password"
                class="input"
                :class="{ error: v$.password.$error }"
                v-model="password"
                @blur="v$.password.$touch"
                autocomplete="new-password"
              />
              <p v-if="v$.password.$error" class="error-message">
                Veuillez saisir votre mot de passe.
              </p>
            </div>
          </div>
          <router-link
            to="/forgot-pwd"
            style="float: right; margin-bottom: 2rem"
            >Mot de passe oublié ?</router-link
          >
          <div class="field">
            <div class="control" style="display: flex">
              <button class="button-dark"  :class="{ 'isloading': loginIsLoading }">
                <span v-if="loginIsLoading">
                  <i class="fa-solid fa-circle-notch fa-spin"></i>
                </span>
                <span v-else>
                  Me connecter
                </span>
              </button>
            </div>
          </div>
          <div class="hp-field">
            <label>Name</label>
            <input type="text" v-model="name" />
          </div>
        </form>
        <p class="create-acount-mobile">
          Pas de compte ?
          <router-link :to="toPath">Créer un compte.</router-link>
        </p>
      </div>
      <div class="new-client">
        <h1 class="title">Nouveau client ?</h1>
        <h1 style="font-size: 1.4rem; margin-bottom: 1rem">
          Inscrivez-vous pour finaliser votre commande et pour bénéficier de
          nombreux avantages :
        </h1>
        <ul style="margin-bottom: 2rem">
          <li>
            <i class="fa-regular fa-map"></i>
            Suivi de commande
          </li>
          <li>
            <i class="fa-solid fa-clock-rotate-left"></i>
            Historique de commandes
          </li>
          <li>
            <i class="fa-regular fa-heart"></i>
            Liste de souhaits sauvegardée
          </li>
        </ul>
        <router-link :to="toPath" class="button-dark"
          >Créer un compte.</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
} from "@vuelidate/validators";
import { toast } from "bulma-toast";

import axios from "axios";
export default {

  setup() {
    return { v$: useVuelidate() };
  },
  name: "LogInView",
  data() {
    return {
      loginIsLoading:false,
      password: "",
      email: "",
      name: "",
      errors: [],
      toPath:
        "/sign-up" +
        (this.$route.query.to ? "?to=" + this.$route.query.to : ""),
    };
  },
  validations() {
    return {
      password: { required, $lazy: true },
      email: { required, email, $lazy: true }, // Matches this.contact.email
    };
  },
  mounted() {
    document.title = "Log in | Outsidog";
    if (this.$store.getters.isAuthenticated) {
      // Rediriger vers la page de compte
      this.$router.push('/my-account');
    }
    const section = this.$route.query.to;
    // Map des sections vers des messages
    const sectionMessages = {
      '/my-favorites': 'accéder à vos Favoris.',
      '/cart/checkout': 'finaliser votre Commande.',
      '/my-orders': 'voir vos Commandes.',
      '/my-return-and-refund': 'gérer votre Retour.',
    };
    if (section && sectionMessages[section]){
      toast({
              message: "Vous devez vous connecter pour " + sectionMessages[section] + "",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 4000,
              position: "top-right",
            });
    }
  },
  methods: {
    async getUserInfo() {
      await axios
        .get(`/api/v1/users/me/`)
        .then((response) => {
        })
        .catch((error) => {});
      return -1;
    },
    async getFavorites() {
      axios
        .get("/api/v1/favoris/")
        .then((response) => {
          this.produits = response.data.map((favori) => favori.produit);
          this.$store.commit("addToFavorites", this.produits);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      const error = document.getElementsByClassName("error-message");
      if (error.length > 0) {
        error[0].parentNode.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
      if (isFormCorrect) {
        this.loginIsLoading = true;
        axios.defaults.headers.common["Authorization"] = "";
        localStorage.removeItem("token");
        const formData = {
          hp: this.name,
          email: this.email.toLowerCase(),
          password: this.password,
        };

        await axios
          .post("/api/v1/token/login/", formData)
          .then((response) => {
            this.loginIsLoading = false;
            const token = response.data.auth_token;
            this.$store.commit("setToken", token);
            axios.defaults.headers.common["Authorization"] = token
              ? `Token ${token}`
              : null;
            localStorage.setItem("token", token);
            const toPath = this.$route.query.to || "/my-account";
            this.getUserInfo();
            this.getFavorites();
            this.$router.push(toPath);
          })
          .catch((error) => {
            this.loginIsLoading = false;
            this.errors = [];
            if (error.response && error.response.status === 500) {
              this.errors.push(
                "Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard. "
                );
              } else {
              for (const property in error.response.data) {
                this.errors.push(`${error.response.data[property]}`);
              }
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
.error {
  border: 1px solid red;
}
.error-message {
  color: red;
}
.existing-client {
  width: 50%;
  padding-right: clamp(4px, 3vw, 200px);
  padding-left: clamp(4px, 3vw, 200px);
  @media (max-width: 780px) {
    width: 100%;
  }
}
.new-client {
  border-left: 1px solid grey;
  padding-right: clamp(4px, 3vw, 200px);
  padding-left: clamp(4px, 3vw, 200px);
  width: 50%;

  @media (max-width: 780px) {
    display: none;
  }
}
.create-acount-mobile {
  display: none;
  @media (max-width: 780px) {
    display: block;
  }
}


</style>
