<template>
  <div v-show="!mobile" style="position: relative;"> 
    <div class="picture-grid" v-bind:class="images.length <= 1 ? 'simple-col' : ''">
      <ImageZoomed
      v-for="image in images"
        v-bind:key="image"
        v-bind:url="image.get_image"
        v-bind:urlZoom="image.get_image"
        />
      </div>
    </div>
    <div v-show="mobile" style="height: 100vw; position: relative">
      <div style="position: absolute; z-index: 1; left: 20px; top:20px;">
       <i class="fa-solid fa-arrow-left fa-xl" @click="goBack" style="color:grey;text-decoration:outline;font-style:italic;cursor: pointer;"></i>
      </div>
      <CarouselMulitple
      ref="myRef"
      v-bind:images="images"
      :childElement="images.length"
      :childPerSlide="1"
      :pagination="true"
      :zoom="true"
    >
      <template v-slot:image="{ image }">
        <ImageLoader style="width: 100vw;" v-bind:url="image.get_image" />
      </template>
      <template #prev-icon>
        <i
          class="fa-solid fa-chevron-left"
          style="position: relative; font-size: 30px"
        ></i>
      </template>
      <template #next-icon>
        <i
          class="fa-solid fa-chevron-right"
          style="position: relative; font-size: 30px"
        ></i>
      </template>
    </CarouselMulitple>
  </div>

  <Modal ref="relaymodal">
    <div v-if="isZoomed" class="modal" @click="closeModal">
      <div class="modal-content">
        <img :src="zoomedPhotoUrl" alt="Zoomed Photo" class="zoomed-photo" />
        <button @click="closeModal">Close</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import ImageLoader from "@/components/ImageLoader";

export default {
  emits: ['refresh'], // : [Vue warn]: Extraneous non-emits event listeners (refresh) were passed to component but could not be automatically inherited because component renders fragment or text root nodes. If the listener is intended to be a component custom event listener only, declare it using the "emits" option. 

  name: "Gallery",
  props: {
    images: Object,
  },
  components: {
    ImageLoader,
  },
  data() {
    return {
      mobile: null,
      tablette:null,
      windowsWidth: null,
      isZoomed: false,
      zoomedPhotoUrl: "",
      categoryUrl: null,
    };
  },
  created() {
    if (!this.$refs.myRef) {
    }

    this.$nextTick(() => {
      if (this.$refs.myRef) {
        this.$emit("refresh");
      }
    });
  },
  computed() {},
  mounted() {
    this.categoryUrl = this.$store.state.categoryUrl;
  },
  methods: {
    checkScreen() {
      this.windowsWidth = window.innerWidth;
      if (this.windowsWidth <= 700) {
        this.mobile = true;
        return;
      }
      if (this.windowsWidth <= 1000 && this.windowsWidth >= 700) {
        this.tablette = true;
      }
      else{
        this.tablette = false;
      }
      this.mobile = false;
      return;
    },
    zoomPhoto() {
      this.isZoomed = true;
      this.zoomedPhotoUrl = images[0];
    },
    closeModal() {
      this.isZoomed = false;
      this.zoomedPhotoUrl =
        "http://127.0.0.1:8000/media/uploads/collier-kaki_mhn6I7z.jpg";
    },
    goBack(){
      if(this.$store.state.productPageVisits){
        this.$router.go(-this.$store.state.productPageVisits);
      }
      else{
        this.$router.push("/home")
      }
    }
  },
  beforeMount() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreen);
  },
};
</script>

<style lang="scss" scoped>
.picture-grid {
  margin-right: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin-inline: auto;
  padding: clamp(4px, 3vw, 200px);
  @media (max-width: 700px) {
    padding: 0px;
    grid-template-columns: repeat(1, 1fr);

  }
  @media (min-width: 700px) and ( max-width: 1250px) {
    grid-template-columns: repeat(1, 1fr);
    //grid-template-areas:none;
  }
}
.simple-col{
  grid-template-columns: repeat(1, 1fr);
  max-width: 900px;
}
.page-product {
  padding-top: 70px;
}
.breadcrumb {
  display: flex;
  font-size: 0.8rem;
  a {
    margin: 0;
    padding: 0;
    color: grey;
    .active {
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
.size {
  margin: 7px;
  display: flex;
  height: 30px;
  width: 40px;
  justify-content: center;
  align-items: center;
  font-style: bold;
  font-size: 0.75rem;
  border: 1px solid rgb(128, 128, 128, 0.7);
  background: transparent;
  cursor: pointer;
  &--active {
    border: 3px solid black;
    cursor: not-allowed;
    pointer-events: none;
  }
  &:hover {
    border: 3px solid black;
  }
}
.disable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.thumbnail {
  display: flex;
  position: relative;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 10%;
  border-color: none;
  border: 1px solid transparent;
  cursor: pointer;
  &--active {
    border: 1px solid black;
    cursor: not-allowed;
    pointer-events: none;
  }
  &:hover {
    border: 1px solid rgba(128, 128, 128, 0.2);
  }

  img {
    padding: 5px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.variant-section {
  display: flex;
}

.separator {
  width: 100%;
  border-bottom: 1px solid black;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.button-light {
  flex: 1;
  text-align: center;
  border-radius: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background: white;
  color: black;
  border: 1px solid rgb(240, 240, 240);
  transition: 0.2s ease all;
  &:hover {
    background: rgb(240, 240, 240);
  }
}
.test {
  max-width: 500px;
  height: 0px;
  overflow: hidden;
}
.image {
  &:hover {
    cursor: zoom-in;
  }
}
.image1 {
  &:hover {
    cursor: zoom-in;
  }
}
.demo-trigger {
  height: 100%;
  object-fit: cover;
}
.demo-trigger1 {
  height: 100%;
  object-fit: contain;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  max-width: 100%;
  max-height: 100%;
}
.zoomed-photo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Ajoutez ces règles pour permettre à l'utilisateur de manipuler l'image zoomée */
.zoomed-photo {
  cursor: grab;
  touch-action: pan-y;
}

.zoomed-photo:active {
  cursor: grabbing;
}
</style>
