import { createStore } from "vuex";
import axios from "axios";
import { toast } from "bulma-toast";
import mediaModule from "../modules/mediaModule";
import media from "./modules/media";

export default createStore({
  modules: {
    media: {
      state: {
        images: {},
      },

      getters: {
        getImage: (state) => (url) => {
          return state.images[url];
        },
      },

      mutations: {
        ADD_IMAGE(state, payload) {
          if (!state.images[payload.url]) {
            const image = new Image();
            image.onload = () => {
              state.images[payload.url] = image.src;
            };
            image.onerror = (error) => {
              console.error(
                `Failed to load image with URL: ${payload.url}`,
                error
              );
            };
            image.src = payload.url;
          }
        },
      },

      actions: {
        fetchImage({ commit, getters }, url) {
          return new Promise((resolve, reject) => {
            const cachedImage = getters.getImage(url);
            if (cachedImage) {
              resolve(cachedImage);
            } else {
              const image = new Image();
              image.onload = () => {
                commit("ADD_IMAGE", { url, data: image.src });
                resolve(image.src);
              };
              image.onerror = (error) => {
                reject(error);
              };
              image.src = url;
            }
          });
        },
      },
    },
  },
  state: {
    cart: {
      items: [
        // {
        //   id:null,
        //   quantity:null,
        //   produit:null,
        //   url:null,
        //   titre:null,
        //   price:null,
        //   final_price:null,
        //   discount_type:null,
        //   thumbnail_url:null,
        //   valid:null,
        // }
      ],
    },
    favoris: [],
    favorites: [],
    isAuthenticated: false,
    token: "",
    isLoading: false,
    produits: [],
    nb_produits: 0,
    page: 1,
    categoryId: null,
    categoryUrl: '',
    updated: false,
    name: "",
    productPageVisits:0,
    subscribed: false, // Indique si l'utilisateur s'est inscrit à la newsletter
    showAnnouncement: true,
  },
  getters: {
    cartTotalPrice(state) {
      let total = state.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.final_price.toFixed(2) * curVal.quantity);
      }, 0);
      return total;
    },
    cartItems(state) {
      return state.cart.items;
    },
    isFavori: (state) => (id) => {
      const test = state.favorites.findIndex((i) => i.id === id) !== -1;
      return state.favorites.findIndex((i) => i.unique_id === id) !== -1;
    },
    cartUpdateStatus(state) {
      return state.updated;
    },
    cartTotalLength(state) {
      return state.cart.items.reduce((acc, curVal) => {
        return (acc += curVal.quantity);
      }, 0);
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    getAnoucementBanner(state) {
      return state.showAnnouncement;
    }
  },
  mutations: {
    updateMatomoEcommerceCart(state, category) {
      if (typeof _paq !== 'undefined') {
        // Effacer le panier actuel dans Matomo
        _paq.push(['deleteCart']);
    
        // Ajouter chaque article du panier actuel à Matomo
        state.cart.items.forEach(item => {
          _paq.push(['addEcommerceItem', item.id, item.name, category, item.final_price, item.quantity]);
        });

        let total = state.cart.items.reduce((acc, curVal) => {
          return (acc += curVal.final_price.toFixed(2) * curVal.quantity);
        }, 0);
        // Mettre à jour le panier dans Matomo avec le total
        _paq.push(['trackEcommerceCartUpdate', total]);
      }
    },

    initializeStore(state) {
      if (
        localStorage.getItem("cart") &&
        localStorage.getItem("cart") !== "undefined"
      ) {
        state.cart = JSON.parse(localStorage.getItem("cart"));
      } else {
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
      if (
        localStorage.getItem("favoris") &&
        localStorage.getItem("favoris") !== "undefined"
      ) {
        state.favoris = JSON.parse(localStorage.getItem("favoris"));
      } else {
        localStorage.setItem("favoris", JSON.stringify(state.favoris));
      }
      if (localStorage.getItem("token")) {
        state.token = localStorage.getItem("token");
        state.isAuthenticated = true;
      } else {
        state.token = "";
        state.isAuthenticated = false;
      }
      if (
        localStorage.getItem("subscribed") &&
        localStorage.getItem("subscribed") !== "undefined"
      ) {
        state.subscribed = JSON.parse(localStorage.getItem("subscribed"));
      } else {
        localStorage.setItem("subscribed", JSON.stringify(state.subscribed));
      }
    },
    addToCart(state, item) {
      const exists = state.cart.items.filter((i) => i.id === item.id);
      if (exists.length) {
        if (exists[0].quantity < exists[0].stock) {
          exists[0].quantity =
          parseInt(exists[0].quantity) + parseInt(item.quantity);
          toast({
            message:
            '<div style="display:flex;flex-direction:column;"> <p>   <i class="fa-solid fa-circle-check fa-2xl"></i>   Le produit a bien été ajouté à votre panier.</p><a href="/cart/checkout" class="button-light" style="color:#48c78e;text-decoration:none;font-weight:bold;"> Finaliser votre commande</a></div>',
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 4000,
            position: "top-center",
          });
        } else {
          toast({
            message: "La quantité ne peut pas être augmentée. Le stock de l'article est trop faible.",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 4000,
            position: "top-center",
          });
        }
      } else {
        state.cart.items.push(item);
        toast({
          message:
          '<div style="display:flex;flex-direction:column;"> <p>   <i class="fa-solid fa-circle-check fa-2xl"></i>   Le produit a bien été ajouté à votre panier.</p><a href="/cart/checkout" class="button-light" style="color:#48c78e;text-decoration:none;font-weight:bold;"> Finaliser votre commande</a></div>',
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 4000,
          position: "top-center",
        });

        let total = state.cart.items.reduce((acc, curVal) => {
          return (acc += curVal.final_price.toFixed(2) * curVal.quantity);
        }, 0);
        console.log("push matomo")
        // _paq.push(['trackPageView']);

        // _paq.push(["addEcommerceItem",item.id,item.name,item.category,item.final_price]);
        // _paq.push(['trackEcommerceCartUpdate', total]);
        state.cart.items.forEach(item => {
          _paq.push(['addEcommerceItem', item.id, item.name, item.category, item.final_price, item.quantity]);
        });
        // Mettre à jour le panier dans Matomo avec le total
        _paq.push(['trackEcommerceCartUpdate', total]);
      }
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    addToFavoris(state, unique_id) {
      const exists = state.cart.items.filter((i) => i === unique_id);
      if (!exists.length) {
        state.favoris.push(unique_id);
      }
      localStorage.setItem("favoris", JSON.stringify(state.favoris));
    },
    addToProduit(state, produits) {
      state.produits = produits;
    },
    addToFavorites(state, favorites) {
      state.favorites = favorites;
    },
    addToFavorite(state, favorite) {
      const exists = state.favorites.filter(
        (i) => i.unique_id === favorite.unique_id
      );
      if (!exists.length) {
        state.favorites.push(favorite);
      }
    },
    removeFavorite(state) {
      state.favoris = [];
      state.favorites = [];
    },
    setIsLoading(state, status) {
      state.isLoading = status;
    },
    setStatus(state, status) {
      state.updated = status;
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    incrementPage(state) {
      state.page++;
    },
    resetPage(state) {
      state.page = 1;
    },
    setCategory(state, categoryId) {
      state.categoryId = categoryId;
    },
    getCategory(state) {
      return state.categoryId;
    },
    setCategoryUrl(state, categoryUrl) {
      state.categoryUrl = categoryUrl;
    },
    getCategoryUrl(state) {
      return state.categoryUrl;
    },
    setNbProduits(state, nbProduits) {
      state.nb_produits = nbProduits;
    },
    removeToken(state) {
      state.token = "";
      state.isAuthenticated = false;
    },
    clearCart(state) {
      state.cart.items = state.cart.items.filter(item => false);
      localStorage.setItem("cart", JSON.stringify(state.cart));
      _paq.push(['clearEcommerceCart']);

    },
    setPrice(state, id, price) {
      const item = state.cart.items.find((i) => i.id === id);
      if (item) {
        item.price = price;
      }
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    setFinalPrice(state, id, price) {
      const item = state.cart.items.find((i) => i.id === id);
      if (item) {
        item.final_price = price;
      }
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    incrementProductPageVisits(state) {
      state.productPageVisits++;
    },
    resetProductPageVisits(state) {
      state.productPageVisits = 0;
    },
    updateQuantityCart(state) {
      let updated = false;
      let promisedEvents = [];
      state.cart.items.forEach((element) => {
        promisedEvents.push(
          axios
            .get(`/api/v1/produit/id/${element.id}`)
            .then((response) => {
            
              if (response.data.quantity <= 0) {
                element.quantity = 0;
              }
              if (
                response.data.price != element.price ||
                response.data.get_final_price != element.final_price
              ) {
                updated = true;
                
                element.price = response.data.price;
                element.final_price = response.data.get_final_price;
                setPrice(element.id, element.price);
                setFinalPrice(element.id, element.final_price);
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 500) {
                for (const property in error.response.data) {
                }
              } else if (error.message) {
              }
            })
        );
      });
      Promise.all(promisedEvents);
      localStorage.setItem("cart", JSON.stringify(state.cart));
      localStorage.setItem("updated", true);
    },
    setSubscribed(state) {
      state.subscribed = true;
      localStorage.setItem("subscribed", JSON.stringify(state.subscribed));
    },
    setUnsubscribed(state) {
      state.subscribed = false;
      localStorage.setItem("subscribed", JSON.stringify(state.subscribed));
    },
    dismissAnnouncement(state) {
      state.showAnnouncement = false;
    },
  },
  actions: {},
});
