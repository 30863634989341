import { createApp } from "vue";
import { createHead } from "unhead";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import CarouselMultiple from "@/components/CarouselMultiple";
import Modal from "@/components/Modal";
import ImageZoomed from "@/components/ImageZoomed";
import ImageLoader from "@/components/ImageLoader";

axios.defaults.baseURL = "https://api.outsidog.com";
//axios.defaults.baseURL = "http://127.0.0.1:8000";

axios.interceptors.request.use(
  (config) => {
    // Modifier la configuration de la requête pour inclure le timeout
    config.timeout = 60000; // Timeout en millisecondes (dans cet exemple, 10 secondes)
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//axios.defaults.baseURL = "https://api.outsidog.com"
const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

const head = createHead();
const app = createApp(App).use(router);
app.component("CarouselMulitple", CarouselMultiple);
app.component("ImageZoomed", ImageZoomed);
app.component("ImageLoader", ImageLoader);
app.component("Modal", Modal);
app.directive("click-outside", clickOutside);
app.use(store).use(router, axios).use(head).mount("#app");

// createApp(App).use(store).use(router, axios).mount('#app')
