<template>
  <div
    class="page-cgv"
    style="
      margin-top: 60px;
      margin-left: 1rem;
      margin-right: 1rem;
      margin: 0 auto;
      padding: 2rem 2rem;
    "
  >
    <div class="breadcrumb">
      <router-link to="/">ACCUEIL</router-link>
      <div
        style="display: flex"
        v-for="(route, index) in routes"
        v-bind:key="route"
      >
        <span>&nbsp;>&nbsp;</span>
        <router-link v-bind:to="route">
          <span v-bind:class="index == routes.length - 1 ? 'active' : ''">{{
            route.toUpperCase()
          }}</span>
        </router-link>
      </div>
    </div>
    <h1 class="title" style="margin-bottom: 4rem">
      Conditions générales de ventes
    </h1>

    <div class="paragraph">
      <h3 class="title">ARTICLE 1 - Champ d'application</h3>
      Les présentes conditions générales de vente (CGV) s'appliquent à toutes
      les ventes conclues par le vendeur sur le site outsidog.com à des
      acheteurs non professionnels (ci-après "les clients"), désirant acquérir
      les produits proposés à la vente (ci-après "les produits"). Les produits
      proposés à la vente sur le site sont des vêtements et des colliers, et les
      caractéristiques principales des produits, notamment les spécifications,
      les illustrations et les indications de dimensions ou de capacité, sont
      présentées sur le site outsidog.com. Le client est tenu de prendre
      connaissance de ces informations avant de passer commande. Le choix et
      l'achat d'un produit sont de la seule responsabilité du client. Les offres
      de produits s'entendent dans la limite des stocks disponibles, tels que
      précisés lors de la passation de la commande. Ces CGV sont accessibles à
      tout moment sur le site outsidog.com et prévalent sur tout autre document.
      Le client déclare avoir pris connaissance des présentes CGV et les avoir
      acceptées en cochant la case prévue à cet effet avant la mise en œuvre de
      la procédure de commande en ligne du site outsidog.com. Sauf preuve
      contraire, les données enregistrées dans le système informatique du
      vendeur constituent la preuve de l'ensemble des transactions conclues avec
      le client. Les coordonnées du vendeur sont les suivantes : OUTSIDOG - LE GOFF
      17 rue Carle Hébert 92400 Courbevoie Email : contact@outsidog.com 
      Numéro d'immatriculation :94913216100017
    </div>
    <div class="paragraph">
      <h3 class="title">ARTICLE 3 – Commandes</h3>
      Les Produits sont proposés à la vente aux tarifs en vigueur indiqués sur
      le site outsidog.com au moment de l'enregistrement de la commande par le
      Vendeur. Les prix sont affichés en Euros, HT et TTC. Ils tiennent compte
      des éventuelles réductions accordées par le Vendeur sur le site
      outsidog.com. Ces tarifs sont fermes et non révisables pendant leur
      période de validité, mais le Vendeur se réserve le droit de les modifier à
      tout moment en dehors de cette période. Les frais de traitement,
      d'expédition, de transport et de livraison ne sont pas inclus dans les
      prix affichés et sont facturés en supplément, conformément aux modalités
      indiquées sur le site et calculés avant la passation de la commande. Le
      paiement demandé au Client correspond au montant total de l'achat, y
      compris ces frais. Une facture sera établie par le Vendeur et remise au
      Client lors de la livraison des Produits commandés.
    </div>
    <div class="paragraph">
      <h3 class="title">ARTICLE 3 - Prix</h3>
      Le Client est responsable de la sélection des Produits qu'il souhaite
      commander sur le site outsidog.com, selon les modalités suivantes : Le
      Client peut parcourir le site et ajouter des produits à son panier. Le
      Client peut ensuite accéder à son panier pour vérifier les produits qu'il
      a sélectionnés. Il peut supprimer des produits ou en ajouter d'autres. Le
      panier affichera également le prix de la commande, y compris les taxes,
      mais sans les frais de livraison. Une fois que le Client a vérifié son
      panier, il peut accéder à la page de commande. À cette étape, le Client
      sera invité à créer un compte ou à se connecter à un compte existant. Sur
      la page de commande, le client devra remplir les informations de
      livraison, de facturation et choisir son mode de livraison. Après avoir
      vérifié et validé les informations de commande, le Client sera invité à
      accepter les présentes conditions générales de vente. Après avoir accepté
      les conditions générales de vente, le Client sera redirigé vers la
      plateforme de paiement Stripe, où il pourra procéder au paiement selon les
      modalités prévues. Une fois le paiement effectué, la commande sera
      considérée comme définitive et le Client recevra une confirmation de
      commande par e-mail. Les offres de Produits sont valables tant qu'elles
      sont visibles sur le site, dans la limite des stocks disponibles. La vente
      ne sera considérée comme valide qu'après le paiement intégral du prix. Il
      appartient au Client de vérifier l'exactitude de la commande et de
      signaler immédiatement toute erreur. Toute commande passée sur le site
      outsidog.com constitue la formation d'un contrat conclu à distance entre
      le Client et le Vendeur. Le Vendeur se réserve le droit d'annuler ou de
      refuser toute commande d'un Client avec lequel il existerait un litige
      relatif au paiement d'une commande antérieure. Le Client pourra suivre
      l'évolution de sa commande sur le site.
    </div>
    <div class="paragraph">
      <h3 class="title">ARTICLE 3 Bis - Espace client - Compte</h3>
      Le Client est invité à créer un compte (espace personnel) pour passer une
      commande. Il doit remplir un formulaire avec des informations sincères et
      exactes concernant son état civil et ses coordonnées, notamment son
      adresse e-mail. Le Client est responsable de la mise à jour de ces
      informations, qu'il peut modifier en se connectant à son compte. Pour
      accéder à son espace personnel et à l'historique de ses commandes, le
      Client doit s'identifier à l'aide de son nom d'utilisateur et de son mot
      de passe, qui lui sont communiqués après son inscription et qui sont
      strictement personnels. Le Client s'engage à ne pas divulguer ces
      informations. Dans le cas contraire, il sera seul responsable de l'usage
      qui en sera fait. Le Client peut demander sa désinscription en se rendant
      sur la page dédiée de son espace personnel ou en envoyant un e-mail à
      contact@outsidog.com. La désinscription sera effective dans un délai raisonnable.
      Si le Client ne respecte pas les conditions générales de vente et/ou
      d'utilisation, le site outsidog.com pourra suspendre ou fermer son compte
      après une mise en demeure adressée par voie électronique et restée sans
      effet. Toute suppression de compte, quel qu'en soit le motif, entraîne la
      suppression pure et simple de toutes les informations personnelles du
      Client. En cas d'événement dû à un cas de force majeure ayant pour
      conséquence un dysfonctionnement du site ou du serveur, et sous réserve de
      toute interruption ou modification en cas de maintenance, le Vendeur ne
      pourra être tenu responsable. La création du compte entraîne l'acceptation
      des présentes conditions générales de vente."
    </div>
    <div class="paragraph">
      <h3 class="title">ARTICLE 4 - Conditions de paiement</h3>
      Le prix doit être payé par le Client en totalité, au moment de la
      passation de la commande, via un paiement sécurisé par carte bancaire. Les
      données de paiement sont échangées de manière cryptée grâce au protocole
      défini par le prestataire de paiement agréé qui intervient pour les
      transactions bancaires effectuées sur le site outsidog.com. Le Vendeur ne
      considérera le paiement comme définitif que lorsque les sommes dues auront
      été encaissées de manière effective. Si le Client ne paie pas le prix
      intégralement dans les conditions indiquées ci-dessus, le Vendeur ne sera
      pas tenu de procéder à la délivrance des Produits commandés.
    </div>
    <div class="paragraph">
      <h3 class="title">ARTICLE 5 - Livraisons</h3>
      Les Produits commandés par le Client seront livrés uniquement en France
      métropolitaine. Les livraisons interviennent dans un délai maximum de 5 jours à compter de la
      date de commande et à l'adresse indiquée par le Client sur le site. La
      livraison sera considérée comme effectuée dès que le Client aura pris
      possession physique ou le contrôle du Produit. Sauf cas particulier ou
      indisponibilité d'un ou plusieurs Produits, les Produits commandés seront
      livrés en une seule fois. Le Vendeur s'engage à tout mettre en œuvre pour
      respecter les délais de livraison mentionnés ci-dessus. Si les Produits
      commandés ne sont pas livrés dans un délai de 5 jours après la date
      indicative de livraison, pour toute autre raison que la force majeure ou
      le fait du Client, la vente pourra être annulée à la demande écrite du
      Client, conformément aux articles L. 216-2, L. 216-3 et L. 241-4 du Code
      de la consommation. Les sommes versées par le Client lui seront alors
      restituées dans les 14 jours suivant la résolution du contrat, sans
      qu'aucune indemnisation ou retenue ne puisse être appliquée. Si le Client
      a des demandes particulières concernant l'emballage ou le transport des
      Produits, celles-ci doivent être acceptées par écrit par le Vendeur. Les
      coûts y afférents feront l'objet d'une facturation spécifique
      complémentaire sur devis accepté par écrit par le Client. Le Client doit
      vérifier l'état des Produits livrés dans un délai maximum de 254 jours
      après la livraison et formuler des réclamations par courrier électronique
      ou par téléphone, accompagnées de tous les justificatifs nécessaires
      (notamment des photos). Passé ce délai et en l'absence de respect de ces
      formalités, les Produits seront considérés comme conformes et exempts de
      tout défaut apparent, et aucune réclamation ne sera acceptée par le
      Vendeur. Si des défauts de conformité ou des vices apparents ou cachés des
      Produits livrés sont dûment prouvés par le Client, le Vendeur remboursera
      ou remplacera les Produits en question dans les meilleurs délais et à ses
      frais, conformément aux dispositions des articles L. 217-4 et suivants du
      Code de la consommation et aux présentes CGV. Le transfert des risques de
      perte et de détérioration s'effectue uniquement lorsque le Client prend
      physiquement possession des Produits. Les Produits voyagent donc aux
      risques et périls du Vendeur, sauf si le Client a lui-même choisi le
      transporteur. Dans ce cas, les risques sont transférés au moment de la
      remise du bien au transporteur.
    </div>
    <div class="paragraph">
      <h3 class="title">ARTICLE 6 - Transfert de propriété</h3>
      La propriété des Produits vendus par le Vendeur ne sera transférée au
      Client qu'après le paiement intégral du prix, quel que soit la date de
      livraison des Produits.
    </div>
    <div class="paragraph">
      <h3 class="title">ARTICLE 7 - Droit de rétractation</h3>
      Conformément à l'article L221-18 du Code de la consommation,consommateur dispose d'un délai de quatorze jours pour exercer son droit de rétractation. Le délai de rétractation commence à partir du moment où vous recevez votre premier produit. Si vous avez passé une commande pour plusieurs produits qui seront livrés au fil du temps, le délai de rétractation commence dès la réception du dernier produit. Le client dispose d'une période de quatorze jours (14 jours) à partir du jour où le client, ou un tiers autre que le transporteur désigné par le client, prend physiquement possession des produits issus de sa commande pour exercer son droit de rétractation.

      Pour exercer son droit de rétractation, le client doit clairement exprimer sa volonté de se rétracter dans un délai maximum de 14 jours comme précédemment énoncé. Pour ce faire, le client doit compléter le <a
                class="button-outline"
                href="./outsidog-return-form.pdf"
                target="_blank"
                >formulaire de retour</a
              > sans ambiguïté et le retourner par courrier à l'adresse postale suivante "OUTSIDOG 17 rue Carle Hebert 92400 Courbevoie" ou par courrier électronique à l'adresse suivante : contact@outsidog.com. La charge de la preuve de l'exercice du droit de rétractation incombe au client. Par conséquent, il est fortement recommandé d'utiliser une lettre recommandée avec accusé de réception en cas d'envoi postal. En cas de rétractation par e-mail, dès réception de la décision du client de se rétracter, A&M SAS enverra immédiatement un accusé de réception par e-mail au client.

      Les produits doivent être retournés dans leur état d'origine complet, y compris leur emballage, leurs accessoires et leur notice, afin de permettre leur recommercialisation à l'état neuf. Les produits endommagés, salis ou incomplets ne seront pas repris. Les frais de retour sont à la charge du client, sauf en cas de défaut de fabrication ou d'erreur de notre part lors de l'expédition de la commande. L'échange (sous réserve de disponibilité) ou le remboursement sera effectué dans un délai de 14 jours à compter de la réception, par le vendeur, des produits retournés par le client, conformément aux conditions énoncées dans cet article.
      
    </div>
    <div class="paragraph" id="return_process">
      <h3 class="title">ARTICLE 7 Bis - Processus de Retour et de Remboursement</h3>

      Pour comprendre en détail le processus de retour et de remboursement, nous vous invitons à prendre connaissance des informations suivantes :

Le client doit initier une demande de retour en complétant le <a
                class="button-outline"
                href="./outsidog-return-form.pdf"
                target="_blank"
                >formulaire de retour</a
              > disponible sur notre site web. Ce formulaire dûment rempli doit être envoyé électroniquement à l'adresse e-mail : contact@outsidog.com ou par voie postale à l'adresse indiquée à l'article 1 des Conditions Générales de Vente. Cette demande doit être effectuée dans un délai de 14 jours à compter de la réception des produits.

À réception de la demande de retour, le vendeur se réserve le droit de la refuser si les produits ne respectent pas les conditions énoncées à l'article 7 ou si le délai de demande est dépassé.

Si la demande de retour est acceptée, le client recevra une confirmation par courrier électronique, précisant les produits à retourner ainsi qu'un document à imprimer et à inclure dans le colis.

Le client est encouragé à expédier les produits retournés avec une assurance et un suivi pour garantir leur sécurité pendant le transport. Les frais d'expédition sont à la charge du client.

Une fois que le vendeur a réceptionné les produits retournés, une inspection sera effectuée pour vérifier leur état. Si les produits satisfont aux conditions de retour, le processus de remboursement sera enclenché.

Le client sera remboursé de la valeur des produits retournés, à l'exception des frais de livraison, sur la même carte de paiement utilisée pour l'achat. Le remboursement sera traité dans un délai de 14 jours ouvrables à compter de la réception des produits retournés et de leur inspection satisfaisante.
    </div>
    <div class="paragraph">
      <h3 class="title">ARTICLE 8 - Responsabilité du Vendeur - Garanties</h3>
      Les Produits fournis par le Vendeur bénéficient de la garantie légale de
      conformité pour les Produits défectueux, abîmés, endommagés ou ne
      correspondant pas à la commande. Ils bénéficient également de la garantie
      légale contre les vices cachés provenant d'un défaut de matière, de
      conception ou de fabrication affectant les produits livrés et les rendant
      impropres à l'utilisation. Dispositions relatives aux garanties légales :
      légalesArticle L217-4 du Code de la consommation«;Le vendeur est tenu de
      livrer un bien conforme au contrat et répond des défauts de conformité
      existant lors de la délivrance. Il répond également des défauts de
      conformité résultant de l'emballage, des instructions de montage ou de
      l'installation lorsque celle-ci a été mise à sa charge par le contrat ou a
      été réalisée sous sa responsabilité.;»Article L217-5 du Code de la
      consommation«;Le bien est conforme au contrat :1° S'il est propre à
      l'usage habituellement attendu d'un bien semblable et, le cas échéant :-
      s'il correspond à la description donnée par le vendeur et possède les
      qualités que celui-ci a présentées à l'acheteur sous forme d'échantillon
      ou de modèle ;- s'il présente les qualités qu'un acheteur peut
      légitimement attendre eu égard aux déclarations publiques faites par le
      vendeur, par le producteur ou par son représentant, notamment dans la
      publicité ou l'étiquetage ;2° Ou s'il présente les caractéristiques
      définies d'un commun accord par les parties ou est propre à tout usage
      spécial recherché par l'acheteur, porté à la connaissance du vendeur et
      que ce dernier a accepté.;»Article L217-12 du Code de la
      consommation«;L'action résultant du défaut de conformité se prescrit par
      deux ans à compter de la délivrance du bien.;»Article 1641 du Code
      civil.«;Le vendeur est tenu de la garantie à raison des défauts cachés de
      la chose vendue qui la rendent impropre à l'usage auquel on la destine, ou
      qui diminuent tellement cet usage, que l'acheteur ne l'aurait pas acquise,
      ou n'en aurait donné qu'un moindre prix, s'il les avait connus.;»Article
      1648 alinéa 1er du Code civil«;L'action résultant des vices rédhibitoires
      doit être intentée par l'acquéreur dans un délai de deux ans à compter de
      la découverte du vice.;»Article L217-16 du Code de la
      consommation.«;Lorsque l'acheteur demande au vendeur, pendant le cours de
      la garantie commerciale qui lui a été consentie lors de l'acquisition ou
      de la réparation d'un bien meuble, une remise en état couverte par la
      garantie, toute période d'immobilisation d'au moins sept jours vient
      s'ajouter à la durée de la garantie qui restait à courir. Cette période
      court à compter de la demande d'intervention de l'acheteur ou de la mise à
      disposition pour réparation du bien en cause, si cette mise à disposition
      est postérieure à la demande d'intervention.;»Afin de faire valoir ses
      droits, le Client devra informer le Vendeur, par écrit (mail ou courrier),
      de la non-conformité des Produits ou de l'existence des vices cachés à
      compter de leur découverte.Le Vendeur remboursera, remplacera ou fera
      réparer les Produits ou pièces sous garantie jugés non conformes ou
      défectueux.Les frais d'envoi seront remboursés sur la base du tarif
      facturé et les frais de retour seront remboursés sur présentation des
      justificatifs.Les remboursements, remplacements ou réparations des
      Produits jugés non conformes ou défectueux seront effectués dans les
      meilleurs délais et au plus tard dans les 14 Jours jours suivant la
      constatation par le Vendeur du défaut de conformité ou du vice caché. Ce
      remboursement pourra être fait par virement ou chèque bancaire.La
      responsabilité du Vendeur ne saurait être engagée dans les cas suivants
      :non respect de la législation du pays dans lequel les produits sont
      livrés, qu'il appartient au Client de vérifier,en cas de mauvaise
      utilisation, d'utilisation à des fins professionnelles, négligence ou
      défaut d'entretien de la part du Client, comme en cas d'usure normale du
      Produit, d'accident ou de force majeure.Les photographies et graphismes
      présentés sur le site ne sont pas contractuels et ne sauraient engager la
      responsabilité du Vendeur.La garantie du Vendeur est, en tout état de
      cause, limitée au remplacement ou au remboursement des Produits non
      conformes ou affectés d'un vice.
    </div>
    <div class="paragraph">
      <h3 class="title">ARTICLE 9 - Données personnelles</h3>

      Le Client est informé que la collecte de ses données à caractère personnel
      est nécessaire à la vente des Produits par le Vendeur ainsi qu'à leur
      transmission à des tiers à des fins de livraison des Produits. Ces données
      à caractère personnel sont récoltées uniquement pour l’exécution du
      contrat de vente. 9.1 Collecte des données à caractère personnel Les
      données à caractère personnel qui sont collectées sur le site outsidog.com
      sont les suivantes : Ouverture de compte : lors de la création du compte
      Client/utilisateur : noms, prénoms, adresse postale, numéro de téléphone
      et adresse e-mail. Paiement : dans le cadre du paiement des Produits
      proposés sur le site outsidog.com, celui-ci enregistre des données
      financières relatives au compte bancaire ou à la carte de crédit du
      Client/utilisateur. 9.2 Destinataires des données à caractère personnel
      Les données à caractère personnel sont réservées à l’usage unique du
      Vendeur et de ses salariés. 9.3 Responsable de traitement Le responsable
      de traitement des données est le Vendeur, au sens de la loi Informatique
      et libertés et à compter du 25 mai 2018 du Règlement 2016/679 sur la
      protection des données à caractère personnel. 9.4 Limitation du traitement
      Sauf si le Client exprime son accord exprès, ses données à caractère
      personnelles ne sont pas utilisées à des fins publicitaires ou marketing.
      9.5 Durée de conservation des données Le Vendeur conservera les données
      ainsi recueillies pendant un délai de 5 ans, couvrant le temps de la
      prescription de la responsabilité civile contractuelle applicable. 9.6
      Sécurité et confidentialité Le Vendeur met en œuvre des mesures
      organisationnelles, techniques, logicielles et physiques en matière de
      sécurité du numérique pour protéger les données personnelles contre les
      altérations, destructions et accès non autorisés. Toutefois, il est à
      signaler qu’Internet n’est pas un environnement complètement sécurisé et
      que le Vendeur ne peut garantir la sécurité de la transmission ou du
      stockage des informations sur Internet. 9.7 Mise en œuvre des droits des
      Clients et utilisateurs En application de la réglementation applicable aux
      données à caractère personnel, les Clients et utilisateurs du site
      outsidog.com disposent des droits suivants : Ils peuvent mettre à jour ou
      supprimer les données qui les concernent en envoyant un email. Ils peuvent
      supprimer leur compte en écrivant à l’adresse électronique indiquée à
      l’article 9.3 "Responsable de traitement". Ils peuvent exercer leur droit
      d’accès pour connaître les données personnelles les concernant en écrivant
      à l’adresse indiquée à l’article 9.3 "Responsable de traitement". Si les
      données à caractère personnel détenues par le Vendeur sont inexactes, ils
      peuvent demander la mise à jour des informations en écrivant à l’adresse
      indiquée à l’article 9.3 "Responsable de traitement". Ils peuvent demander
      la suppression de leurs données à caractère personnel, conformément aux
      lois applicables en matière de protection des données, en écrivant à
      l’adresse indiquée à l’article 9.3 "Responsable de traitement".
    </div>
    <div class="paragraph">
      <h3 class="title">ARTICLE 10 - Propriété intellectuelle</h3>
      Le contenu du site outsidog.com est la propriété exclusive du Vendeur et
      de ses partenaires, et est protégé par les lois françaises et
      internationales relatives à la propriété intellectuelle. Toute
      reproduction, représentation, modification ou exploitation de tout ou
      partie de ce contenu, sous quelque forme et à quelque fin que ce soit,
      sans autorisation écrite préalable du Vendeur, est strictement interdite
      et est susceptible de constituer un délit de contrefaçon, puni par la
      loi." Cette version ajoute des précisions sur les actes interdits
      (reproduction, représentation, modification ou exploitation), la nécessité
      d'une autorisation préalable pour toute utilisation, ainsi que les
      conséquences de toute violation de ces droits de propriété intellectuelle
      (délit de contrefaçon puni par la loi).
    </div>
    <div class="paragraph">
      <h3 class="title">ARTICLE 11 - Droit applicable - Langue</h3>
      Les présentes Conditions Générales de Vente (CGV) et toutes les
      transactions qui en découlent sont régies par le droit français. Les CGV
      sont rédigées en français. En cas de traduction en une ou plusieurs
      langues étrangères, seule la version française fera foi en cas de litige.
    </div>
    <div class="paragraph">
      <h3 class="title">ARTICLE 12 - Résolution des litiges</h3>
      En cas de réclamation, le client est invité à contacter le service
      clientèle du vendeur, dont les coordonnées sont mentionnées à l'ARTICLE 1
      des présentes CGV. Le client est informé qu'il peut également recourir à
      une médiation conventionnelle auprès des instances de médiation
      sectorielles existantes, ou à tout autre mode alternatif de résolution des
      différends, tels que la conciliation. Dans ce cas, le médiateur désigné
      est [nom et coordonnées du médiateur]. Le client est également informé
      qu'il peut utiliser la plateforme de Règlement en Ligne des Litiges (RLL)
      de l'Union Européenne pour régler les litiges relatifs aux transactions
      effectuées en application des présentes CGV :
      https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show Tous
      les litiges découlant des transactions d'achat et de vente effectuées en
      application des présentes CGV et qui n'ont pas été résolus à l'amiable
      entre les parties ou par médiation seront soumis aux tribunaux compétents
      conformément au droit en vigueur.
    </div>
  </div>
</template>

<script>
export default {
  name: "CgvView",
  data() {
    return {
      path: "",
      routes: [],
    };
  },
  mounted() {
    this.path = this.$router.currentRoute;
    this.routes = this.path.path.split("/").filter(Boolean);
  },
  methods: {},

  computed: {},
};
</script>

<style lang="scss" scoped>
.page-cgv {
  max-width: 1280px;
}
.paragraph {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: justify;
  font-size: clamp(14px, 1vw, 16px);
  .title {
    font-size: clamp(20px, 1vw, 30px);
    font-weight: bold;
    margin-bottom: 1rem;
  }
  ol {
    padding-top: 1rem;
    padding-bottom: 1rem;
    list-style-type: disc;
    list-style-position: inside;
  }
}
.breadcrumb {
  display: flex;
  font-size: 0.8rem;
  a {
    margin: 0;
    padding: 0;
    color: grey;
    .active {
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
