<template>
  <div class="box">
    <h3 class="is-size-4">{{ review.title }}</h3>
    <!-- <p class="is-size-9" style="font-style:italic;">{{review.author.email}} - {{ review.date_posted }}</p> -->
    <star-rating
      :rating="review.note"
      :read-only="true"
      :star-size="30"
      :border-width="2"
      :star-points="[
        381.2, 150.3, 524.9, 171.5, 529.3, 172.4, 537.3, 176, 543.9, 181.7,
        548.9, 188.9, 550.6, 193.1, 551.7, 197.5, 552, 206.3, 550, 214.8, 545.7,
        222.5, 542.7, 225.9, 438.5, 328.1, 463.1, 474.7, 463.5, 479.2, 462.5,
        488, 459.2, 496.1, 453.7, 503.1, 450.2, 506, 446.3, 508.4, 438, 511.4,
        429.3, 511.9, 420.6, 510.1, 416.5, 508.3, 288.1, 439.8, 159.8, 508.3,
        155.7, 510.1, 147, 511.9, 138.2, 511.4, 129.9, 508.4, 126, 506, 122.5,
        503.1, 117, 496.1, 113.7, 488, 112.7, 479.2, 113.2, 474.7, 137.8, 328.1,
        33.6, 225.9, 30.6, 222.5, 26.3, 214.8, 24.3, 206.3, 24.6, 197.5, 25.7,
        193.1, 27.4, 188.9, 32.3, 181.7, 39, 176, 47, 172.4, 51.4, 171.5, 195,
        150.3, 259.4, 18, 261.7, 14, 267.6, 7.4, 275.1, 2.8, 283.6, 0.3, 288.1,
        -0, 292.7, 0.3, 301.2, 2.8, 308.7, 7.4, 314.6, 14, 316.9, 18, 381.2,
        150.3, 381.2, 150.3,
      ]"
    ></star-rating>
    <p
      class="is-size-6 has-text-grey card_preview-text"
      :class="{ 'card_preview-text-display': display }"
      ref="content"
    >
      {{ review.content }}
    </p>
    <button
      class="read-more"
      v-show="isTextClamped"
      @click="[(isTextClamped = false), (display = true)]"
    >
      Lire plus
    </button>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
export default {
  name: "ReviewItem",
  components: {
    StarRating,
  },
  props: {
    rating: null,
    review: Object,
    showStar: false,
  },
  data() {
    return {
      display: false,
      isTextClamped: true,
    };
  },

  methods: {
    setRating(rating) {
      this.rating = rating;
    },
  },
  mounted() {
    this.isTextClamped =
      this.$refs["content"].scrollHeight > this.$refs["content"].clientHeight;
    if (this.isTextClamped) {
      this.display = false;
    }
  },
  created() {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 15px;
  background-color: transparent;
}
.inside {
  padding: 0.5rem;
}
.card_preview-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card_preview-text-display {
  display: block;
  overflow: visible;
}
.read-more {
  font-size: 1rem;
  padding: none;
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}
</style>
