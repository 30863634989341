<template>
  <div
    class="page-news"
    style="
      margin-top: 60px;
      margin-left: 1rem;
      margin-right: 1rem;
      margin: 0 auto;
    "
  >
    <h1 class="title" style="display: flex; justify-content: center">
      Les dernières actualités
    </h1>
    <div class="breadcrumb">
      <router-link to="/">ACCUEIL</router-link>
      <div
        style="display: flex"
        v-for="(route, index) in routes"
        v-bind:key="route"
      >
        <span>&nbsp;>&nbsp;</span>
        <router-link v-bind:to="route">
          <span v-bind:class="index == routes.length - 1 ? 'active' : ''">{{
            route.toUpperCase()
          }}</span>
        </router-link>
      </div>
    </div>
    <section v-if="top_article" class="flex-section full-bleed inverse">
      <div style="flex: 1 0 0; max-height: 600px; overflow: hidden;">
        <div style="width: 100%; height: 100%; object-fit: cover" >
          <ImageLoader alt="" v-bind:url="top_article.get_image"/>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          justify-content: center;
          padding: clamp(1rem, 4vw, 4rem);
        "
      >
        <h1 class="subtitle">{{ top_article.title }}</h1>
        <p class="card_preview-text">{{ top_article.preview }}</p>
        <div>
          <router-link v-bind:to="'/news/' + top_article.slug">
            <button class="button-dark">Lire l'article complet.</button>
          </router-link>
        </div>
      </div>
    </section>
    <section class="grid" style="padding: clamp(1rem, 4vw, 4rem) 0">
      <div
        v-for="article in articles"
        v-bind:key="article"
        class="article-card"
      >
        <div class="header-card">
          <ImageLoader alt="" v-bind:url="article.get_image" />
        </div>
        <!-- <img alt="" :src="article.get_image" /> -->
        <div style="padding: 1rem 2rem">
          <h1 style="font-size: 2rem; font-weight: bold">
            {{ article.title }}
          </h1>
          <p class="card_preview-text">{{ article.preview }}</p>
          <router-link class="read-more" v-bind:to="'/news/' + article.slug">
            <span>Continuer à lire</span>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  name: "NewsView",
  data() {
    return {
      path: "",
      routes: [],
      top_article: null,
      articles: [],
    };
  },
  mounted() {
    this.path = this.$router.currentRoute;
    this.routes = this.path.path.split("/").filter(Boolean);
    this.fetchArticles();

    ScrollTrigger.batch(".article-card", {
      onEnter: (elements) => {
        gsap.from(elements, {
          y: 60,
          autoAlpha: 0,
          stagger: 0.15,
        });
      },
      once: true,
    });
  },
  methods: {
    fetchArticles() {
      this.$store.commit("setIsLoading", true);
      axios
        .get(`/api/v1/articles/`)
        .then((response) => {
          this.articles = response.data;
          this.top_article = this.articles[1];
        })
        .catch((error) => {
          console.error(error);
        });
        this.$store.commit("setIsLoading", false);
    },
    sanitizeContent() {
      const contentElement = this.$refs.content;
      if (contentElement) {
        // Filtrer et nettoyer le contenu HTML côté client avec une bibliothèque comme DOMPurify
        const sanitizedHTML = DOMPurify.sanitize(contentElement.innerHTML);
        contentElement.innerHTML = sanitizedHTML;
      }
    },
  },

  computed: {},
};
</script>
<style lang="scss" scoped>
.page-news {
  width: 100%;
  padding: clamp(1rem, 4vw, 4rem);
  max-width: 1780px;
}
.full-bleed {
  box-shadow: 0 0 0 100vmax var(--bg-color);
  clip-path: inset(0 -100vmax);
}
.inverse {
  --bg-color: hsl(0, 3%, 94%);
  background-color: var(--bg-color, #121212);
  color: black;
}
.breadcrumb {
  display: flex;
  font-size: 0.8rem;
  a {
    margin: 0;
    padding: 0;
    color: grey;
    .active {
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (min-width: 900px) and (max-width: 1250px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
  gap: 2rem;
}
.article-card {
  color: rgb(68, 68, 68);
  background-color: #f3f3f3;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  overflow: hidden;
  padding-bottom: 2rem;
  .header-card {
    max-height: 500px;
    height: 40vh;
    width: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.flex-section {
  display: flex;
  gap: 4rem;
  padding: 4rem 0;
  @media (max-width: 750px) {
    flex-direction: column;
  }
}
.card_preview-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 2rem;
}
.read-more {
  color: white;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  font-family: inherit;
  font-size: 100%;
  background-color: hsl(0, 0%, 48%);
  cursor: pointer;
}
</style>
