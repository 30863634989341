<template>
    <h1 class="subtitle">Mes informations personnelles</h1>
    <hr />
    <div style="display:flex; margin-bottom: 2rem;;">
      <div>
        <label>Email</label>
            <div class="control" style="margin-bottom: 1rem;">
              <input
                type="email"
                class="input"
                v-model="email"
                disabled
              />
            </div>
            <router-link style="font-size: 100%;cursor: pointer;background: none;text-decoration: underline;border: none;color: black;" to="/change-pwd">
                <i class="fa-solid fa-pen-to-square"></i>Modifier l'email 
            </router-link>
      </div>
    </div>
    <form @submit.prevent="submitForm">
    <div style="display: flex; gap: 1rem">
        <div class="field" style="flex: 1 0 0">
        <label>Prénom *</label>
        <div class="control">
            <input
            type="text"
            id="id_first_name"
            class="input"
            :class="{ error: v$.first_name.$error }"
            v-model="v$.first_name.$model"
            @blur="v$.first_name.$touch"
            placeholder="ex : Frank"
            v-bind:disabled="!editname"
            />
            <p v-if="v$.first_name.$error" class="error-message">
            Veuillez saisir un prénom.
            </p>
        </div>
        </div>
        <div class="field" style="flex: 1 0 0">
        <label>Nom *</label>
        <div class="control">
            <input
            type="text"
            id="id_last_name"
            class="input"
            :class="{ error: v$.last_name.$error }"
            v-model="v$.last_name.$model"
            @blur="v$.last_name.$touch"
            placeholder="ex : Dupond"
            v-bind:disabled="!editname"
            />
            <p v-if="v$.last_name.$error" class="error-message">
            Veuillez saisir un nom.
            </p>
        </div>
        </div>
    </div>
    <div v-if="editname" class="field">
        <div class="control">
        <button class="button-dark" :class="{ 'isloading': updateinfo }" style="flex:1 0 0;display: inline-flex;">
            <span v-if="updateinfo" class="icon">
                <i class="fa-solid fa-circle-notch fa-spin"></i>
            </span>
            <span v-else>
                Modifier mes informations
            </span>
            </button>
        </div>
    </div>
  </form>
  <div v-if="!editname">
    <button @click="editInfo" style="font-size: 100%;cursor: pointer;background: none;text-decoration: underline;border: none;margin-top: 1rem;">
      <i class="fa-solid fa-pen-to-square"></i>Modifier nom et prénom
    </button>
  </div>
</template>
  
  <script>
  import axios from "axios";
  import { toast } from "bulma-toast";
  import { useVuelidate } from "@vuelidate/core";
  import {
    required,
  } from "@vuelidate/validators";
  
  export default {
    setup() {
      return { v$: useVuelidate() };
    },
    name: "PersonnalInformationsForm",
    data() {
      return {
        first_name: null,
        last_name: null,
        updateinfo:false,
        editname:false,
        email:null,
      };
    },
    mounted() {
      this.getUserInfo();
    },
    validations() {
      return {
        first_name: { required, $lazy: true }, // Matches this.firstName
        last_name: { required, $lazy: true }, // Matches this.lastName
      };
    },
    methods: {
      editInfo(){
        this.editname = !this.editname
      },
      toggleShow() {
        this.showPassword = !this.showPassword;
      },
      async getUserInfo() {
        await axios
          .get(`/api/v1/users/me/`)
          .then((response) => {
            this.first_name = response.data.first_name;
            this.last_name = response.data.last_name;
            this.email = response.data.email;
          })
          .catch((error) => {});
        return -1;
      },
      async submitForm() {
        const isFormCorrect = await this.v$.$validate();
        const error = document.getElementsByClassName("error-message");
        if (error.length > 0) {
          error[0].parentNode.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
        if (isFormCorrect) {
          const formData = {
            first_name: this.first_name,
            last_name: this.last_name,
          };
          this.updateinfo = true;
          await axios
            .patch("/api/v1/users/me/", formData)
            .then((response) => {
              toast({
                message: "Vos informations ont été mise à jours !",
                type: "is-success",
                dismissible: true,
                pauseOnHover: true,
                duration: 4000,
                position: "bottom-right",
              });
            })
            .catch((error) => {
              this.errors = [];
              if (error.response && error.response.status === 500) {
                this.errors.push(
                  "Quelque chose s'est mal passé. Veuillez essayer de nouveau plus tard."
                  );
                } else if (error.message) {
                for (const property in error.response.data) {
                  this.errors.push(`${error.response.data[property]}`);
                }
              }
            });
        }
          this.updateinfo = false;
          this.editname = false;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  input:disabled{
    cursor: default;
  }
  </style>
  